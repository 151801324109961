import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import StyledSVG from '../components/shared/StyledSVG'
import AnnouncementsSVG from '../assets/icons/announcements.svg'
import PhotoSVG from '../assets/icons/photo.svg'
import EventsSVG from '../assets/icons/events.svg'
import moderateSVG from '../assets/icons/moderate.svg'
import archivesSVG from '../assets/icons/archives.svg'
import AnnouncementsView from '../components/view/AnnouncementsView'
import { useQuery } from '../utils/helpers'
import ButtonCard from '../components/card/ButtonCard'
import { roles } from '../utils/constants'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Montserrat, Source Sans Pro',
    width: '98%',
    padding: '1rem 0'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    margin: 16,
    flexWrap: 'wrap',
    [theme.breakpoints.down('426')]: {
      '& button': {
        width: '100px',
        height: '100px',
        '& svg': {
          width: '30px !important',
          height: '30px !important',
          minWidth: '30px !important',
          minHeight: '30px !important'
        },
        '& p': {
          fontSize: '10px'
        }
      }
    },
    [theme.breakpoints.down('330')]: {
      '& button': {
        width: '80px',
        height: '80px'
      }
    }
  },
  paper: {
    borderRadius: 8,
    padding: 32
  }
}))

const defaultColor = '#00ACDB'
// const disabledColor = '#ADB8CC'

const AnnouncementsPage = ({ userOccupations }) => {
  const classes = useStyles()
  const history = useHistory()
  const query = useQuery()
  const projectId = process.env.REACT_APP_PROJECT_ID
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  const topButtons = [
    {
      id: 1,
      title: 'Comunicados',
      icon: AnnouncementsSVG,
      type: 'communications'
    },
    {
      id: 2,
      title: 'Fotos',
      icon: PhotoSVG,
      type: 'images'
    },
    {
      id: 3,
      title: 'Eventos',
      icon: EventsSVG,
      type: 'events'
    },
    {
      id: 4,
      title: 'Moderação',
      icon: moderateSVG,
      type: 'review'
    },
    {
      id: 5,
      title: 'Meu mural',
      icon: archivesSVG,
      type: 'selfs'
    }
  ]

  const handleClickButton = type => {
    history.push(`?name=list&event=${type}`)
  }

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        {topButtons
          .filter(btn => {
            const hiddeModeration = +projectId === 46
            if (hiddeModeration) {
              return btn.type !== 'review'
            } else {
              return true
            }
          })
          .filter(
            btn =>
              (!isAdmin ? btn.type !== 'review' : btn.type) &&
              (isAdmin ? btn.type !== 'selfs' : btn.type)
          )
          .map(btn => {
            return (
              <ButtonCard
                key={btn.id}
                styleType={
                  query.get('event') === btn.type ||
                  (query.get('event') === null && btn.id === 1)
                    ? 'default'
                    : 'disabled'
                }
                onClick={() => handleClickButton(btn.type)}
              >
                <StyledSVG
                  color={
                    defaultColor
                    /* query.get('event') === btn.type
                      ? defaultColor
                      : disabledColor */
                  }
                  src={btn.icon}
                  width={35}
                  height={35}
                />
                <p>{btn.title}</p>
              </ButtonCard>
            )
          })}
      </div>
      {(query.get('event') === 'communications' ||
        query.get('event') === null) && (
        <AnnouncementsView
          isAdmin={isAdmin}
          eventType='communications'
          title='Comunicados'
        />
      )}
      {query.get('event') === 'images' && (
        <AnnouncementsView isAdmin={isAdmin} eventType='images' title='Fotos' />
      )}
      {query.get('event') === 'events' && (
        <AnnouncementsView
          isAdmin={isAdmin}
          eventType='events'
          title='Eventos'
        />
      )}
      {query.get('event') === 'review' && (
        <AnnouncementsView
          isAdmin={isAdmin}
          eventType='review'
          title='Moderação'
        />
      )}
      {query.get('event') === 'selfs' && (
        <AnnouncementsView
          isAdmin={isAdmin}
          eventType='selfs'
          title='Meu mural'
        />
      )}
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  return {
    userOccupations: auth.currentOccupation
  }
}

export default connect(mapStateToProps)(AnnouncementsPage)

import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import SearchClassroomForm from '../components/form/SearchClassroomForm'
import { getClassrooms } from '../store/classrooms/actions'
import { roles } from '../utils/constants'

const mapStateToProps = ({ auth, school, classrooms }) => {
  return {
    userOccupations: auth.currentOccupation,
    currentSchool: school.currentSchool,
    classrooms: classrooms
  }
}

const mapDispatchToProps = {
  getClassrooms
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  }
}))

const SettingsClassroomsPage = ({
  currentSchool,
  getClassrooms,
  classrooms,
  userOccupations
}) => {
  const classes = useStyles()
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  useEffect(() => {
    getClassrooms({
      params: { 'q[school_id_in]': currentSchool && currentSchool.school_id }
    })
  }, [])

  return (
    <div className={classes.root}>
      <SearchClassroomForm
        getClassrooms={getClassrooms}
        classrooms={classrooms}
        school={currentSchool}
        isVersarAdmin={isVersarAdmin}
        isAdmin={isAdmin}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  SettingsClassroomsPage
)

import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'

import ReportStudentView from './../components/view/ReportStudentView'

import {
  getReportStudentById,
  createReportStudent,
  getReportOptionsById,
  editReportStudent,
  cleanReportStudentAnswers
} from '../store/reports/actions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    padding: '1rem 0'
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 24,
    margin: 0
  },
  paper: {
    borderRadius: 8
  }
}))

const ReportStudentPage = ({
  match: { params },
  reports,
  getReportOptionsById,
  getReportStudentById,
  createReportStudent,
  editReportStudent,
  cleanReportStudentAnswers
}) => {
  const classes = useStyles()

  useEffect(() => {
    getReportStudentById({ id: params.reportStudentId })
    getReportOptionsById({ id: params.reportId })
    return () => {
      cleanReportStudentAnswers()
    }
  }, [])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <h1 className={classes.label}>{reports.currentItem.classroom?.name}</h1>
        {
          // eslint-disable-next-line
          <ReportStudentView reports={reports} />
        }
      </Paper>
    </div>
  )
}

const mapStateToProps = ({ auth, reports, school }) => {
  return {
    userOccupations: auth.currentOccupation,
    schoolId: school.currentSchool && school.currentSchool.school_id,
    reports: reports
  }
}

const mapDispatchToProps = {
  getReportStudentById,
  getReportOptionsById,
  createReportStudent,
  editReportStudent,
  cleanReportStudentAnswers
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportStudentPage)

import React, { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import { reduxForm, Field, formValueSelector, change } from 'redux-form'

import { Button, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import StyledSVG from '../shared/StyledSVG'
import applySVG from '../../assets/icons/status-confirmation.svg'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'

import AsyncSelect from '../shared/AsyncSelect'
import jsonApi from '../../utils/jsonApi'
import { useQuery } from '../../utils/helpers'
import HeaderCard from '../card/HeaderCard'
import CustomTable from '../table/CustomTable'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 1220
  },
  container: {
    paddingLeft: 0
  },
  paper: {
    padding: theme.spacing(2),
    margin: 0,
    borderRadius: '8px',
    lineHeight: '20.11px !important',
    fontSize: 16,
    minWidth: 300,
    paddingLeft: '1.5rem',
    maxWidth: '100vw',
    '& h3': {
      color: theme.palette.primary.main
    }
  },
  searchFields: {
    display: 'flex',
    flexFlow: 'column',
    [theme.breakpoints.up('lg')]: {
      flexFlow: 'row',
      '& label:first-of-type': {
        marginRight: '2rem'
      }
    }
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  cellStyle: {
    margin: 0
  },
  select_module: {
    [theme.breakpoints.up('400')]: {
      width: '500px'
    },
    [theme.breakpoints.down('800')]: {
      width: '300px'
    }
  },
  searchBtn: {
    marginTop: 28,
    backgroundColor: '#386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  }
}))

const SearchThemeForm = ({
  change,
  grade,
  moduleValue,
  skillValue,
  school,
  touch,
  values
}) => {
  const classes = useStyles()
  const query = useQuery()

  const stepTitle = query.get('name')
  const skillQuery = query.get('skill_ids')
  const themeModuleQuery = query.get('theme_module_id')
  const [hasSearch, setHasSearch] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [searchList, setSearchList] = useState([])
  const history = useHistory()
  const { gradeId } = useParams()

  const schoolFilters =
    school.school_id || grade.school_id
      ? { 'q[school_id_eq]': school.school_id || grade.school_id }
      : { 'q[school_id_null]': true }

  const paramsRequest = {
    ...schoolFilters,
    'q[skills_id_in]': skillValue?.join(',') || skillQuery,
    'q[theme_module_id_in]': moduleValue || themeModuleQuery,
    include: 'skills,segments,theme_module,theme_module.grade'
  }

  useEffect(() => {
    if (skillQuery || themeModuleQuery) {
      skillQuery && change('skill_ids', skillQuery.split(','))
      themeModuleQuery && change('theme_module_id', themeModuleQuery)
      getThemeById(paramsRequest)
    }
  }, [])

  const columns = [
    {
      key: 'id',
      name: 'ID',
      width: '5%',
      render: row => {
        return row.id
      }
    },
    {
      key: 'title',
      name: 'Título do Tema',
      width: '30%',
      render: row => {
        return row.title
      }
    },
    {
      key: 'skills',
      name: 'Habilidades',
      width: '30%',
      render: row => {
        // eslint-disable-next-line react/display-name
        return row.skills?.map(skill => (
          <p key={skill.id} className={classes.cellStyle}>
            {skill.name}
          </p>
        ))
      }
    },
    {
      key: 'module',
      name: 'Módulo',
      width: '25%',
      render: row => {
        return row.theme_module?.title
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '10%',
      render: function render (row) {
        return (
          <div className={classes.actionsSection}>
            <Tooltip title={'Visualizar'}>
              <Link
                className={classes.link}
                to={{
                  pathname: `/themes/${row.id}`,
                  state: {
                    noTemplate: history.location.pathname.includes(
                      'theme-schedule'
                    ),
                    cycle: history.location.state?.cycle,
                    theme: row,
                    templateQueries: history.location.search,
                    gradeId: gradeId
                  }
                }}
              >
                <StyledSVG src={viewSVG} width={20} height={20} />
              </Link>
            </Tooltip>

            <Tooltip title={'Aplicar'}>
              <Link
                className={classes.link}
                to={{
                  pathname: history.location.pathname.split('/search')[0],
                  state: {
                    noTemplate: history.location.pathname.includes(
                      'theme-schedule'
                    ),
                    cycle: history.location.state?.cycle,
                    theme: row,
                    templateQueries: history.location.search
                  }
                }}
              >
                <StyledSVG src={applySVG} width={20} height={20} />
              </Link>
            </Tooltip>

            {/* <MenuItem>
              <Link
                className={classes.link}
                to={{
                  pathname: history.location.pathname.split('/search')[0],
                  state: {
                    noTemplate: history.location.pathname.includes(
                      'theme-schedule'
                    ),
                    cycle: history.location.state?.cycle,
                    theme: row,
                    templateQueries: history.location.search
                  }
                }}
              >
                Aplicar
              </Link>
            </MenuItem> */}
          </div>
        )
      }
    }
  ]

  const handleChange = (event, input) => {
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v.value)
      updateQuery(input.name, `${newValue}`)
    } else {
      newValue = event ? event.value : ''
      updateQuery(input.name, newValue)
    }
    change(input.name, newValue)
  }

  const updateQuery = (name, value) => {
    query.delete(name)
    query.append(name, value)
    history.push({ ...history.location, search: query.toString() })
  }

  const getThemeById = async ({ page, ...params }) => {
    const newParams = params.params || params
    delete newParams.sort
    await jsonApi
      .findAll('theme', {
        params: {
          'page[number]': page || 1,
          'page[size]': 5,
          ...newParams
        }
      })
      .then(res => {
        const search = res.data.map(data => {
          return {
            ...data,
            segments: data.segments.filter(
              (segment, index, self) =>
                index === self.findIndex(seg => seg.id === segment.id)
            )
          }
        })
        setSearchList({ ...res, data: search })
        setHasSearch(true)
        setIsFetching(false)
      })
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <HeaderCard>
          <div style={{ margin: 'auto 1rem' }}>
            <h1>{grade?.name}</h1>
            <p style={{ paddingTop: 0 }}>
              {grade?.segment?.name} {stepTitle ? `- ${stepTitle}` : ''}
            </p>
          </div>
        </HeaderCard>
        <Grid item xs={12} container className={classes.container}>
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
              <div style={{ maxWidth: 'fit-content' }}>
                <div className={classes.searchFields}>
                  <label
                    className={classes.select_module}
                    htmlFor='theme_module_id'
                  >
                    <div style={{ marginBottom: '0.7rem' }}>Módulo</div>
                    <Field
                      component={AsyncSelect}
                      id='theme_module_id'
                      name='theme_module_id'
                      placeholder='Procure e selecione o Título do Módulo'
                      handleAsyncChange={handleChange}
                      searchParam='q[title_cont]'
                      request={{
                        path: 'theme_module',
                        complemenLabelParams: {
                          includeOptions: { include: 'grade' },
                          sublabel: option =>
                            ` - Sugestão: ${option?.grade?.name}`
                        },
                        params: {
                          // 'q[grade_id_eq]': grade?.id,
                          ...schoolFilters
                        }
                      }}
                      touch={touch}
                    />
                  </label>
                  <label className={classes.select_module} htmlFor='skill_ids'>
                    <div style={{ marginBottom: '0.7rem' }}>Habilidades</div>
                    <Field
                      component={AsyncSelect}
                      id='skill_ids'
                      name='skill_ids'
                      isMulti
                      placeholder='Selecione as Habilidades'
                      handleAsyncChange={handleChange}
                      cacheUniqs={0}
                      isSearchable={false}
                      touch={touch}
                      request={{
                        path: 'skill',
                        params: {
                          'q[segments_id_in]': grade?.segment?.id
                        }
                      }}
                    />
                  </label>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <Button
                    className={classes.searchBtn}
                    variant='contained'
                    type='button'
                    style={{
                      color: '#FFF',
                      width: 150
                    }}
                    onClick={() => {
                      setIsFetching(true)
                      getThemeById(paramsRequest)
                    }}
                  >
                    Buscar
                  </Button>
                </div>
              </div>
              {isFetching ? (
                <CircularProgress color='secondary' />
              ) : (
                hasSearch && (
                  <div style={{ marginTop: '3rem' }}>
                    <h3>{stepTitle}</h3>
                    <CustomTable
                      columns={columns}
                      data={searchList.data}
                      params={{ ...paramsRequest }}
                      fetchItems={getThemeById}
                      isFetching={isFetching}
                      pagination={{ pageCount: searchList.meta.page_count }}
                    />
                  </div>
                )
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchThemeForm')
  return {
    skillValue: selector(state, 'skill_ids'),
    moduleValue: selector(state, 'theme_module_id')
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('searchThemeForm', field, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchThemeForm'
  })(SearchThemeForm)
)

import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import SearchCoordinatorsForm from '../components/form/SearchCoordinatorsForm'
import { getCoordinators } from '../store/coordinators/actions'

import { roles } from '../utils/constants'

const mapStateToProps = ({ auth, school, classrooms, coordinators }) => {
  return {
    userOccupations: auth.currentOccupation,
    currentSchool: school.currentSchool,
    coordinators: coordinators.items
  }
}

const mapDispatchToProps = {
  getCoordinators
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  }
}))

const SettingsClassroomsPage = ({
  currentSchool,
  getCoordinators,
  coordinators,
  userOccupations
}) => {
  const classes = useStyles()
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  useEffect(() => {
    getCoordinators({ params: { 'q[school_id_eq]': currentSchool?.school_id } })
  }, [])

  return (
    <div className={classes.root}>
      <SearchCoordinatorsForm
        getCoordinators={getCoordinators}
        coordinators={coordinators}
        school={currentSchool}
        isVersarAdmin={isVersarAdmin}
        isAdmin={isAdmin}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  SettingsClassroomsPage
)

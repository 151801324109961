import React from 'react'
import { Field, formValueSelector } from 'redux-form'
import { makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import themePuzzleSVG from '../../assets/icons/theme-puzzle.svg'
import StyledSVG from '../shared/StyledSVG'
import ImagesGrid from '../shared/ImagesGrid'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import FormProgress from '../shared/FormProgress'
import TextArea from '../shared/TextArea'
import Button from '../button/Button'
import { required, alreadyFilled } from '../../utils/formUtils'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12
    },
    '& path': {
      stroke: '#4D5E80'
    },
    '& h1': {
      fontWeight: 700,
      color: '#4D5E80',
      fontSize: 32,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16,
      maxWidth: '25%'
    }
  },
  steps: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    '& p': {
      margin: 0,
      fontWeight: 700,
      fontSize: 16
    },
    '& span': {
      fontWeight: 500,
      fontSize: 14
    }
  },
  inputField: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content'
  },
  moduleIdInput: {
    width: '20%'
  },
  title: {
    width: '100%'
  },
  description: {
    '& textarea': {
      height: 140
    },
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    '& label': {
      fontSize: 16,
      fontWeight: 600
    }
  },
  label: {
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  selectCover: {
    marginTop: 60,
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      marginBottom: 20,
      fontWeight: 600,
      fontSize: 16,
      color: 'rgba(85, 85, 85, 1)'
    }
  },
  bottomBtns: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      width: 150
    }
  },
  cancelBtn: {
    width: 146,
    border: '2px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '2px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  moduleInfos: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    '& span': {
      color: '#ADB8CC',
      fontSize: 14,
      fontWeight: 500
    },
    '& p': {
      margin: 0,
      color: '#555555',
      fontSize: 18,
      fontWeight: 700
    }
  },
  formTitle: {
    marginTop: 24,
    marginBottom: 15,
    '& p': {
      color: '#ADB8CC',
      fontSize: 14,
      fontWeight: 500
    }
  },
  themeInfosRow: {
    margin: '24px 0px',
    display: 'flex',
    alignItems: 'center',
    gap: 26,
    '& .segment': {
      width: '50%'
    },
    '& .skills': {
      width: '50%'
    }
  }
}))

const FirstStepThemeForm = props => {
  const {
    segmentValue,
    handleChange,
    currentModule,
    currentStep,
    steps,
    touch,
    initialValues,
    valid,
    history,
    fieldValues,
    change,
    hasModuleId,
    schoolId,
    isVersarAdmin
  } = props

  const schoolParams = isVersarAdmin
    ? { 'q[school_id_null]': true }
    : { 'q[school_id_eq]': schoolId }

  const classes = useStyles()

  const [selectedCover, setSelectedCover] = React.useState(
    initialValues?.cover_id
  )

  const handleClickCover = id => {
    setSelectedCover(id)
    change('cover_id', id)
  }

  return (
    <div>
      <div className={classes.header}>
        <div className='title'>
          <StyledSVG src={themePuzzleSVG} height={32} width={32} />
          <h1>Criar um novo tema</h1>
        </div>
        <span>
          Aqui você pode criar um novo tema e modular da maneira que preferir
        </span>
      </div>

      <div className={classes.steps}>
        <p>Etapas</p>
        <span>Fique atento as etapas a seguir:</span>
        <FormProgress currentStep={currentStep} steps={steps} />
      </div>
      <div className={classes.moduleInfos}>
        <span>Novo tema no módulo: </span>
        {hasModuleId && (
          <p>
            {currentModule?.attributes?.title ||
              initialValues?.theme_module?.title}
          </p>
        )}
        {!hasModuleId && (
          <div className={`${classes.inputField} ${classes.moduleIdInput}`}>
            <label className={classes.label}>Módulo</label>
            <Field
              component={AsyncSelect}
              id='theme_module_id'
              name='theme_module_id'
              placeholder='Selecione um módulo para o tema'
              handleAsyncChange={handleChange}
              searchParam='q[title_cont]'
              touch={touch}
              request={{
                path: 'theme_module',
                params: schoolParams
              }}
              validate={
                !alreadyFilled(initialValues?.theme_module_id) && [required]
              }
            />
          </div>
        )}
      </div>

      <div className={classes.formTitle}>
        <p>Dados do tema</p>
      </div>
      <div className='FormTheme'>
        <div className={`${classes.inputField} ${classes.title}`}>
          <label className={classes.label} htmlFor='title'>
            Título:
          </label>
          <Field
            component={MaterialInput}
            name='title'
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
              }
            }}
            type='text'
            placeholder='Digite o título'
            autoComplete='off'
            validate={!alreadyFilled(initialValues?.title) && [required]}
          />
        </div>
        <div className={classes.themeInfosRow}>
          <div className={`${classes.inputField} skills`}>
            <label className={classes.label}>Habilidades</label>
            <Field
              component={AsyncSelect}
              id='skill_ids'
              name='skill_ids'
              isMulti
              placeholder='Selecione as Habilidades'
              handleAsyncChange={handleChange}
              cacheUniqs={segmentValue}
              defaultValue={
                fieldValues?.skill_ids ||
                initialValues?.skills?.map(item => {
                  return { value: item.id, label: item.name }
                })
              }
              touch={touch}
              request={{
                path: 'skill',
                params: {
                  'q[segments_id_in]': segmentValue
                }
              }}
              validate={!alreadyFilled(initialValues?.skill_ids) && [required]}
            />
          </div>
        </div>
        <div className={classes.description}>
          <label className={classes.label} htmlFor='description'>
            Descrição:
          </label>
          <Field
            name='description'
            component={TextArea}
            validate={!alreadyFilled(initialValues?.description) && [required]}
          />
        </div>
      </div>
      <div className={classes.selectCover}>
        <label htmlFor='cover_id'>Selecione a imagem de capa</label>
        <ImagesGrid
          perPage={5}
          handleClick={handleClickCover}
          selected={selectedCover}
        />
      </div>
      <div className={classes.bottomBtns}>
        <Button
          type='button'
          variant={'outlined'}
          className={classes.cancelBtn}
          onClick={() => history.push('/newmodules')}
        >
          Voltar
        </Button>
        <Button
          type='submit'
          onClick={() => change('currentStep', currentStep)}
          className={classes.sendBtn}
          disabled={!valid || !fieldValues?.cover_id}
        >
          {'Próximo'}
        </Button>
      </div>
    </div>
  )
}
const mapStateToProps = (state, props) => {
  const selector = formValueSelector('newCreateModuleForm')
  return {
    segmentValue: selector(state, 'segment_id'),
    schoolId: state.school.currentSchool.school?.id
  }
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FirstStepThemeForm)

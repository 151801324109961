import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'

import Child1 from '../assets/child1.png'
import Child2 from '../assets/child2.png'
import Select from 'react-select'
const { REACT_APP_PROJECT_LOGO } = process.env

const useStyles = makeStyles(theme => ({
  root: {
    background: '#E8E8DB',
    padding: '1rem',
    minHeight: '100vh',
    [theme.breakpoints.up('xl')]: {
      paddingTop: '3rem'
    }
  },
  childContainer: {
    alignItems: 'end',
    display: 'grid',
    gridTemplateAreas: `
      'child1 child2'
      'versarLogo versarLogo'
      'loginBtn loginBtn';
    `,
    gridTemplateRows: 200,
    justifyContent: 'center',
    justifyItems: 'center',
    [theme.breakpoints.up('md')]: {
      gridTemplateRows: 400
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateRows: 460
    }
  },
  child1: {
    gridArea: 'child1',
    height: '84%',
    width: '27vw',
    [theme.breakpoints.up('xl')]: {
      width: '21vw'
    }
  },
  child2: {
    gridArea: 'child2',
    height: '90%',
    width: '23vw',
    [theme.breakpoints.up('xl')]: {
      marginTop: '-5rem',
      width: '18vw'
    }
  },
  logo: {
    gridArea: 'versarLogo',
    marginTop: '-1rem',
    width: '50vw',
    [theme.breakpoints.up('md')]: {
      marginTop: '-3rem'
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: '-5rem',
      width: '39vw'
    }
  },
  button: {
    fontWeight: 'bold',
    gridArea: 'loginBtn',
    textTransform: 'capitalize',
    width: '100px',
    [theme.breakpoints.up('md')]: {
      width: '200px'
    }
  },
  btnHidden: {
    cursor: 'pointer',
    userSelect: 'none',
    color: 'transparent',
    position: 'absolute',
    right: 0,
    bottom: 0
  }
}))
function encodeQueryData (data) {
  const ret = []
  for (let d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  }
  return ret.join('&')
}
const ssoUrl = {
  local: 'http://localhost:8000/',
  staging: 'https://staging-fractalid.fractaltecnologia.com.br/',
  homolog: 'https://staging-fractalid.fractaltecnologia.com.br/',
  production: 'https://fractalid.fractaltecnologia.com.br/'
}
const versarAppUrl = {
  local: 'http://localhost:3000/',
  staging: 'https://stag-app.plataformaversar.com.br/',
  homolog: 'https://homolog-app.plataformaversar.com.br/',
  production: 'https://app.plataformaversar.com.br/'
}

const VersarPage = () => {
  const {
    REACT_APP_PROJECT_ID,
    REACT_APP_SERVER_ENV = 'local',
    REACT_APP_API_ENV = REACT_APP_SERVER_ENV
  } = process.env
  const [selectValue, setSelectValue] = React.useState(null)
  const [clickCount, setClickCount] = React.useState(0)
  const showSelectProjectId =
    REACT_APP_API_ENV !== 'production' && REACT_APP_API_ENV !== 'staging'
  const urlRedirect = `${window.location.href ||
    versarAppUrl[REACT_APP_API_ENV]}login${
    showSelectProjectId && selectValue ? `?project_id=${selectValue}` : ''
  }`
  const data = {
    app_id: REACT_APP_PROJECT_ID || 34,
    url_redirect: urlRedirect
  }
  const urlString = encodeQueryData(data)
  const url = `${ssoUrl[REACT_APP_SERVER_ENV]}?${urlString}`
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {clickCount > 6 && (
        <Select
          onChange={event => setSelectValue(event.value)}
          options={[
            { value: '43', label: 'Kingdom' },
            { value: '42', label: 'Bertoni' },
            { value: null, label: 'Homolog' },
            { value: '34', label: 'Versar' },
            { value: '46', label: 'Serios' },
            { value: '49', label: 'Escola do Ser' }
          ]}
        />
      )}
      <section className={classes.childContainer}>
        <Fade in>
          <img
            src={Child1}
            className={classes.child1}
            alt='Projeto de Educação Socioemocional para escolas'
          />
        </Fade>
        <Fade in>
          <img
            src={Child2}
            className={classes.child2}
            alt='Projeto de Educação Socioemocional para escolas'
          />
        </Fade>
        <Fade in>
          <img
            src={REACT_APP_PROJECT_LOGO}
            className={classes.logo}
            alt='Projeto de Educação Socioemocional para escolas'
          />
        </Fade>
        <Button
          className={classes.button}
          color='primary'
          component={'a'}
          variant='contained'
          href={url}
          aria-label=''
        >
          Login
        </Button>
      </section>
      {showSelectProjectId && (
        <h1
          onClick={() => {
            setClickCount(prev => prev + 1)
          }}
          className={classes.btnHidden}
        >
          Click
        </h1>
      )}
    </div>
  )
}

export default VersarPage

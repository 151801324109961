import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  Form,
  reduxForm,
  Field,
  formValueSelector,
  change
  // getFormValues
} from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { roles, shiftOptions, months } from '../../utils/constants'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import CustomTable from '../table/CustomTable'
import Button from '../button/Button'
import filterSVG from '../../assets/icons/filter-icon.svg'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import deleteSVG from '../../assets/icons/pattern-delete-icon.svg'
import editIntoModalSVG from '../../assets/icons/edit-pencil.svg'

import { deleteCoordinatorRequestRSPA } from '../../store/coordinators/actions'
import studentBookSVG from '../../assets/icons/student-details-icon.svg'
import StyledSVG from '../shared/StyledSVG'
import { Tooltip } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import Modal from '../modal/Modal'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  btnStyle: {
    marginTop: 18,
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center',
    textAlign: 'right',
    gap: 10,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& button': {
        width: 240
      }
    }
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  searchSection: {
    marginTop: 40
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  filtersSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  filtersDisplay: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  horizontalBar: {
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #ADB8CC'
  },
  reactiveBtn: {
    backgroundColor: '#F4A87C',
    width: 247,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  createBtn: {
    backgroundColor: '#386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    lineHeight: '19.5px'
  },
  modalRoot: {
    scrollbarWidth: 'thin',
    padding: '40px',
    position: 'relative'
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  datasLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    [theme.breakpoints.down('500')]: {
      justifyContent: 'center'
    }
  },
  detailHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 20,
    '& h1': {
      fontSize: 24,
      fontWeight: 700,
      color: '#386093',
      [theme.breakpoints.down('360')]: {
        fontSize: 19
      }
    }
  },
  infoLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  userType: {
    marginBottom: 20,
    '& span': {
      fontSize: '18px !important',
      fontWeight: '600 !important',
      color: '#386093 !important'
    }
  },
  personalData: {
    marginBottom: 10,
    color: '#555555',
    fontWeight: 600
  },
  addressSection: {
    marginTop: 50
  },
  addressInfo: {
    color: '#555555',
    marginBottom: 18,
    fontWeight: 600
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& label': {
      color: '#9F9F9F',
      fontWeight: 500,
      fontSize: 16
    },
    '& p': {
      margin: 0,
      color: '#666666',
      fontWeight: 700,
      fontSize: 16
    }
  },
  classroomLabel: {
    marginTop: 50
  },
  classroomSection: {
    padding: '20px 10px',
    height: '100px',
    borderRadius: 8,
    background: 'linear-gradient(194.95deg, #FFFFFF -0.58%, #F1F3F4 60.91%)',
    position: 'relative',
    transition: 'height 0.3s ease-in-out',
    // xs={12} sm={6} md={4} lg={3} xl={3}
    [theme.breakpoints.down('md')]: {
      height: '150px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '250px'
    }
  },
  editButton: {
    '& button': {
      border: '2px solid #386093',
      color: '#386093',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'transparent',
        border: '2px solid #386093'
      }
    }
  },
  editLabel: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    color: '#386093'
  },
  textModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 136,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  confirmBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 165,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const SearchCoordinatorsForm = ({
  change,
  userOccupations,
  school,
  isVersarAdmin,
  isAdmin,
  touch,
  nameValue,
  schoolValue,
  classroomValue,
  reset,
  getCoordinators,
  coordinators,
  pagination,
  isFetching,
  deleteCoordinatorRequestRSPA
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const paramsRequest = {
    'q[school_id_eq]': school?.school_id || schoolValue,
    'q[coordinator_name_cont]': nameValue,
    'q[coordinator_classroom_coordinators_classroom_id_in]': classroomValue
  }
  const selectNameRef = React.useRef(null)
  const selectClassroomRef = React.useRef(null)
  const selectSchoolRef = React.useRef(null)
  const [coordinatorDetailModal, setCoordinatorDetailModal] = React.useState({
    isOpen: false,
    current: null
  })

  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    user: null,
    id: null,
    school: null
  })

  const today = new Date()
  const lastAccessIsToday =
    moment(today).format('DD/MM/YYYY') ===
    moment(coordinatorDetailModal?.current?.last_access_at).format('DD/MM/YYYY')
  const lastAccessWasYesterday =
    moment(today.setDate(today.getDate() - 1)).format('DD/MM/YYYY') ===
    moment(coordinatorDetailModal?.current?.last_access_at).format('DD/MM/YYYY')

  const handleUserDetails = (row, id) => {
    setCoordinatorDetailModal(prev => ({
      isOpen: !prev.isOpen,
      current: row,
      school_coordinator_id: id
    }))
  }

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getCoordinators({ params: paramsRequest })
    }
  }
  const handleChange = (event, input) => {
    const newValue = event ? event.value : null
    change(input.name, newValue)
  }

  const handleClearFields = () => {
    reset()
    selectNameRef.current?.select.clearValue()
    selectClassroomRef.current?.select.clearValue()
    selectSchoolRef.current?.select.clearValue()
    getCoordinators({ params: {} })
  }

  const handleSearch = () => {
    getCoordinators({
      params: {
        ...paramsRequest
      }
    })
  }

  const columns = [
    {
      key: 'name',
      name: 'Nome',
      render: row => {
        return <span>{row.coordinator.name}</span>
      }
    },
    {
      key: 'email',
      name: 'Email',
      render: row => {
        return row.coordinator.email
      }
    },
    {
      key: 'school',
      name: 'Escola',
      render: row => {
        return row.school.name
      }
    },
    {
      key: 'classrooms',
      name: 'Turma(s)',
      render: row => {
        const classrooms = row?.coordinator?.classroom_coordinators
          .filter(item => {
            return item?.classroom?.school_id === school?.school_id
          })
          .map((item, index, array) => {
            if (index === array.length - 1) {
              return `${item?.classroom?.name}\n`
            } else {
              return `${item?.classroom?.name}, \n`
            }
          })
        return classrooms?.length > 0 ? classrooms : '-'
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '10%',
      align: 'right',
      render: function render (row) {
        // mudar o redirecionamento quando for admin
        return (
          <div className={classes.actionsSection}>
            <Tooltip title='Visualizar'>
              <div onClick={() => handleUserDetails(row.coordinator, row.id)}>
                <StyledSVG src={viewSVG} width={20} height={20} />
              </div>
            </Tooltip>
            {isAdmin && (
              <Tooltip title='Editar'>
                <div
                  onClick={() =>
                    history.push(`${history.location.pathname}/${row.id}/edit`)
                  }
                >
                  <StyledSVG src={editSVG} width={20} height={20} />
                </div>
              </Tooltip>
            )}
            {isAdmin && (
              <Tooltip title='Remover coordenador'>
                <div
                  onClick={() =>
                    setConfirmModal({
                      id: row.id,
                      user: row.coordinator,
                      open: true,
                      school: row.school
                    })
                  }
                >
                  <StyledSVG src={deleteSVG} width={20} height={20} />
                </div>
              </Tooltip>
            )}
          </div>
        )
      }
    }
  ]

  const getColumns = () => {
    const columnKeys = isVersarAdmin
      ? ['name', 'email', 'school', 'classrooms', 'actions']
      : ['name', 'email', 'classrooms', 'actions']

    return columns.filter(column => columnKeys.includes(column.key))
  }

  return (
    <div className={classes.root}>
      <Form>
        <div className={classes.pageLabel}>
          <h1>Coordenadores</h1>
          <span>
            Explore a lista de coordenadores disponíveis na plataforma
          </span>
        </div>
        {/* Header */}
        <div className={classes.searchSection}>
          <div className={classes.searchDiv}>
            <strong>Busca</strong>
            <Field
              style={{ marginTop: 10 }}
              component={MaterialInput}
              selectRef={selectNameRef}
              name='name'
              endBtnFunction={() => {
                getCoordinators({ params: paramsRequest })
              }}
              onKeyDown={handleChangeTitle}
              placeholder={'Nome do coordenador'}
              type='text'
            />
          </div>
          <div className={classes.filtersSection}>
            <div className={classes.filterLabel}>
              <StyledSVG src={filterSVG} width={12} height={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  cursor: 'default',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              className={classes.advancedFilter}
              style={{
                height: openMoreFilter ? '250px' : '0px'
              }}
            >
              <div className={classes.filtersDisplay}>
                <div
                  className={classes.searchGroup}
                  style={{ opacity: openMoreFilter ? '1' : '0' }}
                >
                  {userOccupations?.includes(roles.VERSAR_ADMIN) && (
                    <div className={classes.searchField}>
                      <strong htmlFor='school_id'>Escola</strong>
                      <Field
                        component={AsyncSelect}
                        selectRef={selectSchoolRef}
                        defaultOptions={false}
                        id='school_id'
                        name='school_id'
                        placeholder='Digite ou selecione a escola'
                        handleAsyncChange={handleChange}
                        searchParam='q[name_cont]'
                        request={{
                          path: 'school'
                        }}
                        touch={touch}
                      />
                    </div>
                  )}
                  <div className={classes.searchField}>
                    <strong style={{ marginBottom: 10 }} htmlFor='classroom_id'>
                      Turma
                    </strong>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      id='classroom_id'
                      name='classroom_id'
                      selectRef={selectClassroomRef}
                      cacheUniqs={[schoolValue]}
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'classroom',
                        params: {
                          'q[school_id_in]': school?.school_id || schoolValue,
                          'q[year_lteq]': new Date().getFullYear() + 1,
                          'q[year_gteq]': new Date().getFullYear()
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={classes.buttons}
                >
                  <Button
                    onClick={() => handleClearFields()}
                    className={classes.clearBtn}
                  >
                    Limpar
                  </Button>
                  <Button
                    className={classes.applyBtn}
                    type='button'
                    onClick={() => handleSearch()}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.horizontalBar} />
        </div>
        <div>
          <h2>Lista de coordenadores</h2>
          <CustomTable
            from={'coordinators'}
            columns={getColumns()}
            border={'none'}
            bodyBorder={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            data={coordinators}
            params={paramsRequest}
            fetchItems={getCoordinators}
            isFetching={isFetching}
            pagination={pagination}
          />
        </div>
        <div>
          <Grid item xs={12} className={classes.btnStyle}>
            <Button
              className={classes.backBtn}
              variant='outlined'
              type='button'
              onClick={() => history.push('/settings')}
            >
              Voltar
            </Button>
            {isAdmin && (
              <Button
                className={classes.reactiveBtn}
                type='button'
                onClick={() =>
                  history.push(`${history.location.pathname}/reactivate`)
                }
              >
                Reativar coordenador
              </Button>
            )}
            {isAdmin && (
              <Button
                className={classes.createBtn}
                type='button'
                onClick={() => history.push(`${history.location.pathname}/new`)}
              >
                Cadastrar novo coordenador
              </Button>
            )}
          </Grid>
        </div>
      </Form>
      <Modal
        open={confirmModal.open}
        handleClose={() =>
          setConfirmModal({
            open: false,
            id: null,
            user: null,
            school: null
          })
        }
      >
        <div className={classes.textModal}>
          <h1>Atenção!</h1>
          <h2>
            Ao confirmar {confirmModal.user?.name} será desligado da{' '}
            {confirmModal.school?.name}.
          </h2>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
          <Button
            className={classes.cancelBtn}
            onClick={() => {
              setConfirmModal({
                id: null,
                user: null,
                open: false,
                school: null
              })
            }}
          >
            Cancelar
          </Button>
          <Button
            className={classes.confirmBtn}
            onClick={async () => {
              await deleteCoordinatorRequestRSPA({
                schoolCoordinatorId: confirmModal.id,
                userId: confirmModal.user?.id
              })
              setConfirmModal({
                id: null,
                user: null,
                open: false,
                school: null
              })
              getCoordinators({ params: paramsRequest })
            }}
          >
            Confirmar
          </Button>
        </div>
      </Modal>
      <Modal
        open={coordinatorDetailModal.isOpen}
        handleClose={() =>
          setCoordinatorDetailModal({ isOpen: false, current: null })
        }
        width={'85%'}
        disablePadding
      >
        <div className={classes.modalRoot}>
          <CloseRoundedIcon
            onClick={() =>
              setCoordinatorDetailModal({ isOpen: false, current: null })
            }
            className={classes.closeIcon}
          />
          <Grid className={classes.datasLabel} container item>
            <div className={classes.detailHeader}>
              <StyledSVG src={studentBookSVG} />
              <h1>Detalhes do perfil</h1>
            </div>
            <div className={classes.editButton}>
              <Button variant='outlined'>
                <div
                  className={classes.editLabel}
                  onClick={() => {
                    history.push(
                      `${history.location.pathname}/${
                        coordinatorDetailModal?.school_coordinator_id
                      }/edit`
                    )
                  }}
                >
                  <StyledSVG width={14} height={14} src={editIntoModalSVG} />
                  <span>Editar</span>
                </div>
              </Button>
            </div>
          </Grid>
          <Grid container style={{ rowGap: '10px' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={`${classes.infoLabel} ${classes.userType}`}>
                <span>Coordenador</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={`${classes.infoLabel} ${classes.personalData}`}>
                <span>Dados pessoais</span>
              </div>
            </Grid>
            <Grid style={{ rowGap: 20 }} container>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>Nome</label>
                  <p>{coordinatorDetailModal?.current?.name}</p>
                </div>
              </Grid>
              {coordinatorDetailModal?.current?.taxpayer_number && (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <div className={classes.userInfo}>
                    <label>CPF</label>
                    <p>{coordinatorDetailModal?.current?.taxpayer_number}</p>
                  </div>
                </Grid>
              )}
              {coordinatorDetailModal?.current?.last_access_at && (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <div className={classes.userInfo}>
                    <label>Último acesso</label>
                    <p>
                      {!coordinatorDetailModal?.current?.last_access_at
                        ? 'Sem registro de acesso do usuário.'
                        : lastAccessIsToday
                          ? `Hoje às ${moment(
                              coordinatorDetailModal?.current.last_access_at
                            ).format('HH:mm')}`
                          : lastAccessWasYesterday
                            ? `Ontem às ${moment(
                                coordinatorDetailModal?.current.last_access_at
                              ).format('HH:mm')}`
                            : `${moment(
                                coordinatorDetailModal?.current.last_access_at
                              ).format('DD')} de ${
                                months[
                                  `${moment(
                                    coordinatorDetailModal?.current
                                      .last_access_at
                                  ).format('MM')}`
                                ]
                              } às ${moment(
                                coordinatorDetailModal?.current.last_access_at
                              ).format('HH:mm')}`}
                    </p>
                  </div>
                </Grid>
              )}
              {coordinatorDetailModal?.current?.email && (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <div className={classes.userInfo}>
                    <label>E-mail</label>
                    <p>{coordinatorDetailModal?.current?.email}</p>
                  </div>
                </Grid>
              )}
              {coordinatorDetailModal?.current?.phone && (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <div className={classes.userInfo}>
                    <label>Contato</label>
                    <p>{coordinatorDetailModal?.current?.phone}</p>
                  </div>
                </Grid>
              )}
              {coordinatorDetailModal.current?.address?.zipcode && (
                <Grid container className={classes.addressSection}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                      className={`${classes.infoLabel} ${classes.addressInfo}`}
                    >
                      <span>Endereço</span>
                    </div>
                  </Grid>
                  <Grid container style={{ rowGap: '10px' }}>
                    {coordinatorDetailModal?.current?.user?.address
                      ?.zipcode && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <div className={classes.userInfo}>
                          <label>CEP</label>
                          <p>
                            {coordinatorDetailModal?.current?.address?.zipcode}
                          </p>
                        </div>
                      </Grid>
                    )}
                    {coordinatorDetailModal?.current?.address?.street && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <div className={classes.userInfo}>
                          <label>Rua</label>
                          <p>
                            {coordinatorDetailModal?.current?.address?.street}
                          </p>
                        </div>
                      </Grid>
                    )}
                    {coordinatorDetailModal?.current?.address?.complement && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <div className={classes.userInfo}>
                          <label>Complemento</label>
                          <p>
                            {
                              coordinatorDetailModal?.current?.address
                                ?.complement
                            }
                          </p>
                        </div>
                      </Grid>
                    )}
                    {coordinatorDetailModal?.current?.address
                      ?.neighbourhood && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <div className={classes.userInfo}>
                          <label>Bairro</label>
                          <p>
                            {
                              coordinatorDetailModal?.current?.address
                                ?.neighbourhood
                            }
                          </p>
                        </div>
                      </Grid>
                    )}
                    {coordinatorDetailModal?.current?.address?.number && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <div className={classes.userInfo}>
                          <label>Número</label>
                          <p>
                            {coordinatorDetailModal?.current?.address?.number}
                          </p>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {coordinatorDetailModal.current?.classrooms?.length > 0 && (
            <Grid container style={{ rowGap: '10px' }}>
              <Grid
                className={classes.classroomLabel}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              />
              {coordinatorDetailModal.current?.classrooms.map(
                (classroom, index, self) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div
                        className={`${classes.infoLabel} ${classes.userType}`}
                      >
                        <span>Turma {self.length > 1 ? index + 1 : ''}</span>
                      </div>
                      <Grid
                        style={{ rowGap: 10 }}
                        key={index}
                        className={`${classes.classroomSection}`}
                        container
                      >
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                          <div className={classes.userInfo}>
                            <label>Nome da turma</label>
                            <p>{classroom.name}</p>
                          </div>
                        </Grid>
                        {classroom?.grade && (
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                            <div className={classes.userInfo}>
                              <label>Série </label>
                              <p>{classroom.grade.name}</p>
                            </div>
                          </Grid>
                        )}
                        {classroom?.shift && (
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                            <div className={classes.userInfo}>
                              <label>Turno </label>
                              <p>
                                {
                                  shiftOptions.find(
                                    item => item.value === classroom.shift
                                  )?.label
                                }
                              </p>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )
                }
              )}
            </Grid>
          )}
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchCoordinatorsForm')
  return {
    classroomValue: selector(state, 'classroom_id'),
    nameValue: selector(state, 'name'),
    schoolValue: selector(state, 'school_id'),
    userOccupations: state.auth.currentOccupation,
    isFetching: state.coordinators.isFetching,
    pagination: state.coordinators.pagination
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) =>
    dispatch(change('searchCoordinatorsForm', field, data)),
  deleteCoordinatorRequestRSPA: data =>
    dispatch(deleteCoordinatorRequestRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchCoordinatorsForm'
  })(SearchCoordinatorsForm)
)

import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
// import CircularProgress from '@material-ui/core/CircularProgress'

import docSVG from '../../assets/icons/doc.svg'
import videoSVG from '../../assets/icons/video.svg'
import audioSVG from '../../assets/icons/audio.svg'
import linkSVG from '../../assets/icons/link.svg'
import imgSVG from '../../assets/icons/img.svg'

const useStyles = makeStyles(theme => ({
  archives: {
    display: 'flex',
    padding: '10px',
    flexFlow: 'row wrap',
    gap: 20,
    marginBottom: '1rem',
    '& div': {
      display: 'inline-flex',
      justifyContent: 'end',
      '& svg': {
        color: theme.palette.danger.main,
        cursor: 'pointer',
        position: 'absolute',
        '& .archives': {
          margin: '-14px 14px'
        }
      }
    },
    '& img': {
      maxWidth: 300,
      maxHeight: 194
    }
  },
  link: {
    backgroundColor: 'rgba(218, 218, 218, 0.2)',
    borderRadius: 8,
    display: 'flex',
    gap: 8,
    padding: '5px 10px',
    width: 'fit-content',
    '& a': {
      color: theme.palette.text.secondary,
      textDecoration: 'none'
    }
  },
  img: {
    borderRadius: 8,
    height: 'fit-content',
    objectFit: 'contain',
    cursor: 'pointer'
  },
  cover: {
    border: () =>
      theme.matches ? `1px dashed ${theme.palette.text.secondary}` : 'none',
    borderRadius: 8,
    color: theme.palette.text.secondary,
    padding: '9px 14px',
    width: 'fit-content',
    textAlign: 'center'
  },
  photoSection: {
    position: 'relative',
    border: '1px dashed #ADB8CC',
    borderRadius: '7px',
    width: 'fit-content',
    padding: '25px'
  },
  rmvBtn: {
    position: 'absolute',
    zIndex: 1,
    right: '-1px',
    top: '-1px',
    color: 'gray !important',
    margin: '5px'
  },
  files: {
    margin: '-24px -16px',
    color: 'gray !important',
    [theme.breakpoints.down('426')]: {
      margin: '-5px 10px'
    }
  }
}))

const ShowFiles = ({
  filesByInput,
  filesByDataBase,
  coverValue,
  allFiles,
  handleDeleteNames,
  handleDeleteCover,
  alert,
  handleDeleteFilesInstance,
  onlyView,
  handleImageClick
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  const classes = useStyles({ matches })
  const filesContent = files => {
    if (files?.length > 0) {
      const mainContent = files.map(file => {
        if (file instanceof Blob && file instanceof File) {
          const isImage = file.type.includes('image')
          const isVideo = file.type.includes('video')
          const isAudio = file.type.includes('audio')
          let fileDoc
          if (!isAudio || !isVideo || !isImage) {
            fileDoc = URL.createObjectURL(file)
          }
          // format file name
          let fileName = file.name
          const extencion = fileName.split('.')[fileName.split('.').length - 1]
          fileName.length > 20 &&
            !matches &&
            (fileName = `${fileName.slice(0, 17)}...${extencion}`)

          const possibleTypes = {
            image: matches ? (
              <div key={file.name}>
                <img src={file.preview} alt={file.name} />
                {handleDeleteNames && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => handleDeleteNames(file.name)}
                  />
                )}
              </div>
            ) : (
              <div key={file.name}>
                <p className={classes.link}>
                  <img src={imgSVG} alt='image' width='21' height='21' />
                  <a target='_blank' href={file.preview} rel='noreferrer'>
                    {fileName}
                  </a>
                </p>
                {handleDeleteNames && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => handleDeleteNames(file.name)}
                  />
                )}
              </div>
            ),
            video: matches ? (
              <div key={file.name}>
                <video width='320' height='240' controls>
                  <source src={file.preview} type={file.type} />
                  O seu navegador não suporta o elemento <code>audio</code>.
                </video>
                {handleDeleteNames && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => handleDeleteNames(file.name)}
                  />
                )}
              </div>
            ) : (
              <div key={file.name}>
                <p className={classes.link}>
                  <img src={videoSVG} alt='video' width='21' height='21' />
                  <a target='_blank' href={file.preview} rel='noreferrer'>
                    {fileName}
                  </a>
                </p>
                {handleDeleteNames && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => handleDeleteNames(file.name)}
                  />
                )}
              </div>
            ),
            audio: matches ? (
              <div key={file.name}>
                <audio controls>
                  <source src={file.preview} type={file.type} />
                  O seu navegador não suporta o elemento <code>audio</code>.
                </audio>
                {handleDeleteNames && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => handleDeleteNames(file.name)}
                  />
                )}
              </div>
            ) : (
              <div key={file.name}>
                <p className={classes.link}>
                  <img src={audioSVG} alt='video' width='21' height='21' />
                  <a target='_blank' href={file.preview} rel='noreferrer'>
                    {fileName}
                  </a>
                </p>
                {handleDeleteNames && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => handleDeleteNames(file.name)}
                  />
                )}
              </div>
            ),
            default: (
              <div style={{ height: 'fit-content' }} key={file.name}>
                <p className={classes.link}>
                  <img src={docSVG} alt='document' width='21' height='21' />
                  <a target='_blank' href={fileDoc} rel='noreferrer'>
                    {matches ? file.name : fileName}
                  </a>
                </p>
                {handleDeleteNames && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => handleDeleteNames(file.name)}
                  />
                )}
              </div>
            )
          }
          return (
            <div key={file.name}>
              {(isImage && possibleTypes['image']) ||
                (isVideo && possibleTypes['video']) ||
                (isAudio && possibleTypes['audio']) ||
                possibleTypes['default']}
            </div>
          )
        } else {
          const isImage = file?.content_type?.includes('image')
          const isVideo = file?.content_type?.includes('video')
          const isAudio = file?.content_type?.includes('audio')
          const isLink = file?.content_type?.includes('link')
          // format file name
          let fileName = file?.filename
          const extencion = fileName?.split('.')[
            fileName?.split('.').length - 1
          ]
          fileName?.length > 20 &&
            !matches &&
            (fileName = `${fileName.slice(0, 12)}...${extencion}`)
          const fileUrl = file?.file_url || file?.url
          const possibleTypes = {
            image: matches ? (
              <div key={file.filename}>
                <img
                  src={fileUrl}
                  alt={file.filename}
                  className={classes.img}
                  onClick={() => handleImageClick && handleImageClick(file)}
                  width='195'
                  height='195'
                />
                {!onlyView && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => {
                      handleDeleteNames(file.filename, true)
                      handleDeleteFilesInstance(file.id)
                    }}
                  />
                )}
              </div>
            ) : (
              <div key={file.filename}>
                <p className={classes.link}>
                  <img src={imgSVG} alt='image' width='21' height='21' />
                  <a target='_blank' href={fileUrl} rel='noreferrer'>
                    {fileName}
                  </a>
                </p>
                {!onlyView && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => {
                      handleDeleteNames(file.filename, true)
                      handleDeleteFilesInstance(file.id)
                    }}
                  />
                )}
              </div>
            ),
            video: matches ? (
              <div key={file.filename}>
                <video width='320' height='240' controls>
                  <source src={fileUrl} type={file.content_type} />
                  O seu navegador não suporta o elemento <code>audio</code>.
                </video>
                {!onlyView && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => {
                      handleDeleteNames(file.filename, true)
                      handleDeleteFilesInstance(file.id)
                    }}
                  />
                )}
              </div>
            ) : (
              <div key={file.filename}>
                <p className={classes.link}>
                  <img src={videoSVG} alt='video' width='21' height='21' />
                  <a target='_blank' href={fileUrl} rel='noreferrer'>
                    {fileName}
                  </a>
                </p>
                {!onlyView && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => {
                      handleDeleteNames(file.filename, true)
                      handleDeleteFilesInstance(file.id)
                    }}
                  />
                )}
              </div>
            ),
            audio: matches ? (
              <div key={file.filename}>
                <audio controls>
                  <source src={fileUrl} type={file.content_type} />
                  O seu navegador não suporta o elemento <code>audio</code>.
                </audio>
                {!onlyView && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => {
                      handleDeleteNames(file.filename, true)
                      handleDeleteFilesInstance(file.id)
                    }}
                  />
                )}
              </div>
            ) : (
              <div key={file.filename}>
                <p className={classes.link}>
                  <img src={audioSVG} alt='video' width='21' height='21' />
                  <a target='_blank' href={fileUrl} rel='noreferrer'>
                    {fileName}
                  </a>
                </p>
                {!onlyView && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => {
                      handleDeleteNames(file.filename, true)
                      handleDeleteFilesInstance(file.id)
                    }}
                  />
                )}
              </div>
            ),
            link: (
              <div style={{ height: 'fit-content' }} key={file.note}>
                <p className={classes.link}>
                  <img src={linkSVG} alt='link' width='21' height='21' />
                  <a target='_blank' href={file.note} rel='noreferrer'>
                    {file.note}
                  </a>
                </p>
                {!onlyView && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => {
                      handleDeleteNames(file.note)
                      handleDeleteFilesInstance(file.id)
                    }}
                  />
                )}
              </div>
            ),
            default: (
              <div style={{ height: 'fit-content' }} key={file.filename}>
                <p className={classes.link}>
                  <img src={docSVG} alt='document' width='21' height='21' />
                  <a target='_blank' href={fileUrl} rel='noreferrer'>
                    {fileName}
                  </a>
                </p>
                {!onlyView && (
                  <HighlightOffIcon
                    className={classes.files}
                    onClick={() => {
                      handleDeleteNames(file.filename, true)
                      handleDeleteFilesInstance(file.id)
                    }}
                  />
                )}
              </div>
            )
          }

          return (
            <div key={file.name}>
              <div>
                {(isImage && possibleTypes['image']) ||
                  (isVideo && possibleTypes['video']) ||
                  (isAudio && possibleTypes['audio']) ||
                  (isLink && possibleTypes['link']) ||
                  possibleTypes['default']}
              </div>
            </div>
          )
        }
      })
      return mainContent
    }
  }

  const coverContent = cover => {
    if (cover) {
      if (cover instanceof Blob && cover instanceof File) {
        return (
          <div className={classes.photoSection}>
            <img
              alt='imagem de capa'
              height='195'
              src={URL.createObjectURL(cover)}
              className={classes.img}
              id='cover'
              width='195'
            />
            <HighlightOffIcon
              color='gray'
              className={classes.rmvBtn}
              onClick={() => handleDeleteCover()}
            />
          </div>
        )
      } else if (cover !== 'delete') {
        return (
          <div>
            <img
              alt='imagem de capa'
              height='195'
              src={cover}
              className={classes.img}
              id='cover'
              width='195'
            />
            <HighlightOffIcon onClick={() => handleDeleteCover(true)} />
          </div>
        )
      }
    }
  }

  return (
    <div>
      {
        <>
          {coverValue && (
            <>
              <p>Foto de capa: </p>
              <div className={`${classes.archives} ${classes.cover}`}>
                {coverContent(coverValue)}
              </div>
            </>
          )}
          {allFiles?.length > 0 && (
            <>
              <p>Arquivo(s): </p>
              <div className={`${classes.archives} ${classes.cover}`}>
                {filesContent(allFiles)}
              </div>
            </>
          )}
        </>
      }
    </div>
  )
}

export default ShowFiles

import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Paper, Avatar } from '@material-ui/core'

import { AvatarGroup } from '@material-ui/lab'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import Backdrop from '@material-ui/core/Backdrop'
import MaterialModal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import { reportStatus, colors, reportsShift, roles } from '../utils/constants'
import { numberFromText } from '../utils/helpers'

import StyledSVG from '../components/shared/StyledSVG'
import doneSVG from '../assets/icons/status-confirmation.svg'
import pendingSVG from '../assets/icons/status-pending.svg'
import moderatingSVG from '../assets/icons/moderating-icon.svg'
import openSVG from '../assets/icons/status-open.svg'
import UserAvatar from '../components/shared/UserAvatar'
import Button from '../components/button/Button'
import CustomTable from '../components/table/CustomTable'
import ReportTemplateLabel from '../components/label/ReportTemplateLabel'
import { getReportById, getReportStudents } from '../store/reports/actions'
import RowActionTable from '../components/table/RowActionTable'

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    padding: '1rem 0',
    '& th.MuiTableCell-head': {
      background: 'none',
      border: 'none',
      fontSize: 16,
      paddingBottom: 0
    },
    '& td.MuiTableCell-body': {
      color: theme.palette.text.secondary,
      fontSize: 16
    }
  },
  positionAnswerReport: {
    display: 'flex',
    marginTop: '20px',
    '& button': {
      '&:first-of-type': {
        justifyContent: 'flex-end'
      }
    },
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center'
    }
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 16
  },
  paper: {
    borderRadius: 8,
    padding: 32
  },
  status: {
    marginLeft: '5px',
    [theme.breakpoints.down('426')]: {
      display: 'none'
    }
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 32,
    '& button': {
      width: 146,
      border: '1px solid #EF7C8F',
      height: 44,
      padding: '14px 48px',
      gap: 10,
      color: '#EF7C8F',
      borderRadius: 8,
      '&:hover': {
        border: '1px solid #EF7C8F'
      },
      fontSize: '16px',
      fontWeight: 600,
      textTransform: 'capitalize',
      lineHeight: '19.5px'
    }
  },
  answerBtn: {
    backgroundColor: '#F4A87C',
    width: 247,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  labelGrade: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& p': {
      marginLeft: '5px',
      fontSize: 18
    }
  },
  dashedBorder: {
    marginTop: '25px',
    width: '100%',
    height: '0px',
    border: '1px dashed #ADB8CC'
  },
  table: {
    [theme.breakpoints.up('lg')]: {
      minWidth: 700
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalRoot: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '29px 35px 50px',
    [theme.breakpoints.down('426')]: {
      maxWidth: '95%'
    },
    color: theme.palette.text.primary,
    maxHeight: 600,
    width: 825,
    overflow: 'auto',
    '& button': {
      fontWeight: 700,
      textTransform: 'capitalize'
    },
    '& > div:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        marginTop: '1rem',
        width: 130
      }
    }
  },
  closeIcon: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '-20px',
    '& svg': {
      fill: theme.palette.text.primary,
      height: 16,
      width: 16
    }
  },
  statusRoot: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center'
    }
  }
}))

const ReportClassroomPage = ({
  getReportById,
  getReportStudents,
  match: { params },
  reports,
  userOccupations
}) => {
  const classes = useStyles()
  const history = useHistory()
  const path = useLocation().pathname
  const [viewsModal, setViewsModal] = React.useState(false)
  const [parents, setParents] = React.useState([])
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))

  useEffect(() => {
    getReportById({ id: params.reportId, getWithAllStudents: false })
  }, [])

  const handleViewsModal = () => {
    setViewsModal(prevState => !prevState)
  }

  const columns = [
    {
      key: 'student',
      name: 'Estudante',
      width: '40%',
      render: row => {
        let name
        matches
          ? (name = row.child.name)
          : (name = `${row.child.name.slice(0, 8)}...`)
        return (
          row.child && (
            <UserAvatar
              onClick={() =>
                history.push(`/reports/${row.report_id}/classroom/${row.id}`)
              }
              avatarStyle={{ height: '40px', width: '40px' }}
              userName={name}
              avatarUrl={row.child?.avatar_url}
            />
          )
        )
      }
    },
    {
      key: 'status',
      name: 'Status',
      width: '20%',
      render: function render (row) {
        const statusProperties = (status, isForColor) => {
          switch (status) {
            case 'done': {
              if (isForColor) return '#00ACDB'
              return doneSVG
            }
            case 'pending':
              if (isForColor) return '#F69E9E'
              return pendingSVG
            case 'open':
              if (isForColor) return '#C4C4C4'
              return openSVG
            case 'waiting_moderation': {
              if (isForColor) return '#F1925B'
              return moderatingSVG
            }
            default:
              return ''
          }
        }
        return (
          <div className={classes.statusRoot}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledSVG
                height={20}
                width={20}
                src={statusProperties(row.status)}
              />
            </div>
            <div>
              <span
                style={{ color: statusProperties(row.status, true) }}
                className={classes.status}
              >
                {reportStatus[row.status]}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      key: 'views',
      name: 'Visualizado por',
      width: '25%',
      render: function render (row) {
        const localConstParents = row?.views?.map(view => {
          return { name: view.user.name, date: view.created_at }
        })
        const handleViewsModal = () => {
          setParents(prev => localConstParents)
          localConstParents?.length > 0 &&
            setViewsModal(prevState => !prevState)
        }
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => localConstParents?.length > 0 && handleViewsModal()}
          >
            <AvatarGroup max={4} spacing={1}>
              {localConstParents?.length > 0 ? (
                localConstParents.map((parent, idx) => {
                  const index =
                    numberFromText(parent.name?.slice(0, 2)) % colors.length
                  return (
                    <Tooltip
                      key={`user[${idx}]`}
                      title={parent.name}
                      arial-label='usuario'
                    >
                      <Avatar
                        style={{
                          height: '30px',
                          width: '30px',
                          backgroundColor:
                            // eslint-disable-next-line standard/computed-property-even-spacing
                            colors[index]
                        }}
                      >
                        <p
                          style={{
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '17px',
                            letterSpacing: '0em',
                            textAlign: 'left'
                          }}
                        >
                          {parent.name[0]}
                        </p>
                      </Avatar>
                    </Tooltip>
                  )
                })
              ) : (
                <div style={{ padding: 5, borderRadius: 10 }}>
                  <span>Não visualizado</span>
                </div>
              )}
            </AvatarGroup>
            <span>{localConstParents?.[0]?.name}</span>
          </div>
        )
      }
    },
    {
      key: 'date',
      name: 'Data',
      width: '20%',
      render: function render (row) {
        const date =
          row.views?.[0]?.created_at &&
          moment(row.views?.[0]?.created_at).format('DD/MM/YYYY HH:mm a')
        return <span>{date || 'Não visualizado'}</span>
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '5%',
      render: function render (row) {
        const localConstParents = row?.views?.map(view => {
          return { name: view.user.name, date: view.created_at }
        })
        return (
          <RowActionTable>
            <MenuItem
              className={classes.action}
              onClick={() =>
                history.push(`/reports/${row.report_id}/classroom/${row.id}`)
              }
            >
              Visualizar diário
            </MenuItem>
            {!matches && (
              <MenuItem
                className={classes.action}
                onClick={() => {
                  setParents(localConstParents)
                  handleViewsModal()
                }}
              >
                Visualizado por
              </MenuItem>
            )}
          </RowActionTable>
        )
      }
    }
  ]

  const defaultParams = {
    'q[report_id_eq]': params.reportId,
    'page[number]': reports.reportStudents.page
  }
  const classroom = reports?.currentTemplate?.classroom?.map(clsr => clsr.name)
  const shift = reports?.currentTemplate?.classroom?.map(
    clrs => reportsShift[clrs.shift]
  )
  const grade = reports?.currentTemplate?.classroom?.map(
    clrs => clrs.grade.name
  )
  const getColumns = () => {
    const columnKeys = matches
      ? ['student', 'status', 'views', 'date', 'actions']
      : ['student', 'status', 'actions']
    return columns.filter(column => columnKeys.includes(column.key))
  }
  const parentsColumn = [
    {
      key: 'name',
      name: 'Nome',
      width: '35%',
      render: function render (row) {
        return row.name
      }
    },
    {
      key: 'date',
      name: 'Data',
      width: '35%',
      render: row => {
        return moment(row.date).format('DD-MM-YYYY, hh:mm a')
      }
    },
    {
      key: 'relationationship',
      name: 'Relacionamento',
      width: '30%',
      render: row => {
        return 'Responsável'
      }
    }
  ]
  const isModerate =
    reports?.currentItem?.status === 'waiting_moderation' && isAdmin
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <ReportTemplateLabel
          classroom={classroom}
          shift={shift}
          grade={grade}
        />
        <div className={classes.positionAnswerReport}>
          <Button
            type='button'
            className={classes.answerBtn}
            onClick={() => history.push(`${path}/all/students`)}
          >
            {isModerate ? 'Moderar Relatórios' : 'Responder Relatório'}
          </Button>
        </div>
        <CustomTable
          columns={getColumns()}
          data={reports.reportStudents.items}
          params={defaultParams}
          fetchItems={getReportStudents}
          isFetching={reports.reportStudents.isFetching}
          pagination={reports.reportStudents.pagination}
          border={'none'}
          bodyBorder={'none'}
          headCellRadius
          columnsPointer={[0]}
        />
        <div className={classes.backBtn}>
          <Button
            type='button'
            variant='outlined'
            onClick={() =>
              reports?.currentItem?.report_template_id &&
              history.push(
                `/report_template/${reports?.currentItem?.report_template_id}`
              )
            }
          >
            Voltar
          </Button>
        </div>
      </Paper>
      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={viewsModal}
        onClose={handleViewsModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={viewsModal}>
          <div className={classes.modalRoot}>
            <div className={classes.closeIcon} onClick={handleViewsModal}>
              <CloseRoundedIcon />
            </div>
            <h2 id='modal-title'>Visualizado por:</h2>
            <CustomTable columns={parentsColumn} whiteHeader data={parents} />
          </div>
        </Fade>
      </MaterialModal>
    </div>
  )
}

const mapStateToProps = ({ reports, school, auth }) => {
  return {
    reports: reports,
    userOccupations: auth.currentOccupation
  }
}

const mapDispatchToProps = {
  getReportById,
  getReportStudents
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportClassroomPage)

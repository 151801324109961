import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import { Form, formValueSelector, reduxForm, getFormValues } from 'redux-form'

import FirstStepThemeForm from './FirstStepThemeForm'
import SecondStepThemeForm from './SecondStepThemeForm'
import ThirdStepThemeForm from './ThirdStepThemeForm'
import configSVG from '../../assets/icons/config-gear-icon.svg'
import fillContentSVG from '../../assets/icons/stepper-theme-icon.svg'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import { roles } from '../../utils/constants'
import { createThemeRSPA, editThemeRSPA } from '../../store/themes/actions'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Montserrat, Source Sans Pro',
    width: '98%',
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    margin: 'auto',
    height: '95%',
    padding: '32px',
    marginBottom: '100px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12
    },
    '& path': {
      stroke: '#4D5E80'
    },
    '& h1': {
      fontWeight: 700,
      color: '#4D5E80',
      fontSize: 32,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16,
      maxWidth: '25%'
    }
  },
  steps: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    '& p': {
      margin: 0,
      fontWeight: 700,
      fontSize: 16
    },
    '& span': {
      fontWeight: 500,
      fontSize: 14
    }
  },
  inputField: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content'
  },
  title: {
    width: '100%'
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    '& label': {
      fontSize: 16,
      fontWeight: 600
    }
  },
  descriptionRootStyle: {
    padding: 0,
    marginBottom: 0,
    border: '1.5px solid #ADB8CC',
    '& .mce-edit-area': {
      minHeight: '120px'
    },
    '& .mce-container-body': {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    '& .mce-btn-group .mce-btn': {
      margin: 0,
      backgroundColor: 'transparent'
    },
    '& .mce-toolbar-grp': {
      width: 'fit-content',
      backgroundColor: '#F6F7F8',
      boxShadow: '3px 11px 14px 6px #6B67671A',
      margin: '0px 30px 30px 40px',
      height: 'fit-content',
      borderRadius: 8
    },
    '& .mce-btn': {
      '& button': {
        height: 50
      }
    },
    '& .mce-panel': {
      backgroundColor: 'unset'
    }
  },
  label: {
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  selectCover: {
    marginTop: 60,
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      marginBottom: 20,
      fontWeight: 600,
      fontSize: 16,
      color: 'rgba(85, 85, 85, 1)'
    }
  },
  bottomBtns: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      width: 150
    }
  },
  cancelBtn: {
    width: 146,
    border: '2px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '2px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  moduleInfos: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    '& span': {
      color: '#ADB8CC',
      fontSize: 14,
      fontWeight: 500
    },
    '& p': {
      margin: 0,
      color: '#555555',
      fontSize: 18,
      fontWeight: 700
    }
  },
  formTitle: {
    marginTop: 24,
    marginBottom: 15,
    '& p': {
      color: '#ADB8CC',
      fontSize: 14,
      fontWeight: 500
    }
  },
  themeInfosRow: {
    margin: '24px 0px',
    display: 'flex',
    alignItems: 'center',
    gap: 26,
    '& .segment': {
      width: '50%'
    },
    '& .skills': {
      width: '50%'
    }
  }
}))

const NewCreateThemeForm = ({
  isEdit,
  handleSubmit,
  userOccupations,
  change,
  touch,
  initialValues,
  step,
  ...props
}) => {
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const classes = useStyles()

  const [currentStep, setCurrentStep] = React.useState(step || 0)

  const handleBack = () => {
    setCurrentStep(prevCurrentStep => {
      const newState = prevCurrentStep - 1
      change('currentStep', newState)
      return newState
    })
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <FirstStepThemeForm
            {...props}
            initialValues={initialValues}
            isVersarAdmin={isVersarAdmin}
            touch={touch}
            change={change}
            handleChange={handleChange}
            currentStep={currentStep}
            steps={[
              { key: 'Configurações', icon: configSVG },
              { key: 'Foco e conteúdo', icon: fillContentSVG },
              { key: 'Preview', icon: viewSVG }
            ]}
          />
        )
      case 1:
        return (
          <SecondStepThemeForm
            {...props}
            initialValues={initialValues}
            change={change}
            handleChange={handleChange}
            currentStep={currentStep}
            steps={[
              { key: 'Configurações', icon: configSVG },
              { key: 'Foco e conteúdo', icon: fillContentSVG },
              { key: 'Preview', icon: viewSVG }
            ]}
          />
        )
      case 2:
        return (
          <ThirdStepThemeForm
            {...props}
            initialValues={initialValues}
            handleBack={handleBack}
            currentStep={currentStep}
            steps={[
              { key: 'Configurações', icon: configSVG },
              { key: 'Foco e conteúdo', icon: fillContentSVG },
              { key: 'Preview', icon: viewSVG }
            ]}
          />
        )
      default:
        return ''
    }
  }

  const handleChange = (event, input) => {
    touch(input.name)
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  return (
    <div className={classes.root}>
      {props.themeIsFetching ? (
        <CircularProgress />
      ) : (
        <Form onSubmit={handleSubmit}>{getStepContent(currentStep)}</Form>
      )}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('newCreateThemeForm')
  return {
    segmentValue: selector(state, 'segment_id'),
    currentSchool: state.school.currentSchool.school,
    currentModule: state.modules.currentItem.item,
    currentTheme: state.themes.currentItem.item,
    themeIsFetching: state.themes.currentItem.isFetching,
    fieldValues: getFormValues('newCreateThemeForm')(state),
    contents: state.contents.contents,
    modules: state.modules
  }
}

const mapDispatchToProps = dispatch => ({
  createThemeRSPA: data => dispatch(createThemeRSPA.request(data)),
  editThemeRSPA: data => dispatch(editThemeRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'newCreateThemeForm',
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      if (values.currentStep === 0 && !props.isEdit) {
        const { currentStep, ...newValues } = values
        await props
          .createThemeRSPA({
            ...newValues,
            school: props.currentSchool,
            module: props.currentModule
          })
          .then(res => {
            props.history.push(`/newthemes/${res.id}/contents`)
          })
          .catch(error => error)
      } else if (values.currentStep === 0 && props.isEdit) {
        const { currentStep, ...newValues } = values
        await props
          .editThemeRSPA({
            id: props.initialValues.id,
            school_id: props.currentSchool.id,
            title: newValues.title,
            description: newValues.description,
            theme_module_id: newValues?.theme_module_id,
            skill_ids: newValues.skill_ids.map(item => +item.value || +item),
            cover_id: newValues.cover_id
          })
          .then(res => {
            props.history.push(`/newthemes/${props.initialValues.id}/contents`)
          })
      } else if (values.currentStep === 1) {
        props.history.push(`/newthemes/${props.currentTheme.id}/preview`)
      }
    }
  })(NewCreateThemeForm)
)

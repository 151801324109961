import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { roles } from '../utils/constants'

import MyProfilePage from './MyProfilePage'
import SettingsTeachers from './SettingsTeachersPage'
import SettingsClassrooms from './SettingsClassroomsPage'
import SettingsLayout from './SettingsLayoutPage'
import SettingsCovers from './SettingsCoversPage'
import ModerationPage from './ModerationPage'
import CoordinatorPage from './SettingsCoordinatorsPage'
import HistoryAccessPage from './HistoryAccessPage'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Montserrat, Source Sans Pro',
    width: '98%',
    marginTop: 40
  },
  headOptions: {
    overflowY: 'hidden',
    borderBottom: '2px solid #CED6E0',
    paddingLeft: 24,
    display: 'flex',
    gap: 24
  },
  optionPath: {
    cursor: 'pointer',
    color: '#8080808C',
    fontWeight: 600,
    paddingBottom: 8,
    fontSize: '16px'
  },
  selectedOption: {
    position: 'relative',
    color: '#375F92',
    fontWeight: 700,
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      bottom: -0.5,
      width: '100%',
      height: 2,
      backgroundColor: '#386093'
    }
  }
}))

const SettingsPage = props => {
  const { currentOccupation } = props
  const classes = useStyles()
  const history = useHistory()

  const optionsPaths = [
    {
      label: 'Meu perfil',
      path: '/settings',
      permission: [
        roles.STUDENT,
        roles.PARENT,
        roles.TEACHER,
        roles.VERSAR_ADMIN,
        roles.SCHOOL_ADMIN,
        roles.COORDINATOR
      ],
      component: <MyProfilePage {...props} history={history} />
    },
    {
      label: 'Turmas e alunos',
      path: '/settings/classrooms',
      permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR],
      component: <SettingsClassrooms {...props} />
    },
    {
      label: 'Professores',
      path: '/settings/teachers',
      permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR],
      component: <SettingsTeachers {...props} />
    },
    {
      label: 'Coordenadores',
      path: '/settings/coordinator',
      permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR],
      component: <CoordinatorPage {...props} />
    },
    {
      label: 'Configurações de usuários',
      path: '/settings/historic',
      permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN],
      component: <HistoryAccessPage {...props} />
    },
    {
      label: 'Moderação',
      path: '/settings/moderation',
      permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN],
      component: <ModerationPage {...props} />
    },
    {
      label: 'Capas',
      path: '/settings/covers',
      permission: [roles.VERSAR_ADMIN],
      component: <SettingsCovers {...props} />
    },
    {
      label: 'Layout',
      path: '/settings/layout',
      permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN],
      component: <SettingsLayout {...props} />
    }
  ]

  return (
    <div className={classes.root}>
      <div className={classes.headOptions}>
        {optionsPaths
          .filter(item => item.permission.includes(currentOccupation))
          .map((option, index) => {
            return (
              <span
                onClick={() => history.push(option.path)}
                className={`${classes.optionPath} ${
                  String(option.path) === String(props.location.pathname)
                    ? classes.selectedOption
                    : {}
                }`}
                key={index}
              >
                {option.label}
              </span>
            )
          })}
      </div>
      <div>
        {
          optionsPaths.find(item => item.path === props.location.pathname)
            ?.component
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    currentOccupation: state.auth.currentOccupation?.[0]
  }
}

export default connect(mapStateToProps)(SettingsPage)

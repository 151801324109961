import React from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import { makeStyles, styled } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import noReportSVG from '../assets/icons/no-reports.svg'

import { useQuery } from '../utils/helpers'
import ReportsListView from '../components/view/ReportsListView'
import { roles } from '../utils/constants'
import { getReports, getReportTemplateById } from '../store/reports/actions'
import CreateReportForm from '../components/form/CreateReportForm'

const NoReportStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 16,
  marginTop: 18,
  [theme.breakpoints.down('sm')]: {
    '& h5': {
      fontSize: 18
    }
  },
  '& img': {
    objectFit: 'contain',
    width: '100%',
    maxWidth: 450
  }
}))

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    padding: '1rem 0'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    margin: 16
  },
  paper: {
    borderRadius: 8,
    padding: 32,
    [theme.breakpoints.down('426')]: {
      padding: '10px !important'
    }
  }
}))

export const NoReportsDiv = () => (
  <NoReportStyled>
    <img src={noReportSVG} alt='imagem da lista de diários vazia' />
    <Typography variant='h5' color='primary'>
      Não há diários associados
    </Typography>
  </NoReportStyled>
)

const ReportsTemplatePage = ({
  userOccupations,
  reports,
  schoolId,
  getReportTemplateById
}) => {
  const classes = useStyles()

  const query = useQuery()
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const { reportTemplateId } = useParams()

  React.useEffect(() => {
    getReportTemplateById({ id: reportTemplateId })
  }, [])
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        {(query.get('name') === 'list' || query.get('name') === null) && (
          <ReportsListView
            isAdmin={isAdmin}
            reports={reports}
            schoolId={schoolId}
          />
        )}
        {query.get('name') === 'edit' && (
          <CreateReportForm isEdit reportTemplateId={reportTemplateId} />
        )}
      </Paper>
    </div>
  )
}

const mapStateToProps = ({ auth, reports, school }) => {
  return {
    userOccupations: auth.currentOccupation,
    schoolId: school.currentSchool && school.currentSchool.school_id,
    reports: reports
  }
}

const mapDispatchToProps = {
  getReports,
  getReportTemplateById
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTemplatePage)

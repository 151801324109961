import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  getClassroomById,
  clearCurrentClassroom
} from '../store/classrooms/actions'
import SettingsClassroomView from '../components/view/SettingsClassroomView'

const mapStateToProps = ({ school, classrooms }) => {
  return {
    currentSchool: school.currentSchool,
    classroom: classrooms.currentItem,
    isFetching: classrooms.isFetching
  }
}

const mapDispatchToProps = {
  getClassroomById,
  clearCurrentClassroom
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    padding: '1rem 0'
  }
}))

const SettingsClassroomPage = ({
  currentSchool,
  match,
  getClassroomById,
  clearCurrentClassroom,
  classroom,
  isFetching
}) => {
  const classes = useStyles()
  const { classId } = match.params
  useEffect(() => {
    getClassroomById(classId)
    return () => {
      clearCurrentClassroom()
    }
  }, [])

  if (isFetching) return <CircularProgress color='secondary' />

  return (
    <div className={classes.root}>
      <SettingsClassroomView classroom={classroom} />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  SettingsClassroomPage
)

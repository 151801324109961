import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import * as types from './constants'

export const SIGN_IN = createAction('auth:SIGN_IN')
export const SIGN_IN_SUCCESSFUL = createAction('auth:SIGN_IN_SUCCESSFUL')
export const SIGN_IN_FAILURE = createAction('auth:SIGN_IN_FAILURE')

export const LOGOUT = createAction('auth:LOGOUT')
export const LOGOUT_SUCCESSFUL = createAction('auth:LOGOUT_SUCCESSFUL')
export const LOGOUT_FAILURE = createAction('auth:LOGOUT_FAILURE')

export const switchUser = createAction(types.SWITCH_USER)
export const switchUserSuccessful = createAction(types.SWITCH_USER_SUCCESSFUL)
export const switchUserFailure = createAction(types.SWITCH_USER_FAILURE)

export const updateUserFromSignin = createAction(types.UPDATE_USER_FROM_SIGNIN)

export const confirmPassword = createPromiseAction('rspa: CONFIRM_PASSWORD')()

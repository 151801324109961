import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  contents: [],
  isFetching: false,
  isError: false,
  errorMessage: '',
  currentItem: {
    item: null,
    isFetching: false,
    isError: false,
    messageError: null
  }
}

const contentsReducer = createReducer(initialState, {
  NEW_GET_CONTENT_BY_ID: (state, action) => ({
    ...state,
    currentItem: {
      item: null,
      isFetching: true,
      isError: false,
      messageError: null
    }
  }),
  NEW_GET_CONTENT_BY_ID_SUCCESSFUL: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      item: action.payload.data,
      isFetching: false,
      isError: false,
      messageError: null
    }
  }),
  NEW_GET_CONTENT_BY_ID_FAILURE: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }
  }),
  [types.GET_CONTENTS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: '',
    currentItem: initialState.currentItem
  }),
  [types.GET_CONTENTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    contents: action.payload,
    errorMessage: ''
  }),
  [types.GET_CONTENTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    contents: {},
    isError: true,
    errorMessage: action.payload,
    currentItem: initialState.currentItem
  }),
  [types.GET_CONTENT_BY_ID]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null
    }
  }),
  [types.GET_CONTENT_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      item: action.payload,
      isFetching: false,
      isError: false,
      messageError: null,
      included: action.payload?.included
    }
  }),
  [types.GET_CONTENT_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }
  }),
  [types.GET_CONTENTS_BY_IDS]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null
    }
  }),
  [types.CREATE_CONTENTS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.CREATE_CONTENTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      item: [action.payload?.data] || [action.payload]
    },
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.CREATE_CONTENTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    contents: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.DELETE_CONTENT]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.DELETE_CONTENT_SUCCESSFUL]: (state, action) => ({
    ...state,
    contents: {
      ...state.contents,
      data: state.contents.data.filter(item => item.id !== action.payload)
    },
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.DELETE_CONTENT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_CONTENT]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.EDIT_CONTENT_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentItem: {
      item: action.payload
    },
    errorMessage: ''
  }),
  [types.EDIT_CONTENT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.DELETE_DOCUMENT]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.DELETE_AUDIO]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.CLEAR_CURRENT_CONTENT]: (state, action) => ({
    ...state,
    currentItem: initialState.currentItem
  })
})

export default contentsReducer

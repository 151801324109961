import { all, call, takeLatest, put } from 'redux-saga/effects'
import { jsonToFormdata } from '../../utils/mappers'

import { showAlertMessage } from '../alert/actions'
import { editTheme } from '../themes/actions'
import * as actions from './actions'
import * as services from './services'

export function * fetchContents ({ payload }) {
  try {
    const contentsData = yield call(services.fetchContents, payload)
    yield put(actions.getContentsSuccessful(contentsData))
  } catch (error) {
    yield put(actions.getContentsFailure(error))
  }
}

export function * fetchContentById ({ payload }) {
  const { ids } = payload
  try {
    let response
    if (payload.isToEdit) {
      response = yield call(services.fetchContentById, ids)
    } else {
      response = yield call(services.fetchContentByIdForContentView, ids)
    }
    yield put(actions.getContentByIdSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getContentByIdFailure(error))
  }
}

export function * createContentRequest ({ payload }) {
  try {
    const fd = new FormData()
    fd.append('data[type]', 'contents')
    yield jsonToFormdata(fd, payload)
    const response = yield call(services.createContent, fd)
    yield put(actions.createContentsSuccessful(response.data))
    yield put(
      showAlertMessage({
        message: 'Sucesso ao criar conteúdo.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.createContentsFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao criar conteúdo.',
        severity: 'error'
      })
    )
  }
}

export function * deleteContent ({ payload }) {
  const { id, afterEffect } = payload
  try {
    const contentById = yield call(services.fetchContentById, id)
    const theme = contentById && contentById.theme
    let themeContent
    if (theme) {
      themeContent = theme?.contents_order
      themeContent && themeContent.splice(themeContent.indexOf(id), 1)
    }
    yield call(services.deleteContent, id)
    afterEffect && afterEffect()
    yield theme &&
      put(editTheme({ id: theme.id, data: { contents_order: themeContent } }))
    yield put(actions.deleteContentSuccessful(id))
  } catch (error) {
    console.error(error)
    yield put(actions.deleteContentFailure(error))
  }
}

export function * editContent ({ payload }) {
  try {
    const { filesToDelete } = payload.data
    delete payload.data.filesToDelete
    const fd = new FormData()
    fd.append('data[type]', 'contents')
    fd.append('data[id]', payload.id)
    yield jsonToFormdata(fd, payload.data)
    const response = yield call(services.editContent, {
      id: payload.id,
      data: fd
    })
    if (filesToDelete?.length > 0) {
      yield all(
        filesToDelete.map(item => {
          return call(services.deleteDocument, {
            contentId: payload.id,
            documentId: item
          })
        })
      )
    }
    yield put(actions.editContentSuccessful(response))
    yield put(
      showAlertMessage({
        message: 'Sucesso ao editar conteúdo.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editContentFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao editar conteúdo.',
        severity: 'error'
      })
    )
  }
}

export function * deleteDocument ({ payload }) {
  const response = yield call(services.deleteDocument, {
    contentId: payload.contentId,
    documentId: payload.documentId
  })
  yield put(actions.deleteDocument(response))
}

export function * deleteAudio ({ payload }) {
  try {
    yield call(services.deleteAudio, payload)
    yield put(
      showAlertMessage({
        message: 'Aúdio deletado.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(
      showAlertMessage({
        message: 'Falha ao deletar aúdio.',
        severity: 'error'
      })
    )
  }
}

export function * fetchContentsByIds ({ payload }) {
  const { ids } = payload
  try {
    const response = yield call(services.fetchContentsByIds, ids)
    yield put(actions.getContentByIdSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getContentByIdFailure(error))
  }
}

export function * newFetchContentById ({ payload }) {
  try {
    const { id } = payload
    const response = yield call(services.newFetchContentById, id)
    yield put(actions.newGetContentByIdSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.newGetContentByIdFailure(error))
  }
}

// Watchers
export function * watchModules () {
  yield takeLatest(actions.getContents, fetchContents)
  yield takeLatest(actions.getContentById, fetchContentById)
  yield takeLatest(actions.getContentsByIds, fetchContentsByIds)
  yield takeLatest(actions.createContents, createContentRequest)
  yield takeLatest(actions.deleteContent, deleteContent)
  yield takeLatest(actions.editContent, editContent)
  yield takeLatest(actions.deleteDocument, deleteDocument)
  yield takeLatest(actions.deleteAudio, deleteAudio)
  yield takeLatest(actions.newGetContentById, newFetchContentById)
}

export default function * modulesSaga () {
  yield all([watchModules()])
}

import http from '../../utils/http'
import jsonApi from '../../utils/jsonApi'
const contentsUrl = '/contents'

export const fetchContents = ({
  value,
  option,
  page,
  sort = 'created_at desc',
  hasTheme = true,
  hasSchoolId = false,
  schoolId = null
}) => {
  const valueIsNotNumber = isNaN(value)
  const moduleKey = valueIsNotNumber
    ? 'q[theme_theme_module_title_cont]'
    : 'q[theme_theme_module_id_eq]'

  const paramSearch =
    option === 'Módulos'
      ? { [moduleKey]: value }
      : option === 'Temas'
        ? {
            [valueIsNotNumber ? 'q[theme_title_cont]' : 'q[theme_id_eq]']: value
          }
        : { [valueIsNotNumber ? 'q[title_cont]' : 'q[id_eq]']: value }
  const paramHasTheme = !hasTheme && { 'q[theme_id_not_null]': hasTheme }
  let school = hasSchoolId === true && { 'q[school_id_eq]': schoolId }
  return http
    .get(contentsUrl, {
      params: {
        'page[number]': page || 1,
        'page[size]': 5,
        'q[s]': sort,
        'q[school_id_not_null]': hasSchoolId,
        include: 'theme,segment,occupation,category',
        ...paramSearch,
        ...paramHasTheme,
        ...school
      }
    })
    .then(res => res.data)
}

export const newFetchContentById = id => {
  return jsonApi
    .find('contents', id, {
      params: {
        include: 'theme,segment,occupation,category'
      }
    })
    .then(res => res)
}

export const fetchContentById = ids => {
  return jsonApi
    .findAll('contents', {
      params: {
        'q[id_in]': ids,
        include: 'theme,segment,occupation,category'
      }
    })
    .then(res => res.data)
}

export const fetchContentByIdForContentView = ids => {
  return http
    .get('contents', {
      params: {
        'q[id_in]': ids,
        include: 'theme,segment,occupation,category'
      }
    })
    .then(res => res.data)
}

export const createContent = data =>
  http.post(contentsUrl, data, { 'Content-Type': 'multipart/form-data' })

export const deleteContent = id =>
  http.delete(`${contentsUrl}/${id}`).then(res => res.data)

export const editContent = data => {
  return http
    .put(`${contentsUrl}/${data.id}`, data.data, {
      'Content-Type': 'multipart/form-data'
    })
    .then(res => res.data)
}

export const deleteDocument = ({ contentId, documentId }) => {
  http
    .delete(`${contentsUrl}/${contentId}/documents/${documentId}`)
    .then(res => res.data)
}

export const deleteAudio = contentId => {
  http.delete(`${contentsUrl}/${contentId}/audio`).then(res => res.data)
}

export const fetchContentByThemeId = themeId => {
  return jsonApi.findAll('contents', {
    params: {
      'q[theme_id_eq]': themeId,
      include: 'theme.theme_module.cover'
    }
  })
}

export const fetchContentsByIds = ids => {
  return jsonApi
    .findAll('contents', {
      params: {
        'q[id_in]': ids,
        'page[size]': 20,
        include: 'theme,segment,occupation,category'
      }
    })
    .then(res => res)
}

import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { connect, useDispatch } from 'react-redux'

import {
  Form,
  formValueSelector,
  reduxForm,
  getFormValues,
  Field,
  submit
} from 'redux-form'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import TextArea from '../shared/TextArea'
import DropzoneInput from '../shared/DropzoneInput'
import SimpleEditorInput from '../shared/SimpleEditorInput'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import StyledSVG from '../shared/StyledSVG'

import { createContents, editContent } from '../../store/contents/actions'
import Button from '../button/Button'
import themePuzzleSVG from '../../assets/icons/theme-puzzle.svg'
import downloadSVG from '../../assets/icons/save-sketch.svg'
import ShowFile from '../shared/ShowFile'
import SliderInput from '../shared/SliderInput'
import { required, alreadyFilled } from '../../utils/formUtils'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Montserrat, Source Sans Pro',
    width: '98%',
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    margin: 'auto',
    height: '95%',
    padding: '32px',
    marginBottom: '100px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12
    },
    '& path': {
      stroke: '#4D5E80'
    },
    '& h1': {
      fontWeight: 700,
      color: '#4D5E80',
      fontSize: 32,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16,
      maxWidth: '25%'
    }
  },
  inputField: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content'
  },

  descriptionRootStyle: {
    padding: 0,
    marginBottom: 0,
    border: '1.5px solid #ADB8CC',
    '& .mce-edit-area': {
      minHeight: '120px'
    },
    '& .mce-container-body': {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    '& .mce-btn-group .mce-btn': {
      margin: 0,
      backgroundColor: 'transparent'
    },
    '& .mce-toolbar-grp': {
      width: 'fit-content',
      backgroundColor: '#F6F7F8',
      boxShadow: '3px 11px 14px 6px #6B67671A',
      margin: '0px 30px 30px 40px',
      height: 'fit-content',
      borderRadius: 8
    },
    '& .mce-btn': {
      '& button': {
        height: 50
      }
    },
    '& .mce-panel': {
      backgroundColor: 'unset'
    }
  },
  label: {
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  settedUpContentsInfoSection: {
    marginTop: 40,
    display: 'flex',
    alignItems: 'center',
    gap: 24
  },
  settedUpContentsInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    '& span': {
      color: '#ADB8CC',
      fontSize: 14,
      fontWeight: 500
    },
    '& p': {
      margin: 0,
      color: '#555555',
      fontSize: 18,
      fontWeight: 700
    }
  },
  formLabel: {
    marginTop: 32,
    color: '#ADB8CC',
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 24
  },
  selectStage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 40,
    alignItems: 'flex-start',
    '& label': {
      fontWeight: 600,
      fontSize: 16
    }
  },
  rootSlider: {
    marginLeft: 10,
    '& .MuiSlider-active': {
      boxShadow: 'none'
    },
    '& .Mui-focusVisible, .MuiSlider-thumb:hover': {
      boxShadow: 'none'
    }
  },
  fields: {
    display: 'flex',
    flexDirection: 'column'
  },
  field: {
    marginBottom: 24
  },
  fieldLabel: {
    fontWeight: 600,
    fontSize: 16,
    margin: '0px 0px 8px 0px',
    '& span': {
      fontWeight: 400
    }
  },
  resumeField: {
    '& textarea': {
      height: 200,
      '&::placeholder': {
        color: '#ADB8CC',
        fontSize: 14,
        padding: '20px 30px'
      }
    }
  },
  dropzoneRootStyle: {
    '& div': {
      maxWidth: 'unset !important'
    }
  },
  documents: {
    margin: '20px 0px',
    display: 'flex',
    gap: 12,
    flexWrap: 'wrap'
  },
  linkSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 18
  },
  materialLink: {
    maxWidth: '400px',
    width: '80% !important',
    minWidth: '255px',
    '& label': {
      fontSize: 16,
      color: '#555555',
      fontWeight: 600
    },
    '& .MuiInputBase-root': {
      marginTop: 4
    }
  },
  linkIcon: {
    marginRight: 8
  },
  addLinkSection: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& span': {
      fontSize: 18,
      color: '#9B9B9B',
      [theme.breakpoints.down('970')]: {
        display: 'none'
      }
    }
  },
  addLinkBtn: {
    cursor: 'pointer',
    borderRadius: 50,
    width: 45,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0.75px dashed #9B9B9B'
  },
  listLinks: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: 16,
    height: 'fit-content',
    maxHeight: 250,
    '& img:not(.icon)': {
      borderRadius: 8,
      maxHeight: 250,
      maxWidth: 250,
      objectFit: 'contain',
      width: 'fit-content'
    },
    '& div': {
      alignItems: 'center',
      borderRadius: 8,
      color: '#9B9B9B',
      display: 'flex',
      padding: '6px 8px',
      maxHeight: 250,
      '& img': {
        marginRight: 4
      }
    }
  },
  bottomBtns: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      width: 150
    }
  },
  cancelBtn: {
    width: 146,
    border: '2px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '2px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  saveSketchBtn: {
    backgroundColor: '#386093',
    width: '280px !important',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  iconDisable: {
    '& path': {
      fill: 'rgba(0, 0, 0, 0.26)'
    }
  }
}))

const NewCreateContentForm = ({
  isEdit,
  handleSubmit,
  userOccupations,
  change,
  touch,
  initialValues,
  fieldValues,
  location,
  currentSchool,
  linkValue,
  documents,
  valid,
  ...props
}) => {
  // const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const dispatch = useDispatch()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  const classes = useStyles()
  const settedUpCategory = location.state.category
  const settedUpOccupation = location.state.occupation

  const [blobFiles, setBlobFiles] = React.useState([])
  const [fileToDeleteDropzone, setFileToDeleteDropzone] = React.useState(null)

  React.useEffect(
    () => {
      if (documents?.length > 0) {
        setBlobFiles(documents)
      }
    },
    [documents]
  )

  const handleDeleteFiles = blobName => {
    setBlobFiles(prev => {
      const index = prev.findIndex(item => item && item.name === blobName)
      if (index !== -1) {
        const newState = [...prev.slice(0, index), ...prev.slice(index + 1)]
        setFileToDeleteDropzone(blobName)
        change('documents', newState)
        return newState
      }
      return prev
    })
  }

  const handleChange = (event, input) => {
    touch(input.name)
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const handleSliderChange = (input, newValue) => {
    change('stage', newValue)
  }

  const handleSketchButton = async () => {
    await change('status', 'sketched')
    dispatch(submit('newCreateContentForm'))
  }

  const handleSubmitButton = async () => {
    await change('status', 'done')
    dispatch(submit('newCreateContentForm'))
  }

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        <div className={classes.header}>
          <div className='title'>
            <StyledSVG src={themePuzzleSVG} height={32} width={32} />
            <h1>Adicionar um novo conteúdo</h1>
          </div>
          <span>
            Adicione o conteúdo necessário para completar a categoria e avançar
            no seu foco.
          </span>
        </div>

        <div className={classes.settedUpContentsInfoSection}>
          <div className={classes.settedUpContentsInfo}>
            <span>Foco</span>
            {settedUpOccupation ? (
              <p>{settedUpOccupation?.label}</p>
            ) : (
              <div className={`${classes.inputField}`}>
                <label className={classes.label}>Foco</label>
                <Field
                  component={AsyncSelect}
                  id='occupation_id'
                  name='occupation_id'
                  placeholder='Selecione um foco'
                  handleAsyncChange={handleChange}
                  searchParam='q[title_cont]'
                  touch={touch}
                  request={{
                    path: 'occupations'
                  }}
                />
              </div>
            )}
          </div>
          <div className={classes.settedUpContentsInfo}>
            <span>Categoria</span>
            {settedUpCategory ? (
              <p>{settedUpCategory?.label}</p>
            ) : (
              <div className={`${classes.inputField}`}>
                <label className={classes.label}>Categoria</label>
                <Field
                  component={AsyncSelect}
                  id='category_id'
                  name='category_id'
                  placeholder='Selecione uma categoria'
                  handleAsyncChange={handleChange}
                  searchParam='q[title_cont]'
                  touch={touch}
                  request={{
                    path: 'categories'
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <p className={classes.formLabel}>Conteúdo</p>

        <div className={classes.selectStage}>
          <label>Qual etapa essa categoria se encaixa ?</label>
          <Field
            component={SliderInput}
            className={classes.rootSlider}
            name={'stage'}
            defaultValue={fieldValues?.stage}
            step={1}
            min={1}
            max={4}
            handleSliderChange={handleSliderChange}
            valueLabelDisplay='on'
          />
        </div>

        <div className={classes.fields}>
          <div className={classes.field}>
            <p className={classes.fieldLabel} htmlFor='title'>
              Título
            </p>
            <Field
              component={MaterialInput}
              name='title'
              type='text'
              placeholder='Digite o título'
              autoComplete='off'
              validate={!alreadyFilled(initialValues?.title) && [required]}
            />
          </div>
          <div className={`${classes.field} ${classes.resumeField}`}>
            <p className={classes.fieldLabel} htmlFor='abstract'>
              Resumo
            </p>
            <Field
              component={TextArea}
              name='abstract'
              type='text'
              placeholder='Adicione um novo módulo socioemocional para ampliar sua experiência. Escolha um nome e descrição claros para organização eficaz. Preencha todos os focos para avançar. '
              autoComplete='off'
              validate={!alreadyFilled(initialValues?.abstract) && [required]}
            />
          </div>
          <div className={`${classes.field}`}>
            <p className={classes.fieldLabel} htmlFor='text_content'>
              Texto
            </p>
            <Field
              name='text_content'
              component={SimpleEditorInput}
              additionalToolbar={'image media'}
              addRootStyle={classes.descriptionRootStyle}
              request={{
                type: 'content_images',
                model: 'content_images',
                otherAttributes: [
                  {
                    name: 'school_id',
                    value: currentSchool?.id
                  }
                ]
              }}
              validate={
                !alreadyFilled(initialValues?.text_content) && [required]
              }
            />
          </div>
          <div className={`${classes.field} ${'materialInput'}`}>
            <p className={classes.fieldLabel} htmlFor='title'>
              Arquivos <span>(opcional)</span>
            </p>
            <Field
              component={DropzoneInput}
              maxFiles={100}
              maxSize={524288000}
              name='documents'
              type='file'
              dropzoneRootStyle={classes.dropzoneRootStyle}
              fileToDelete={fileToDeleteDropzone}
              withoutPreview
            />
          </div>
          <div className={classes.documents}>
            {blobFiles?.length > 0 &&
              blobFiles.map(file => {
                return (
                  <ShowFile
                    typeBlob
                    matches={matches}
                    classes={classes}
                    key={file?.filename}
                    file={file}
                    handleDeleteFiles={handleDeleteFiles}
                  />
                )
              })}
            {/* {isEdit &&
              materialFilesInstance.length > 0 &&
              materialFilesInstance.map((file, index) => {
                return (
                  <ShowFile
                    matches={matches}
                    classes={classes}
                    key={file?.filename}
                    file={file}
                    handleDeleteFiles={handleDeleteMaterialFiles}
                  />
                )
              })} */}
          </div>
          {/* <div className={classes.linkSection}>
            <div className={`${classes.materialLink} ${classes.field}`}>
              <p className={classes.fieldLabel} htmlFor='linkInput'>
                Link <span>(opcional)</span>
              </p>
              <Field
                component={MaterialInput}
                name='linkInput'
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleAddLink()
                  }
                }}
                startAdornment={
                  <StyledSVG
                    className={classes.linkIcon}
                    src={linkSVG}
                    width={15}
                    height={15}
                  />
                }
                type='text'
                placeholder='Insira o link aqui'
                autoComplete='off'
              />
            </div>
            <div className={classes.addLinkSection}>
              <div
                onClick={() => {
                  handleAddLink()
                }}
                className={classes.addLinkBtn}
              >
                <StyledSVG src={crossAddSVG} width={18} height={18} />
              </div>
              <span>Adicionar link</span>
            </div>
          </div>
          <div className={classes.listLinks}>
            {links.length > 0 &&
              links.map((link, index) => {
                return (
                  <ShowLink
                    key={`${link}-${index}`}
                    link={link}
                    handleDeleteLinks={() => {
                      setLinks(prev => {
                        const index = prev.findIndex(
                          item => item && item === link
                        )
                        if (index !== -1) {
                          const newState = [
                            ...prev.slice(0, index),
                            ...prev.slice(index + 1)
                          ]
                          return newState
                        }
                        return prev
                      })
                    }}
                  />
                )
              })}
          </div> */}
        </div>

        <div className={classes.bottomBtns}>
          <Button
            onClick={() => {
              props.history.push(`/newthemes/${props.currentTheme.id}/contents`)
            }}
            type='button'
            variant={'outlined'}
            className={classes.cancelBtn}
          >
            Voltar
          </Button>
          {props?.currentContent?.status !== 'done' && (
            <Button
              onClick={handleSketchButton}
              className={`${classes.saveSketchBtn} ${!valid &&
                classes.iconDisable}`}
              endIcon={<StyledSVG src={downloadSVG} width={15} height={15} />}
              disabled={!valid}
            >
              Salvar como rascunho
            </Button>
          )}
          <Button
            onClick={handleSubmitButton}
            className={classes.sendBtn}
            disabled={!valid}
          >
            Próximo
          </Button>
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('newCreateContentForm')
  return {
    segmentValue: selector(state, 'segment_id'),
    documents: selector(state, 'documents'),
    linkValue: selector(state, 'linkInput'),
    currentSchool: state.school.currentSchool.school,
    currentTheme: state.themes.currentItem.item,
    currentContent: state.contents.currentItem.item,
    fieldValues: getFormValues('newCreateContentForm')(state),
    modules: state.modules
  }
}

const mapDispatchToProps = dispatch => ({
  createContents: data => dispatch(createContents(data)),
  editContent: data => dispatch(editContent(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'newCreateContentForm',
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      try {
        if (!props.isEdit) {
          const { stage, links, status, ...formValues } = values
          const newStage = stage || 1
          const newValues = {
            stage: newStage,
            school_id: props.currentSchool.id,
            theme_id: props.currentTheme.id,
            segment_id: props.currentTheme.theme_module.grade.segment_id,
            category_id: props.location.state.category.value,
            status: status || 'done',
            occupation_id: props.location.state.occupation.value,
            ...formValues
          }

          props.createContents(newValues)
          setTimeout(() => {
            props.history.push(`/newthemes/${props.currentTheme.id}/contents`)
          }, 1000)
        } else {
          const { stage, links, status, ...formValues } = values
          const newStage = stage || 1
          const newValues = {
            id: formValues.id,
            data: {
              stage: newStage,
              abstract: formValues.abstract,
              text_content: formValues.text_content,
              title: formValues.title,
              documents: formValues.documents,
              status: status || 'done'
            }
          }
          props.editContent(newValues)
          setTimeout(() => {
            props.history.push(`/newthemes/${props.currentTheme.id}/contents`)
          }, 1000)
        }
      } catch (err) {
        console.error(err)
      }
    }
  })(NewCreateContentForm)
)

import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'
import { areAllPropertiesNullOrUndefined } from '../../utils/helpers'

export const getUserByEmail = payload => {
  try {
    const { email } = payload
    return jsonApi
      .findAll('users', {
        params: {
          'q[email_eq]': email,
          include: 'teacher_subjects'
        }
      })
      .then(res => {
        return res.data
      })
  } catch (err) {
    console.error(err)
  }
}

export const getUsersRSPA = payload => {
  try {
    return jsonApi
      .findAll('users', {
        params: {
          ...payload
        }
      })
      .then(res => {
        return res.data
      })
  } catch (err) {
    console.error(err)
  }
}

export const getUserById = id => {
  try {
    return http.get(`/users/${id}`).then(res => {
      return res.data
    })
  } catch (err) {
    console.error(err)
  }
}

export const getUsers = payload => {
  try {
    const { page, params, schoolId, ...searchParams } = payload

    // schoolId come on in two ways, by payload or by params
    let possibleSchoolId = schoolId || params?.schoolId
    params?.schoolId && delete params.schoolId

    const paramsToReq = {
      ...(!areAllPropertiesNullOrUndefined({ ...searchParams, ...params })
        ? { ...searchParams, ...params }
        : { 'q[s]': 'name asc' })
    }

    delete paramsToReq.hasSchoolId
    delete paramsToReq.isAdmin

    return jsonApi
      .findAll(
        possibleSchoolId ? `users/by_schools/${possibleSchoolId}` : 'users',
        {
          params: {
            'page[number]': page,
            ...paramsToReq,
            include: 'user_config,user_occupations.occupation,child.user'
          }
        }
      )
      .then(res => {
        return res
      })
  } catch (e) {
    console.error(e)
  }
}

export const exportUsers = payload => {
  try {
    return http
      .get(`/users/by_schools/${payload}/create_spreadsheet`)
      .then(res => {
        return res
      })
  } catch (err) {
    console.error(err)
  }
}
export const forceTemporaryPassword = payload => {
  try {
    return http.put(`/users/force_temporary_password`, payload).then(res => {
      return res
    })
  } catch (err) {
    console.error(err)
  }
}

export const deleteUser = payload => {
  try {
    return http.delete(`users/${payload.id}`).then(res => res)
  } catch (err) {
    console.error(err)
  }
}

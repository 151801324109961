import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  currentItem: {
    event_comments: []
  },
  destinations: {
    items: [],
    type: '',
    isFetching: false,
    pagination: {
      total: 1,
      pageCount: 1
    }
  },
  pagination: {
    total: 1,
    page: 1,
    pageCount: 1
  }
}

const eventsReducer = createReducer(initialState, {
  [types.GET_EVENTS]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    isFetching: true,
    isSuccess: false
  }),
  [types.GET_EVENTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload.data,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    isFetching: false
  }),
  [types.GET_EVENTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_EVENT_BY_ID]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    destinations: [],
    isFetching: true
  }),
  [types.GET_EVENT_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    destinations: action.payload?.destinations,
    currentItem: {
      ...state.currentItem.event_comments,
      ...action.payload.data
    }
  }),
  [types.GET_EVENT_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.CREATE_EVENTS]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    isFetching: true,
    isSuccess: false
  }),
  [types.CREATE_EVENTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: [...state.items, action.payload],
    isFetching: false,
    isSuccess: true
  }),
  [types.CREATE_EVENTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    isSuccess: false,
    errorMessage: action.payload
  }),
  [types.GET_EVENT_DESTINATION_BY_ID]: (state, action) => ({
    ...state,
    items: [],
    destinations: {
      items: [],
      type: '',
      pagination: {},
      isFetching: true
    }
  }),
  [types.GET_EVENT_DESTINATION_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    destinations: {
      items: action.payload.items,
      pagination: action.payload.pagination,
      isFetching: false
    }
  }),
  [types.GET_EVENT_DESTINATION_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_EVENT_COMMENTS]: (state, action) => ({
    ...state,
    items: []
  }),
  [types.GET_EVENT_COMMENTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      event_comments: [...action.payload]
    },
    isFetching: false
  }),
  [types.GET_EVENT_COMMENTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.CREATE_EVENT_COMMENTS]: (state, action) => ({
    ...state,
    isFetching: true
  }),
  [types.CREATE_EVENT_COMMENTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      event_comments: [action.payload, ...state.currentItem.event_comments]
    },
    isFetching: false
  }),
  [types.CREATE_EVENT_COMMENTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_EVENT_COMMENT]: (state, action) => ({
    ...state,
    isFetching: true
  }),
  [types.EDIT_EVENT_COMMENT_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false
  }),
  [types.EDIT_EVENT_COMMENT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_EVENT_LIKES]: (state, action) => ({
    ...state,
    items: [],
    isFetching: true
  }),
  [types.GET_EVENT_LIKES_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: { ...state.currentItem, likes: action.payload },
    isFetching: false
  }),
  [types.GET_EVENT_LIKES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_EVENT_FILES]: (state, action) => ({
    ...state,
    items: [],
    isFetching: true
  }),
  [types.GET_EVENT_FILES_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      event_files: state.currentItem.event_files
        ? state.currentItem.event_files.concat(action.payload)
        : action.payload
    },
    isFetching: false
  }),
  [types.GET_EVENT_FILES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.DELETE_EVENT]: (state, action) => ({
    ...state,
    isFetching: true
  }),
  [types.DELETE_EVENT_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: state.items.filter(
      item => String(item.id) !== String(action.payload)
    ),
    isFetching: false
  }),
  [types.DELETE_EVENT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_EVENT]: (state, action) => ({
    ...state,
    isFetching: true,
    isSuccess: false
  }),
  [types.EDIT_EVENT_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: state.items.filter(
      item => String(item.id) !== String(action.payload)
    ),
    isFetching: false,
    isSuccess: true
  }),
  [types.EDIT_EVENT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    isSuccess: false,
    errorMessage: action.payload
  }),
  [types.SET_CURRRENT_EVENT]: (state, action) => ({
    ...state,
    currentItem: action.payload,
    isFetching: false
  }),
  [types.CLEAN_CURRRENT_EVENT]: (state, action) => ({
    ...initialState
  }),
  [types.CLEAN_CURRRENT_DESTINATIONS_EVENT]: (state, action) => ({
    ...state,
    destinations: initialState.destinations
  })
})

export default eventsReducer

import React from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import {
  clearCurrentClassroom,
  createClassroomTeacher,
  editClassroomTeacher
} from '../store/classrooms/actions'

import SearchReactivateTeacherForm from '../components/form/SearchReactivateTeacherForm'
import {
  getTeacherById,
  removeClassroomTeachers,
  getDeletedTeachers
} from '../store/teachers/actions'
import { roles } from '../utils/constants'

const mapStateToProps = ({ auth, school, classrooms, teachers }) => {
  return {
    deletedTeachers: teachers.deletedTeachers.items,
    deletedTeachersPagination: teachers.deletedTeachers.pagination,
    userOccupations: auth.currentOccupation,
    currentTeacher: teachers.currentItem,
    teacherIsFetching: teachers.isFetching,
    currentSchool: school.currentSchool,
    classrooms: classrooms,
    isFetching: teachers.isFetching
  }
}

const mapDispatchToProps = dispatch => ({
  createClassroomTeacher: data => dispatch(createClassroomTeacher(data)),
  editClassroomTeacher: data => dispatch(editClassroomTeacher(data)),
  clearCurrentClassroom: () => dispatch(clearCurrentClassroom()),
  getTeacherById: id => dispatch(getTeacherById(id)),
  removeClassroomTeachers: ids => dispatch(removeClassroomTeachers(ids)),
  getDeletedTeachers: data => dispatch(getDeletedTeachers(data))
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    padding: '1rem 0'
    // maxWidth: 1220
  }
}))

const CreateTeacherPage = ({
  currentSchool,
  createClassroomTeacher,
  userOccupations,
  getDeletedTeachers,
  deletedTeachers,
  deletedTeachersPagination,
  isFetching
}) => {
  const classes = useStyles()
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  return (
    <div className={classes.root}>
      <SearchReactivateTeacherForm
        school={currentSchool}
        createClassroomTeacher={createClassroomTeacher}
        isVersarAdmin={isVersarAdmin}
        deletedTeacher={deletedTeachers}
        getDeletedTeachers={getDeletedTeachers}
        isFetching={isFetching}
        isAdmin={isAdmin}
        pagination={deletedTeachersPagination}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeacherPage)

import React from 'react'
import { Field, reduxForm, Form } from 'redux-form'

import TextArea from '../shared/TextArea'
import { makeStyles } from '@material-ui/styles'
import {
  createEventComment,
  editEventComment,
  getEventComments
} from '../../store/events/actions'

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #00ACDB80',
    maxHeight: 175,
    borderRadius: 16,
    marginLeft: 50,
    '& > div textarea': {
      borderRadius: '16px 16px 0px 0px',
      fontFamily: 'inherit',
      fontSize: 14,
      minHeight: 'unset',
      padding: '25px 40px',
      '&:focus-visible': {
        outline: 'none !important'
      }
    },
    [theme.breakpoints.down('426')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}))

const ReplyForm = ({
  onSubmit,
  handleSubmit,
  reset,
  textColor,
  bgColor,
  hasBorder,
  disabled,
  defaultValue,
  firstComment,
  style,
  rootClass,
  placeholder
}) => {
  const classes = useStyles({ firstComment })

  const handleOnChange = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      handleSubmit()
      reset()
    }
  }
  return (
    <Form
      className={`${classes.root} ${rootClass}`}
      style={style}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Field
        name='content'
        disabled={disabled}
        placeholder={'Digite aqui sua resposta'}
        component={TextArea}
        onKeyDown={handleOnChange}
        defaultValue={defaultValue}
        componentStyle={{ textColor, bgColor, hasBorder }}
      />
    </Form>
  )
}

const createReduxForm = (formId, currentReply) =>
  reduxForm({
    form: formId, // Usa o `formId` dinâmico passado no componente pai
    onSubmit: (values, dispatch, props) => {
      if (currentReply) {
        const newValues = {
          data: {
            type: 'event_comments',
            id: currentReply.id,
            attributes: {
              ...values
            }
          }
        }
        dispatch(editEventComment({ id: currentReply.id, data: newValues }))
        dispatch(getEventComments({ id: props.eventId }))
      } else {
        const newValue = {
          ...values,
          event_id: props.eventId,
          user_id: props.userId,
          parent_id: props.commentParentId
        }
        dispatch(createEventComment(newValue))
      }
    }
  })(ReplyForm)

export default createReduxForm

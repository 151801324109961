import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { makeStyles, styled } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import ReportListSVG from '../assets/icons/reportList.svg'
import ReportHistorySVG from '../assets/icons/reportHistory.svg'
// import ReportCreateSVG from '../assets/icons/reportCreate.svg'

import ButtonCard from '../components/card/ButtonCard'
import StyledSVG from '../components/shared/StyledSVG'
import noReportSVG from '../assets/icons/no-reports.svg'

import { useQuery } from '../utils/helpers'
import ReportsTemplateListView from '../components/view/ReportsTemplateListView'
import ReportsHistoryView from '../components/view/ReportsHistoryView'
import CreateReportForm from '../components/form/CreateReportForm'
import { roles } from '../utils/constants'
import { getReports } from '../store/reports/actions'

const NoReportStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 16,
  marginTop: 18,
  '& h5': {
    color: '#555555'
  },
  [theme.breakpoints.down('sm')]: {
    '& h5': {
      fontSize: 18
    }
  },
  '& img': {
    objectFit: 'contain',
    width: '100%',
    maxWidth: 450
  }
}))

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    padding: '1rem 0'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    margin: 16,
    [theme.breakpoints.down('426')]: {
      '& button': {
        '& svg': {
          width: '41px !important',
          height: '41px !important',
          minWidth: '41px !important',
          minHeight: '41px !important'
        },
        '& p': {
          fontSize: '12px'
        }
      }
    }
  },
  paper: {
    borderRadius: 8,
    padding: 32,
    [theme.breakpoints.down('426')]: {
      padding: 10
    }
  }
}))

const topButtons = [
  {
    id: 1,
    title: 'Diários de turmas',
    icon: ReportListSVG,
    type: 'list'
  },
  {
    id: 2,
    title: 'Histórico de diários',
    icon: ReportHistorySVG,
    type: 'history'
  }
  /* {
    id: 3,
    title: 'Criar diário',
    icon: ReportCreateSVG,
    type: 'create'
  } */
]

export const NoReportsDiv = () => (
  <NoReportStyled>
    <img src={noReportSVG} alt='imagem da lista de diários vazia' />
    <Typography variant='h5'>Você ainda não possui diários</Typography>
  </NoReportStyled>
)

const defaultColor = '#00ACDB'
const disabledColor = '#ADB8CC'

const handleGenerateReport = async (
  reportTemplateId,
  status,
  userId,
  generateReportFunction
) => {
  const data = {
    type: 'reports',
    attributes: {
      report_template_id: reportTemplateId,
      user_id: userId
    }
  }
  generateReportFunction(data)
}

const ReportsPage = ({
  userOccupations,
  getReports,
  reports,
  schoolId,
  location,
  currentTeacherClassrooms
}) => {
  const classes = useStyles()
  const history = useHistory()
  const query = useQuery()
  const isCoordinator = userOccupations?.includes(roles.COORDINATOR)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  const defaultParams = {
    'page[size]': 5,
    'q[year_lteq]': new Date().getFullYear() + 1,
    'q[year_gteq]': new Date().getFullYear(),
    'q[classroom_school_id_eq]': schoolId
  }

  const handleClickButton = type => {
    history.push({ search: `?name=${type}` })
    type !== 'create' && localStorage.setItem('origeNavegate', type)
  }

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        {topButtons
          .filter(
            btn => (isAdmin || isCoordinator ? btn.type !== 'list' : btn.type)
          )
          .map(btn => {
            return (
              <ButtonCard
                key={btn.id}
                styleType={
                  isAdmin || isCoordinator
                    ? 'default'
                    : query.get('name') === btn.type ? 'default' : 'disabled'
                }
                onClick={() => handleClickButton(btn.type)}
              >
                <StyledSVG
                  color={
                    isAdmin || isCoordinator
                      ? defaultColor
                      : query.get('name') === btn.type
                        ? defaultColor
                        : disabledColor
                  }
                  src={btn.icon}
                  width={35}
                  height={35}
                />
                <p>{btn.title}</p>
              </ButtonCard>
            )
          })}
      </div>

      {(query.get('name') === 'history' || query.get('name') === null) && (
        <ReportsHistoryView
          history={history}
          handleClickButton={handleClickButton}
          title={'Histórico'}
          defaultParams={defaultParams}
          isAdmin={isAdmin}
          getReports={getReports}
          reports={reports}
          schoolId={schoolId}
          handleGenerateReport={handleGenerateReport}
        />
      )}
      {query.get('name') === 'list' &&
        !isAdmin &&
        !isCoordinator && (
          <ReportsTemplateListView
            defaultParams={Object.assign(defaultParams, {
              'q[classroom_id_in]': currentTeacherClassrooms
            })}
            getReports={getReports}
            reports={reports}
            handleGenerateReport={handleGenerateReport}
          />
        )}
      {query.get('name') === 'create' &&
        (isAdmin || isCoordinator) && <CreateReportForm />}
    </div>
  )
}

const mapStateToProps = ({ auth, reports, school }) => {
  return {
    userOccupations: auth.currentOccupation,
    schoolId: school.currentSchool && school.currentSchool.school_id,
    reports: reports,
    currentTeacherClassrooms:
      auth.user.data.attributes.classrooms_id_by_teachers
  }
}

const mapDispatchToProps = {
  getReports
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage)

import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'

export const createMaterial = data =>
  http
    .post('/materials', data, { 'Content-Type': 'multipart/form-data' })
    .then(res => res.data)

export const editMaterial = data => {
  return http
    .put(`/materials/${data.get('data[id]')}`, data, {
      'Content-Type': 'multipart/form-data'
    })
    .then(res => res.data)
}

export const deleteMaterialDestination = id => {
  return http.delete(`/material_destinations/${id}`)
}

export const deleteMaterialFile = id => {
  return http.delete(`/material_files/${id}`)
}

export const deleteMaterial = data => http.delete(`/materials/${data}`)

export const fetchMaterialById = ({ id, params }) => {
  return jsonApi
    .find('materials', id, {
      params: {
        ...params,
        include:
          'school,subject,user,files,destinations.child,views.user,destinations.classroom.grade'
      }
    })
    .then(res => {
      return res.data
    })
}

export const createMaterialFile = data => {
  return http.post('/material_files', {
    ...data
  })
}
export const fetchMaterials = ({ params, page }) => {
  return jsonApi
    .findAll('materials', {
      params: {
        'page[number]': page,
        ...params,
        include: 'school,subject,user,files,destinations.classroom.grade'
      }
    })
    .then(res => {
      return res
    })
}

export const exportMaterialReport = id => {
  try {
    return http
      .post(
        '/materials/create_spreadsheet',
        { data: { attributes: { material_id: id } } },
        {
          responseType: 'blob'
        }
      )
      .then(res => res)
  } catch (err) {
    console.error(err)
  }
}

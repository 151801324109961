import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import NewCreateThemeForm from '../components/form/NewCreateThemeForm'
import { getModuleById } from '../store/modules/actions'
import { newGetThemeById } from '../store/themes/actions'
import SearchThemesForm from '../components/form/SearchThemesForm'
import { newFetchThemeCategoriesByThemeId } from '../store/themes/services'
import { fetchContentByThemeId } from '../store/contents/services'
import { getContentsSuccessful } from '../store/contents/actions'

const NewThemesPage = ({
  componentAttribute,
  match,
  isFetching,
  userOccupations,
  getModuleById,
  newGetThemeById,
  currentTheme,
  getContentsSuccessful
}) => {
  const history = useHistory()
  const location = useLocation()
  const moduleId = match.params.moduleId
  const themeId = match.params.themeId
  const [themeOccupations, setThemeOccupations] = React.useState([])

  React.useEffect(async () => {
    if (moduleId) {
      getModuleById({ ids: moduleId })
    } else if (themeId) {
      newGetThemeById({ id: themeId })
      if (componentAttribute?.stepThree === true) {
        const contents = await fetchContentByThemeId(themeId).then(res => {
          return res.data
        })
        let contentsByThemeCategory = []
        await newFetchThemeCategoriesByThemeId(themeId).then(tcs => {
          tcs.forEach(themeCategory => {
            setThemeOccupations(themeOccupations => [
              ...themeOccupations,
              themeCategory.occupation.name
            ])
            contents.forEach(item => {
              if (
                item.occupation_id === themeCategory.occupation_id &&
                themeCategory.category_ids.includes(item.category_id)
              ) {
                contentsByThemeCategory.push(item)
              }
            })
          })
        })
        getContentsSuccessful(contentsByThemeCategory)
      }
    }
  }, [])

  if (componentAttribute?.isCreate === true) {
    return (
      <NewCreateThemeForm
        hasModuleId={!!moduleId}
        step={0}
        location={location}
        history={history}
      />
    )
  } else if (componentAttribute?.stepTwo === true) {
    return <NewCreateThemeForm step={1} location={location} history={history} />
  } else if (componentAttribute?.isEdit === true) {
    return (
      <NewCreateThemeForm
        isEdit
        step={0}
        initialValues={{ ...currentTheme }}
        hasModuleId={!!currentTheme?.theme_module_id}
        location={location}
        history={history}
      />
    )
  } else if (componentAttribute?.stepThree === true) {
    return (
      <NewCreateThemeForm
        themeOccupations={themeOccupations}
        currentTheme={{ ...currentTheme }}
        step={2}
        location={location}
        history={history}
      />
    )
  }
  return <SearchThemesForm />
}

const mapStateToProps = (state, props) => {
  return {
    currentSchool: state.school.currentSchool.school,
    currentTheme: state.themes.currentItem.item
  }
}

const mapDispatchToProps = dispatch => ({
  getModuleById: data => dispatch(getModuleById(data)),
  newGetThemeById: data => dispatch(newGetThemeById(data)),
  getContentsSuccessful: data => dispatch(getContentsSuccessful(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewThemesPage)

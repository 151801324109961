import React from 'react'
import { connect } from 'react-redux'

import SendMessageForm from '../components/form/SendMessageForm'
import {
  createCommunication,
  cleanSuccess
} from '../store/communications/actions'
import { roles } from '../utils/constants'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem 0',
    width: '98%'
  }
}))

const mapStateToProps = ({ auth, school, communications }) => {
  return {
    currentSchool: school.currentSchool,
    selectedSchoolItem: school.selectedItem,
    userOccupations: auth.currentOccupation,
    isSuccess: communications.isSuccess
  }
}

const mapDispatchToProps = {
  createCommunication,
  cleanSuccess
}

const SendMessagePage = ({
  currentSchool,
  selectedSchoolItem,
  userOccupations,
  createCommunication,
  cleanSuccess,
  isSuccess
}) => {
  const isAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SendMessageForm
        createCommunication={createCommunication}
        selectedSchool={selectedSchoolItem}
        school={currentSchool}
        isAdmin={isAdmin}
        isSuccess={isSuccess}
        cleanSuccess={cleanSuccess}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SendMessagePage)

import React from 'react'
import { makeStyles } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import linkSVG from '../../assets/icons/material-link.svg'

const useStyles = makeStyles(theme => ({
  deleteImgButton: {
    width: 15,
    margin: '2px -8px',
    color: 'gray !important',
    cursor: 'pointer'
  },
  link: {
    display: 'flex',
    '& p': {
      backgroundColor: 'rgba(218, 218, 218, 0.2)',
      borderRadius: 8,
      margin: 0,
      display: 'flex',
      gap: 14,
      padding: '5px 10px',
      width: 'fit-content',
      alignItems: 'center',
      '& a': {
        color: theme.palette.text.secondary,
        textDecoration: 'none'
      }
    }
  }
}))

const ShowLink = ({ link, handleDeleteLinks, isInstance }) => {
  const classes = useStyles()
  return (
    <div className={classes.link}>
      <p>
        <img src={linkSVG} alt='image' width='21' height='21' />
        <span>{link.link_url || link}</span>
        <HighlightOffIcon
          className={classes.deleteImgButton}
          onClick={() => {
            isInstance ? handleDeleteLinks(link.id, true) : handleDeleteLinks()
          }}
        />
      </p>
    </div>
  )
}

export default ShowLink

import http from '../../utils/http'
import jsonApi from '../../utils/jsonApi'

const fetchThemesUrl = '/themes'

export const fetchThemes = ({
  value,
  option,
  page = 1,
  per = 5,
  sort = 'created_at desc',
  hasSchoolId = false,
  hasModule = true,
  schoolId
}) => {
  const valueIsNotNumber = isNaN(value)
  const contentKey = valueIsNotNumber
    ? 'q[contents_title_cont]'
    : 'q[contents_id_eq]'
  const moduleKey = valueIsNotNumber
    ? 'q[theme_module_title_cont]'
    : 'q[theme_module_id_eq]'

  const paramSearch =
    option === 'Conteúdos'
      ? { [contentKey]: value }
      : option === 'Módulos'
        ? { [moduleKey]: value }
        : { [valueIsNotNumber ? 'q[title_cont]' : 'q[id_eq]']: value }

  const paramHasModule = !hasModule
    ? { 'q[theme_module_id_not_null]': hasModule }
    : ''
  let school = hasSchoolId === true && { 'q[school_id_eq]': schoolId }

  return http
    .get(fetchThemesUrl, {
      params: {
        'page[number]': page,
        'page[size]': per,
        'q[school_id_not_null]': hasSchoolId,
        'q[s]': sort,
        include: 'segments,skills,theme_module',
        ...paramSearch,
        ...paramHasModule,
        ...school
      }
    })
    .then(res => res.data)
}

export const fetchThemeById = ids => {
  return http
    .get(fetchThemesUrl, {
      params: {
        'q[id_in]': ids,
        include:
          'theme_module,skills,cover,theme_module.grade,theme_module.grade.segment'
      }
    })
    .then(res => res.data)
}

export const newFetchThemeById = id => {
  return jsonApi
    .find('theme', id, {
      params: {
        include: 'theme_module.grade.segment,cover,skills'
      }
    })
    .then(res => res)
}
export const newFetchThemeCategoriesByThemeId = id => {
  return jsonApi
    .findAll('theme_category', {
      params: {
        'q[theme_id_eq]': id,
        include: 'occupation'
      }
    })
    .then(res => res.data)
}

export const newFetchThemes = ({ params, page }) => {
  return jsonApi
    .findAll('theme', {
      params: {
        'page[number]': page,
        'q[s]': 'created_at desc',
        'page[size]': 5,
        ...params,
        include: 'skills,theme_module'
      }
    })
    .then(res => {
      return res
    })
}

export const deleteCategoryFromThemeCategory = ({
  categoryId,
  themeCategoryId
}) => {
  return http
    .put(`/theme_categories/${themeCategoryId}/delete_category/${categoryId}`)
    .then(res => res.data)
}

export const deleteThemeCategory = id => {
  return http.delete(`/theme_categories/${id}`).then(res => res.data)
}

export const newFetchThemeContents = async ({ id, page, per }) => {
  let currentPage = 1
  const response = await jsonApi
    .findAll('content', {
      params: {
        'page[number]': currentPage,
        'q[theme_id_eq]': id
      }
    })
    .then(res => res)
  let fullResponse = { ...response }
  while (fullResponse.data.length < fullResponse.meta.total_count) {
    currentPage += 1
    let responseForNextPage = await jsonApi
      .findAll('content', {
        params: {
          'page[number]': currentPage,
          'q[theme_id_eq]': id
        }
      })
      .then(res => res)
    fullResponse.data = [...fullResponse.data, ...responseForNextPage.data]
  }
  return fullResponse
}

export const fetchThemeContents = ({ id, page, per }) =>
  http
    .get('/contents', {
      params: {
        'q[theme_id_in]': id,
        'page[number]': page || 1,
        'page[size]': per || 20
      }
    })
    .then(res => res.data)

export const createTheme = data => {
  return jsonApi.create('theme', data).then(res => res.data)
}

export const fetchSegmentByModule = moduleId => {
  return jsonApi
    .find('theme_module', moduleId, {
      params: {
        include: 'grade,grade.segment'
      }
    })
    .then(res => res)
}

export const editTheme = data => {
  return jsonApi
    .put('theme', { id: data.id, ...data.data })
    .then(res => res.data)
}

export const deleteTheme = id =>
  http.delete(`${fetchThemesUrl}/${id}`).then(res => res.data)

export const fetchThemeSegments = id =>
  http.get(`${fetchThemesUrl}/${id}/segments`).then(res => res.data)

export const fetchTheme = id => {
  return jsonApi
    .find('themes', id, {
      params: {
        include:
          'skills,theme_module,segments,cover,theme_module.grade,theme_module.grade.segment'
      }
    })
    .then(res => res)
}

export const fetchThemeCategoryById = id => {
  return jsonApi
    .find('theme_category', id, {
      params: {
        include: 'occupation,theme'
      }
    })
    .then(res => res.data)
}

export const fetchThemeCategory = id => {
  return jsonApi.findAll('theme_category', {
    params: {
      'q[theme_id_eq]': id,
      include: 'occupation'
    }
  })
}

export const cloneTheme = data => {
  return jsonApi.create('clone_theme', data).then(res => res.data)
}

export const addCategoryOnTheme = data => {
  return http
    .put(`theme_categories/${data.id}/add_category`, {
      data: {
        attributes: {
          category_ids: data.categoryIds
        }
      }
    })
    .then(res => res.data)
}

export const createThemeCategory = data => {
  return http.post('theme_categories', {
    data: {
      type: 'theme_categories',
      attributes: {
        theme_id: data.themeId,
        occupation_id: data.occupationId,
        category_ids: data.categoryIds
      }
    }
  })
}

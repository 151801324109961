export const SIGN_IN = 'auth:SIGN_IN'
export const SIGN_IN_SUCCESSFUL = 'auth:SIGN_IN_SUCCESSFUL'
export const SIGN_IN_FAILURE = 'auth:SIGN_IN_FAILURE'

export const LOGOUT = 'auth:LOGOUT'
export const LOGOUT_SUCCESSFUL = 'auth:LOGOUT_SUCCESSFUL'
export const LOGOUT_FAILURE = 'auth:LOGOUT_FAILURE'

export const SWITCH_USER = 'auth: SWITCH_USER'
export const SWITCH_USER_SUCCESSFUL = 'auth: SWITCH_USER_SUCCESSFUL'
export const SWITCH_USER_FAILURE = 'auth: SWITCH_USER_FAILURE'

export const UPDATE_USER_FROM_SIGNIN = 'user: UPDATE_USER_FROM_SIGNIN'

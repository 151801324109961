import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  smartphone: {
    position: 'relative',
    width: '280px',
    height: '550px',
    margin: 'auto',
    border: '10px black solid',
    borderTopWidth: '30px',
    borderBottomWidth: '20px',
    borderRadius: '20px',
    fontSize: '0.7rem',
    '&::before': {
      content: '',
      display: 'block',
      width: '30px',
      height: '5px',
      position: 'absolute',
      top: '-30px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#333',
      borderRadius: '8px'
    }
  },
  content: {
    height: '500px',
    objectFit: 'contain'
  },
  contentBoby: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%',
    '& img': {
      objectFit: 'contain',
      height: 'auto'
    },
    scrollbarWidth: 'none' /* "auto" or "thin" */,
    '&::-webkit-scrollbar': {
      width: 0
    }
  },
  iframe: {
    width: '100%',
    border: 'none',
    height: '100%'
  }
}))

const MobilePreview = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.smartphone}>
      <div className={classes.content}>
        <div className={classes.contentBoby}>{children}</div>
      </div>
    </div>
  )
}

export default MobilePreview

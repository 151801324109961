import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import * as types from './types'

export const getEvents = createAction(types.GET_EVENTS)
export const getEventsSuccessful = createAction(types.GET_EVENTS_SUCCESSFUL)
export const getEventsFailure = createAction(types.GET_EVENTS_FAILURE)

export const getEventById = createAction(types.GET_EVENT_BY_ID)
export const getEventByIdSuccessful = createAction(
  types.GET_EVENT_BY_ID_SUCCESSFUL
)
export const getEventByIdFailure = createAction(types.GET_EVENT_BY_ID_FAILURE)

export const createEvents = createAction(types.CREATE_EVENTS)
export const createEventsSuccessful = createAction(
  types.CREATE_EVENTS_SUCCESSFUL
)
export const createEventsFailure = createAction(types.CREATE_EVENTS_FAILURE)

export const getEventDestinationById = createAction(
  types.GET_EVENT_DESTINATION_BY_ID
)
export const getEventDestinationByIdSuccessful = createAction(
  types.GET_EVENT_DESTINATION_BY_ID_SUCCESSFUL
)
export const getEventDestinationByIdFailure = createAction(
  types.GET_EVENT_DESTINATION_BY_ID_FAILURE
)

export const getEventComments = createAction(types.GET_EVENT_COMMENTS)
export const getEventCommentsSuccessful = createAction(
  types.GET_EVENT_COMMENTS_SUCCESSFUL
)
export const getEventCommentsFailure = createAction(
  types.GET_EVENT_COMMENTS_FAILURE
)

export const getEventLikes = createAction(types.GET_EVENT_LIKES)
export const getEventLikesSuccessful = createAction(
  types.GET_EVENT_LIKES_SUCCESSFUL
)
export const getEventLikesFailure = createAction(types.GET_EVENT_LIKES_FAILURE)

export const createEventComment = createAction(types.CREATE_EVENT_COMMENTS)
export const createEventCommentSuccessful = createAction(
  types.CREATE_EVENT_COMMENTS_SUCCESSFUL
)
export const createEventCommentFailure = createAction(
  types.CREATE_EVENT_COMMENTS_FAILURE
)

export const editEventComment = createAction(types.EDIT_EVENT_COMMENT)
export const editEventCommentSuccessfull = createAction(
  types.EDIT_EVENT_COMMENT_SUCCESSFUL
)
export const editEventCommentFailure = createAction(
  types.EDIT_EVENT_COMMENT_FAILURE
)

export const getEventFiles = createAction(types.GET_EVENT_FILES)
export const getEventFilesSuccessful = createAction(
  types.GET_EVENT_FILES_SUCCESSFUL
)
export const getEventFilesFailure = createAction(types.GET_EVENT_FILES_FAILURE)

export const deleteEvent = createAction(types.DELETE_EVENT)
export const deleteEventSuccessful = createAction(types.DELETE_EVENT_SUCCESSFUL)
export const deleteEventFailure = createAction(types.DELETE_EVENT_FAILURE)

export const editEvent = createAction(types.EDIT_EVENT)
export const editEventSuccessful = createAction(types.EDIT_EVENT_SUCCESSFUL)
export const editEventFailure = createAction(types.EDIT_EVENT_FAILURE)

export const setCurrentEvent = createAction(types.SET_CURRRENT_EVENT)
export const cleanEventSuccessful = createAction(types.CLEAN_CURRRENT_EVENT)
export const cleanCurrentDestinationsEvent = createAction(
  types.CLEAN_CURRRENT_DESTINATIONS_EVENT
)

export const getEventDetailsRSPA = createPromiseAction(
  'rspa: GET_EVENT_DETAILS'
)()
export const getEventDestinationsRSPA = createPromiseAction(
  'rspa: GET_EVENT_DESTINATIONS'
)()
export const getEventForAllDestinationsRSPA = createPromiseAction(
  'rspa: GET_EVENT_ALL_DESTINATIONS'
)()
export const getClassroomsCountBySchoolRSPA = createPromiseAction(
  'rspa: GET_CLASSROOMS_COUNT_BY_SCHOOL'
)()
export const getEventConfirmationRSPA = createPromiseAction(
  'rspa: GET_EVENT_CONFIRMATION'
)()
export const getEventNotConfirmedRSPA = createPromiseAction(
  'rspa: GET_EVENT_NOT_CONFIRMED'
)()

import http from '../../utils/http'
import jsonApi from '../../utils/jsonApi'

// Endpoints
const signInEndpoint = '/auth/sign_in'
const signOutEndpoint = '/auth/sign_out'

export const signIn = credentials => {
  const canHaveProjectIdByParams =
    process.env.REACT_APP_API_ENV !== 'production' &&
    process.env.REACT_APP_API_ENV !== 'staging'
  return http
    .post(signInEndpoint, {
      user_application_id: canHaveProjectIdByParams
        ? credentials.project_id || process.env.REACT_APP_PROJECT_ID
        : process.env.REACT_APP_PROJECT_ID,
      datagateway_token: credentials.token
    })
    .then(res => res)
}

export const signOut = () => http.delete(signOutEndpoint)

export const createUser = data => {
  return jsonApi.create('user', data).then(res => res.data)
}

export const confirmPassword = data => {
  return http.post('users/password_confirmation', data).then(res => res)
}

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { useHistory } from 'react-router-dom'

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

import { makeStyles } from '@material-ui/core/styles'

import StyledSVG from '../components/shared/StyledSVG'

import closedEyeSVG from '../assets/icons/eye-closed.svg'
import eyeSVG from '../assets/icons/eye-icon.svg'

import Button from '../components/button/Button'
import { deleteUserRSPA } from '../store/users/actions'
import { confirmPassword } from './../store/auth/actions'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '98%',
    fontFamily: 'Montserrat, Source Sans Pro'
  },
  root: {
    boxShadow: '-10px 10px 10px 0px #1E1E1E0D',
    width: '100%',
    borderRadius: 16,
    backgroundColor: '#FFFFFF',
    padding: '40px 27px 30px 27px',
    height: 'fit-content'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 27,
    maxWidth: 420,
    '& h1': {
      color: '#375F92',
      fontWeight: 700,
      fontSize: 24,
      margin: 0
    },
    '& p': {
      color: '#868E96',
      fontWeight: 400,
      fontSize: 14,
      margin: 0
    }
  },
  horizontalBar: {
    height: 1,
    width: '100%',
    border: '1px solid #CED6E080',
    margin: '24px 0px 30px'
  },
  passwordWrapper: {
    '& span': {
      color: '#555555',
      fontWeight: 700,
      fontSize: 16
    }
  },
  passwordInput: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    marginTop: 6,
    '& .input': {
      fontSize: 20,
      paddingLeft: 16,
      width: 400,
      height: 48,
      border: '1px solid #ADB8CC',
      borderRadius: 8
    },
    '& .passwordBtn': {
      cursor: 'pointer'
    }
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 169,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  continueBtn: {
    border: '1px solid #386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#386093',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  btnSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16,
    marginTop: 48
  },
  messagesWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  errorMessage: {
    borderRadius: 16,
    backgroundColor: '#EF7C8F1A',
    width: 'fit-content',
    color: '#EF7C8F',
    margin: '30px',
    padding: '0px 15px 0px 15px'
  }
}))

const DeleteProfilePage = props => {
  const { confirmPassword, user, userId, deleteUserRSPA } = props
  const [showPassword, setShowPassword] = React.useState(false)
  const [password, setPassword] = React.useState()
  const [showError, setShowError] = React.useState(false)
  const classes = useStyles()
  const history = useHistory()

  const handleConfirmDelete = async () => {
    if (password) {
      await confirmPassword({ email: user.email, password })
        .then(response => {
          deleteUserRSPA({ userId })
        })
        .catch(error => {
          console.error(error)
          setShowError(true)
        })
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.header}>
          <h1>Exclusão de conta</h1>
          <p>
            Ao confirmar a exclusão, sua conta será permanentemente removida e
            todos os seus dados serão apagados. Essa ação nao pode ser desfeita
          </p>
        </div>
        <div className={classes.horizontalBar} />
        <div className={classes.passwordWrapper}>
          <span>Senha</span>
          <div className={classes.passwordInput}>
            <input
              onChange={event => {
                if (showError) {
                  setShowError(false)
                }
                setPassword(event.target.value)
              }}
              className='input'
              type={showPassword ? 'text' : 'password'}
            />
            {showPassword ? (
              <div
                onClick={() => setShowPassword(false)}
                className='passwordBtn'
              >
                <StyledSVG src={eyeSVG} />
              </div>
            ) : (
              <div
                onClick={() => setShowPassword(true)}
                className='passwordBtn'
              >
                <StyledSVG src={closedEyeSVG} />
              </div>
            )}
          </div>
        </div>
        {/* <div className={classes.horizontalBar} /> */}
        <div className={classes.btnSection}>
          <Button
            className={classes.continueBtn}
            variant='outlined'
            startIcon={<KeyboardBackspaceIcon />}
            onClick={() => history.push('/settings')}
          >
            Voltar
          </Button>
          <Button onClick={handleConfirmDelete} className={classes.cancelBtn}>
            Deletar
          </Button>
        </div>
      </div>
      <div className={classes.messagesWrapper}>
        {showError && (
          <div className={classes.errorMessage}>
            <h3>
              A senha inserida está incorreta. Por favor, tente novamente.
            </h3>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userId: state.auth.user.data.id,
    user: state.auth.user.data.attributes
  }
}

const mapDispatchToProps = dispatch => {
  return {
    confirmPassword: data => dispatch(confirmPassword.request(data)),
    deleteUserRSPA: data => dispatch(deleteUserRSPA.request(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'deleteProfileForm',
    enableReinitialize: true,
    onSubmit: async (values, dispatch, props) => {}
  })(DeleteProfilePage)
)

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box } from '../form/styles/CreateModuleForm.style'
import Label from '../label/Label'
import SettingsLayoutForm from '../form/SettingsLayoutForm'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    textAlign: 'center',
    '& p': {
      fontSize: 16
    },
    '& h2': {
      marginBottom: '1rem'
    }
  },
  box: {
    margin: 'auto auto 2rem'
  }
}))

const SettingsLayoutView = ({ schoolsList, isAdmin, updateLayoutSchool }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/* <Box className={classes.box}>
        <Label>Importação</Label>
        <ImportStudentsForm />
      </Box> */}
      <Box className={classes.box}>
        <Label>Personalizar Layout</Label>
        <SettingsLayoutForm
          isAdmin={isAdmin}
          schoolsList={schoolsList}
          updateLayoutSchool={updateLayoutSchool}
        />
      </Box>
    </div>
  )
}

export default SettingsLayoutView

import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Tooltip from '@material-ui/core/Tooltip'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import Label from '../label/Label'
import ConfirmModal from '../modal/ConfirmModal'
import jsonApi from '../../utils/jsonApi'
import { showAlertMessage } from '../../store/alert/actions'
import { groupByKey } from '../../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 1220,
    marginBottom: '2rem',
    '& a': {
      textDecoration: 'none'
    },
    '& h2,h3': {
      color: theme.palette.primary.main
    }
  },
  label: {
    margin: '1.5rem 1rem',
    '& button': {
      marginLeft: '2rem'
    }
  },
  list: {
    borderRadius: 8,
    maxWidth: 950,
    padding: '2rem 2.2rem 2rem 0'
  },
  cardAddClass: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'center',
    '& svg': {
      fontSize: '5rem'
    },
    [theme.breakpoints.up('md')]: {
      color: theme.palette.primary.main,
      margin: 'auto 1rem'
    }
  },
  themeContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr'
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    }
  },
  themeBox: {
    borderRadius: '8px',
    boxShadow: '3px 11px 14px 6px rgb(107 103 103 / 10%)',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    padding: '2rem',
    width: 250,
    '& h3': {
      fontSize: 16
    },
    '& p': {
      margin: 0
    },
    '& svg.MuiSvgIcon-colorSecondary': {
      cursor: 'pointer',
      alignSelf: 'flex-end',
      position: 'absolute'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '1rem'
    }
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  editBtn: {
    backgroundColor: '#386093',
    height: 44,
    width: 146,
    color: '#FFFF',
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  useBtn: {
    marginTop: 12,
    color: '#FFFF',
    backgroundColor: '#386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box component='div'>{children}</Box>}
    </div>
  )
}

const ThemeScheduleView = ({
  cycles,
  getGradesById,
  grade,
  template,
  selectedSchool,
  themeSchedules,
  getGradeThemeSchedules,
  schoolId
}) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0)
  const [schedules, setSchedules] = React.useState([])
  const [historicSchedules, setHistoricSchedules] = React.useState([])
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    title: null,
    id: null
  })
  const [reRenderShedules, setReRenderShedules] = React.useState(false)
  const [countRender, setCountRender] = React.useState(0)

  const scheduleTemplate =
    grade.schedule_templates && grade.schedule_templates[0]

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  React.useEffect(
    () => {
      if (themeSchedules) {
        const groupByCycle = groupByKey(themeSchedules, 'cycle_id')
        groupByCycle && setSchedules(groupByCycle)
      }
    },
    [scheduleTemplate]
  )

  React.useEffect(
    () => {
      if (reRenderShedules === true && countRender >= 3) {
        getGradeThemeSchedules({
          gradeId: grade.id,
          schoolId,
          year: new Date().getFullYear()
        })
      }
      setReRenderShedules(false)
      setCountRender(countRender + 1)
    },
    [reRenderShedules]
  )

  React.useEffect(
    () => {
      const loadData = async () => {
        if (value === 2) {
          await jsonApi
            .find('theme_schedule', '', {
              params: {
                'page[size]': 20,
                'q[school_id_eq]': schoolId,
                'q[grade_id_eq]': grade.id,
                'q[cycle_id_not_null]': true,
                'q[start_at_lteq]': new Date(),
                'q[s]': 'start_at',
                include: 'theme.theme_module'
              }
            })
            .then(res => {
              const groupByCycle = groupByKey(res.data, 'cycle_id')
              groupByCycle && setHistoricSchedules(groupByCycle)
            })
        } else if (value === 0) {
          // trecho adicionado por causa de um bug onde os themesSchedules não são renderizados
          if (!themeSchedules.length) {
            setReRenderShedules(true)
          }
        }
      }
      loadData()
    },
    [value]
  )

  const schedulePanelList = (
    <>
      {Object.entries(schedules).length > 0 &&
        Object.entries(schedules)
          .filter(([key, value]) => key !== 'null')
          .map(([key, value]) => {
            const cycle = cycles?.find(cycle => cycle.id === String(key))
            return (
              <div key={`cycle[${key}]`} style={{ marginBottom: '2rem' }}>
                <h2 style={{ marginLeft: '2rem' }}>{cycle?.name}</h2>
                <div className={classes.themeContainer}>
                  {value.map((sched, index) => {
                    const theme = sched.theme || {}
                    return (
                      <Paper
                        key={`sched[${sched.id || index}]`}
                        className={classes.themeBox}
                        elevation={0}
                      >
                        <HighlightOffIcon
                          color='secondary'
                          onClick={() => {
                            setConfirmModal({
                              id: sched.id,
                              title: theme.title,
                              open: true
                            })
                          }}
                        />
                        <h3>{theme.title}</h3>
                        {theme.theme_module?.title && (
                          <p>Módulo: {theme.theme_module?.title}</p>
                        )}
                        <p>
                          {moment(sched.start_at).isAfter(
                            moment().format('YYYY-MM-DD'),
                            'day'
                          )
                            ? 'Publicar em: '
                            : 'Publicado: '}
                          {moment(sched.start_at).format('DD/MM/YYYY')}
                        </p>
                        <Button
                          className={classes.editBtn}
                          onClick={() => {
                            history.push(
                              `${history.location.pathname}/${
                                scheduleTemplate.id
                              }`,
                              { theme, cycle: cycle, themeSchedule: sched }
                            )
                          }}
                        >
                          Editar
                        </Button>
                      </Paper>
                    )
                  })}
                  <Paper className={classes.themeBox} elevation={0}>
                    <Tooltip title='Adicionar tema'>
                      <div
                        className={classes.cardAddClass}
                        onClick={() =>
                          history.push(
                            `${history.location.pathname}/${
                              scheduleTemplate.id
                            }/search?name=${cycle.name}`,
                            { cycle }
                          )
                        }
                      >
                        <AddCircleOutlineIcon />
                      </div>
                    </Tooltip>
                  </Paper>
                </div>
              </div>
            )
          })}
      {Object.entries(schedules).length === 0 && (
        <>
          <Paper className={classes.themeBox} elevation={0}>
            <Tooltip title='Adicionar tema'>
              <div
                className={classes.cardAddClass}
                onClick={() =>
                  history.push(
                    `${history.location.pathname}/${scheduleTemplate.id}/search`
                  )
                }
              >
                <AddCircleOutlineIcon />
              </div>
            </Tooltip>
          </Paper>
        </>
      )}
    </>
  )
  const templatePanelList = (
    <>
      {template?.rowOrder &&
        template.rowOrder.map(rowId => {
          const row = template.rows[rowId]
          const themes = row.theme_ids.map(themeId => {
            const theme = template.themes[themeId]
            return theme || ''
          })
          return (
            <div key={`row[${rowId}]`} style={{ marginBottom: '2rem' }}>
              <h2 style={{ marginLeft: '2rem' }}>{row.name}</h2>
              <div className={classes.themeContainer}>
                {themes.map((theme, index) => {
                  const themeSchedule =
                    themeSchedules &&
                    themeSchedules.find(
                      sched => String(sched.theme_id) === theme.id
                    )
                  return (
                    <Paper
                      key={`theme[${theme.id || index}]`}
                      className={classes.themeBox}
                      elevation={0}
                    >
                      <h3>{theme.theme}</h3>
                      {theme.module && <p>Módulo: {theme.module}</p>}
                      {themeSchedule ? (
                        <p>
                          {moment(themeSchedule.start_at).isAfter(
                            moment().format('YYYY-MM-DD'),
                            'day'
                          )
                            ? 'Publicar em: '
                            : 'Publicado: '}
                          {moment(themeSchedule.start_at).format('DD/MM/YYYY')}
                        </p>
                      ) : (
                        <p>Não publicado</p>
                      )}
                      {!themeSchedule ? (
                        <Button
                          className={classes.useBtn}
                          onClick={() => {
                            history.push(
                              `${history.location.pathname}/${
                                scheduleTemplate.id
                              }`,
                              { theme, cycle: row }
                            )
                          }}
                        >
                          Usar
                        </Button>
                      ) : (
                        <Button disabled>Usado</Button>
                      )}
                    </Paper>
                  )
                })}
              </div>
            </div>
          )
        })}
    </>
  )

  const historicPanelList = (
    <>
      {Object.entries(historicSchedules).map(([key, value]) => {
        const cycle = cycles?.find(cycle => cycle.id === String(key))
        return (
          <div key={`cycle[${key}]`} style={{ marginBottom: '2rem' }}>
            <h2 style={{ marginLeft: '2rem' }}>{cycle?.name}</h2>
            <div className={classes.themeContainer}>
              {value.map((sched, index) => {
                const theme = sched.theme || {}
                return (
                  <Paper
                    key={`sched[${sched.id || index}]`}
                    className={classes.themeBox}
                    elevation={0}
                  >
                    <h3>{theme.title}</h3>
                    {theme.theme_module?.title && (
                      <p>Módulo: {theme.theme_module?.title}</p>
                    )}
                    <p>
                      {moment(sched.start_at).isAfter(
                        moment().format('YYYY-MM-DD'),
                        'day'
                      )
                        ? 'Publicar em: '
                        : 'Publicado: '}
                      {moment(sched.start_at).format('DD/MM/YYYY')}
                    </p>
                  </Paper>
                )
              })}
            </div>
          </div>
        )
      })}
    </>
  )

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        style={{
          alignContent: 'baseline',
          height: '100%',
          minHeight: 'calc(100vh - 160px)'
        }}
      >
        <Grid item xs={12} className={classes.label}>
          {selectedSchool?.id && <Label>Escola: {selectedSchool.name}</Label>}
          <Label>
            {grade.segment?.name} - {grade.name}
          </Label>
        </Grid>
        <AppBar position='static' color='default'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='simple tabs'
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
          >
            <Tab
              label='Agendamento'
              id={'simple-tab-0'}
              aria-controls={'simple-tabpanel-0'}
            />
            <Tab
              label='Temas sugeridos'
              id={'simple-tab-1'}
              aria-controls={'simple-tabpanel-1'}
            />
            <Tab
              label='Histórico'
              id={'simple-tab-2'}
              aria-controls={'simple-tabpanel-2'}
            />
          </Tabs>
        </AppBar>
        <Grid item xs={12} sm={6} className={classes.list}>
          <TabPanel value={value} index={0}>
            {schedulePanelList}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {templatePanelList}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {historicPanelList}
          </TabPanel>
        </Grid>
      </Grid>
      <div style={{ textAlign: 'end', marginBottom: '2rem' }}>
        <Button
          className={classes.backBtn}
          variant='outlined'
          type='button'
          onClick={() => history.push('/theme-schedule/grades')}
        >
          Voltar
        </Button>
      </div>
      <ConfirmModal
        title={`Deseja remover o tema "${confirmModal.title}" do agendamento?`}
        open={confirmModal.open}
        setOpen={value => {
          if (value) {
            setConfirmModal({ ...confirmModal, open: value })
          } else {
            setConfirmModal({ id: null, title: '', open: value })
          }
        }}
        onConfirm={async () => {
          await jsonApi
            .destroy('theme_schedule', confirmModal.id)
            .then(res => {
              dispatch(
                showAlertMessage({
                  message: 'Tema removido do agendamento.',
                  severity: 'success'
                })
              )
              getGradesById(grade.id)
              getGradeThemeSchedules({
                gradeId: grade.id,
                schoolId,
                year: new Date().getFullYear()
              })
            })
            .catch(err => {
              console.error(err)
              dispatch(
                showAlertMessage({
                  message: 'Erro ao remover tema do agendamento.',
                  severity: 'error'
                })
              )
            })
        }}
      />
    </div>
  )
}

export default ThemeScheduleView

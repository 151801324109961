import React from 'react'
import UserAvatar from '../shared/UserAvatar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import deleteSVG from '../../assets/icons/pattern-delete-icon.svg'
import reportSVG from '../../assets/icons/report-icon.svg'
import Pagination from '@material-ui/lab/Pagination'
import CustomTable from '../table/CustomTable'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Field,
  Form,
  formValueSelector,
  reduxForm,
  change,
  getFormValues
} from 'redux-form'

import AsyncSelect from '../shared/AsyncSelect'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import StyledSVG from './../shared/StyledSVG'
import filterSVG from '../../assets/icons/filter-icon.svg'
import listSVG from '../../assets/icons/material-list.svg'
import {
  getClassroomById,
  clearCurrentClassroom
} from '../../store/classrooms/actions'
import { getGradesById, cleanGrade } from '../../store/grades/actions'
import { getUserById } from '../../store/users/actions'
import MaterialInput from '../shared/MaterialInput'
import Button from '../button/Button'
import { getMaterials, deleteMaterial } from '../../store/materials/actions'
import { roles } from '../../utils/constants'
import moment from 'moment'

import videoSVG from '../../assets/icons/material-video.svg'
import folderSVG from '../../assets/icons/material-folder.svg'
import linkSVG from '../../assets/icons/link.svg'
import fileSVG from '../../assets/icons/material-file.svg'
import audioSVG from '../../assets/icons/audio.svg'

import Tooltip from '@material-ui/core/Tooltip'
import ConfirmModal from '../modal/ConfirmModal'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 40,
    '& h1': {
      color: '#386093',
      fontSize: 32,
      marginBottom: '0px 0px 18px 0px'
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  searchTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    '& h2': {
      margin: 0,
      color: '#386093',
      fontSize: 24,
      [theme.breakpoints.down('500')]: {
        fontSize: 20
      }
    },
    marginBottom: 35
  },
  searchSection: {
    marginTop: 40
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  createBtn: {
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    [theme.breakpoints.up('890')]: {
      marginTop: 16
    },
    whiteSpace: 'nowrap'
  },

  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  materialHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  materialBackground: {
    height: 48,
    width: 48,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  materialItem: {
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    border: '1px solid #D7DCE480',
    minWidth: '320px',
    maxWidth: '320px',
    overflow: 'hidden'
  },
  materialFolder: {
    backgroundColor: '#EDDFEF'
  },
  materialFile: {
    backgroundColor: '#FDE6E8'
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    width: '55%',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    }
  },
  materialInfos: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    wordBreak: 'break-all',
    margin: 0,
    maxWidth: 250,
    fontWeight: 600,
    fontSize: 16,
    marginTop: 12,
    marginBottom: 8
  },
  infoTemplate: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& p': {
      margin: 0,
      color: '#555555',
      fontWeight: 600
    },
    '& span': {
      color: '#868E96',
      fontSize: 12,
      marginBottom: 10
    }
  },
  materialInfoFooter: {
    marginTop: 8,
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  teacherSection: {
    marginTop: 8,
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& .label': {
      color: '#868E96',
      fontSize: 12,
      marginBottom: 4
    }
  },
  pagination: {
    float: 'right',
    paddingTop: '15px',
    marginBottom: 50,
    [theme.breakpoints.down('426')]: {
      float: 'unset',
      '& .MuiPagination-ul': {
        flexWrap: 'nowrap'
      }
    }
  },
  destinationLabel: {
    display: 'flex',
    gap: 32,
    '& path': {
      stroke: '#555555'
    },
    '& .destination': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      '& span': {
        fontWeight: 600,
        fontSize: 20,
        color: '#555555',
        [theme.breakpoints.down('sm')]: {
          fontSize: 16
        }
      }
    }
  },
  isFetching: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  filtersSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  filtersDisplay: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  horizontalBar: {
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #ADB8CC'
  },
  linkStyle: {
    '& path': {
      strokeWidth: 5
    }
  }
}))

const MaterialSvg = ({ item, classes }) => {
  const isAFolder = item.material_type === 'files'
  const isAudio = item.material_type === 'audios'
  const isVideo = item.material_type === 'videos'
  const isLink = item.material_type === 'links'
  return (
    <div
      className={`${classes.materialBackground} ${
        isAFolder ? classes.materialFolder : classes.materialFile
      }`}
    >
      <StyledSVG
        className={isLink ? classes.linkStyle : ''}
        src={
          isAFolder
            ? folderSVG
            : isAudio
              ? audioSVG
              : isVideo ? videoSVG : isLink ? linkSVG : fileSVG
        }
      />
    </div>
  )
}

const MaterialComponent = ({
  materials,
  fetchItems,
  isFetching,
  params,
  isAdmin,
  getCurrentMaterial,
  firstPageAfterSearch,
  history,
  classes,
  pagination,
  deleteMaterial,
  isVersarAdmin
}) => {
  const [page, setPage] = React.useState(1)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    title: null,
    id: null
  })

  const handleChange = (event, value) => {
    setPage(value)
    fetchItems({
      page: value,
      params: {
        ...params
      }
    })
  }

  // to syncronize both components that handle the wrappers when the screen size changes
  React.useEffect(
    () => {
      fetchItems({
        page: 1,
        params: {
          ...params
        }
      })
      setPage(1)
    },
    [matches]
  )

  return (
    <div style={{ marginTop: 40 }}>
      {matches ? (
        isFetching ? (
          <div className={classes.isFetching}>
            <CircularProgress size={60} />
          </div>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: '20px',
                columnGap: '40px',
                justifyContent: 'flex-start'
              }}
            >
              {materials &&
                materials.length > 0 &&
                materials.map(item => {
                  const classrooms = item?.destinations
                    ?.filter(item => item.classroom_id)
                    .filter((item, index, self) => {
                      return (
                        index ===
                        self.findIndex(
                          obj => obj.classroom_id === item.classroom_id
                        )
                      )
                    })
                  const grades = classrooms
                    ?.map(
                      materialDestination => materialDestination.classroom.grade
                    )
                    .filter((item, index, self) => {
                      return (
                        index ===
                        self.findIndex(obj => obj.grade_id === item.grade_id)
                      )
                    })
                  return (
                    <div key={item.id} className={`${classes.materialItem}`}>
                      <div className={classes.materialHeader}>
                        <MaterialSvg classes={classes} item={item} />
                        <div className={classes.actionsSection}>
                          <Tooltip title='Visualizar'>
                            <div
                              onClick={() =>
                                history.push(`/materials/${item.id}`)
                              }
                            >
                              <StyledSVG width={24} height={24} src={viewSVG} />
                            </div>
                          </Tooltip>
                          <Tooltip title='Editar'>
                            <div
                              onClick={() =>
                                history.push(`/materials/${item.id}/edit`)
                              }
                            >
                              <StyledSVG width={24} height={24} src={editSVG} />
                            </div>
                          </Tooltip>
                          <Tooltip title='Relatório'>
                            <div
                              onClick={() =>
                                history.push(`/materials/${item.id}/report`)
                              }
                            >
                              <StyledSVG
                                width={24}
                                height={24}
                                src={reportSVG}
                              />
                            </div>
                          </Tooltip>
                          <Tooltip title='Excluir'>
                            <div
                              onClick={() => {
                                setConfirmModal({
                                  id: item?.id,
                                  title: item.title,
                                  open: true
                                })
                              }}
                            >
                              <StyledSVG
                                width={24}
                                height={24}
                                src={deleteSVG}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                      <div className={classes.materialInfos}>
                        <p className={classes.title}>{`${
                          item.title.length > 30
                            ? `${item.title.slice(0, 30)}...`
                            : item.title
                        }`}</p>
                        {item?.description && (
                          <div className={classes.infoTemplate}>
                            <span>Descrição</span>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.description.length > 150
                                    ? `${item.description.slice(0, 150)}...`
                                    : item.description
                              }}
                            />
                          </div>
                        )}
                        <div className={classes.teacherSection}>
                          <span className='label'>Professor</span>
                          <UserAvatar
                            userName={item?.user?.name}
                            avatarStyle={{ width: 26, height: 26 }}
                            style={{ color: '#ADB8CC' }}
                          />
                        </div>
                        <div className={classes.materialInfoFooter}>
                          <div className={classes.infoTemplate}>
                            <span>Criação</span>
                            <p>
                              {moment(item.created_at).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                            </p>
                          </div>
                          {isVersarAdmin && (
                            <div className={classes.infoTemplate}>
                              <span>Escola</span>
                              <p>{item?.school?.name}</p>
                            </div>
                          )}
                          <div className={classes.infoTemplate}>
                            <span>Série</span>
                            <p>{grades?.map(grade => grade.name).join(', ')}</p>
                          </div>
                          <div className={classes.infoTemplate}>
                            <span>Turma(s)</span>
                            <p>
                              {classrooms
                                ?.map(
                                  materialDestination =>
                                    materialDestination.classroom.name
                                )
                                .join(', ')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
            {pagination &&
              pagination.pageCount > 1 && (
                <Pagination
                  count={pagination.pageCount}
                  className={classes.pagination}
                  color='primary'
                  page={page}
                  onChange={handleChange}
                />
              )}
          </>
        )
      ) : (
        <CustomTable
          from={'material'}
          columns={[
            {
              key: 'name',
              name: 'Nome',
              render: function render (row) {
                return row?.title
              }
            },
            {
              key: 'school',
              name: 'Escola',
              render: function render (row) {
                return <p>{row.school?.name}</p>
              }
            },
            {
              key: 'created_at',
              name: 'Criação',
              render: function render (row) {
                return row?.created_at
              }
            },
            {
              key: 'scheduled_at',
              name: 'Publicação',
              render: function render (row) {
                return row?.scheduled_at
              }
            },
            {
              key: 'grade',
              name: 'Série',
              render: function render (row) {
                return row?.grade?.name
              }
            },
            {
              key: 'classroom',
              name: 'Turma',
              render: function render (row) {
                return row?.classroom?.name
              }
            },
            {
              key: 'actions',
              name: 'Ações',
              render: function render (row) {
                return (
                  <div className={classes.actionsSection}>
                    <Tooltip title='Visualizar'>
                      <div onClick={() => history.push(`/materials/${row.id}`)}>
                        <StyledSVG width={24} height={24} src={viewSVG} />
                      </div>
                    </Tooltip>
                    <Tooltip title='Editar'>
                      <div
                        onClick={() =>
                          history.push(`/materials/${row.id}/edit`)
                        }
                      >
                        <StyledSVG width={24} height={24} src={editSVG} />
                      </div>
                    </Tooltip>
                    <Tooltip title='Relatório'>
                      <div
                        onClick={() =>
                          history.push(`/materials/${row.id}/report`)
                        }
                      >
                        <StyledSVG width={24} height={24} src={reportSVG} />
                      </div>
                    </Tooltip>
                    <Tooltip title='Excluir'>
                      <div
                        onClick={() => {
                          setConfirmModal({
                            id: row?.id,
                            title: row.title,
                            open: true
                          })
                        }}
                      >
                        <StyledSVG width={24} height={24} src={deleteSVG} />
                      </div>
                    </Tooltip>
                  </div>
                )
              }
            }
          ].filter(item => {
            if (!isVersarAdmin) {
              return item.key !== 'school'
            } else {
              return item
            }
          })}
          data={materials}
          whiteHeader
          border={'1px solid #ADB8CC'}
          isFetching={isFetching}
          fetchItems={fetchItems}
          params={params}
          pagination={pagination}
          firstPageAfterSearch={firstPageAfterSearch}
        />
      )}
      <ConfirmModal
        title={`Deseja mesmo excluir o grupo de arquivos: ${
          confirmModal?.title?.length > 150
            ? `${confirmModal?.title.slice(0, 150)}...`
            : confirmModal?.title
        } ?`}
        open={confirmModal.open}
        setOpen={value => {
          if (value) {
            setConfirmModal({ ...confirmModal, open: value })
          } else {
            setConfirmModal({
              id: null,
              title: '',
              open: value
            })
          }
        }}
        onConfirm={() => {
          deleteMaterial(confirmModal.id)
        }}
      />
    </div>
  )
}

const SearchMaterialsForm = ({
  handleSubmit,
  schoolValue,
  fieldValues,
  change,
  touch,
  gradeValue,
  classroomValue,
  teacherValue,
  materialTitleValue,
  materials,
  schoolId,
  getMaterials,
  userOccupations,
  isFetching,
  pagination,
  reset,
  deleteMaterial,
  currentUser
}) => {
  const classes = useStyles()
  const selectNameRef = React.useRef(null)
  const selectGradeRef = React.useRef(null)
  const selectTeacherRef = React.useRef(null)
  const selectClassroomRef = React.useRef(null)
  const selectSchoolRef = React.useRef(null)
  const history = useHistory()
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const defaultParams = {
    'q[s]': 'created_at desc',
    'page[size]': 8,
    'q[school_id_eq]': schoolId,
    'q[grade_id_eq]': gradeValue,
    'q[classroom_id_eq]': classroomValue,
    'q[user_id_eq]': !isAdmin ? currentUser.id : teacherValue,
    'q[title_cont]': materialTitleValue
  }
  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)

  const handleClearFields = () => {
    reset()
    selectGradeRef.current?.select.clearValue()
    selectClassroomRef.current?.select.clearValue()
    selectNameRef.current?.select.clearValue()
    selectTeacherRef.current?.select.clearValue()
    selectSchoolRef.current?.select.clearValue()
    getMaterials({
      params: {
        'page[size]': 8,
        'q[school_id_eq]': schoolId
      }
    })
  }

  const handleSearch = () => {
    getMaterials({
      params: {
        ...defaultParams,
        ...(schoolValue && { 'q[school_id_eq]': schoolValue })
      }
    })
  }

  const handleChange = (event, input) => {
    touch()
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getMaterials({
        params: { ...defaultParams }
      })
    }
  }

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        <div className={classes.pageLabel}>
          <h1>Espaço do professor</h1>
          <span>
            Hub pessoal para organizar, carregar e gerenciar recursos
            educacionais.
          </span>
        </div>

        <div className={classes.searchSection}>
          <div className={classes.searchContainer}>
            <div className={classes.searchDiv}>
              <strong>Busca</strong>
              <Field
                component={MaterialInput}
                selectRef={selectNameRef}
                name='title'
                endBtnFunction={() => {
                  getMaterials({
                    params: {
                      ...defaultParams
                    }
                  })
                }}
                onKeyDown={handleChangeTitle}
                placeholder={'Nome da pasta ou arquivo'}
                type='text'
              />
            </div>
            <Button
              className={classes.createBtn}
              id='create_material'
              type='button'
              onClick={() => history.push('/materials/create')}
            >
              Criar
            </Button>
          </div>
          <div className={classes.filtersSection}>
            <div className={classes.filterLabel}>
              <StyledSVG src={filterSVG} width={12} height={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  cursor: 'default',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              className={classes.advancedFilter}
              style={{
                height: openMoreFilter ? '250px' : '0px'
              }}
            >
              <div className={classes.filtersDisplay}>
                <div
                  className={classes.searchGroup}
                  style={{ opacity: openMoreFilter ? '1' : '0' }}
                >
                  {userOccupations?.includes(roles.VERSAR_ADMIN) && (
                    <div className={classes.searchField}>
                      <strong htmlFor='school_id'>Escola</strong>
                      <Field
                        component={AsyncSelect}
                        selectRef={selectSchoolRef}
                        defaultOptions={false}
                        id='school_id'
                        name='school_id'
                        placeholder='Digite ou selecione a escola'
                        handleAsyncChange={handleChange}
                        searchParam='q[name_cont]'
                        request={{
                          path: 'school'
                        }}
                        touch={touch}
                      />
                    </div>
                  )}
                  <div className={classes.searchField}>
                    <strong style={{ marginBottom: 10 }} htmlFor='grade_id'>
                      Série
                    </strong>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      selectRef={selectGradeRef}
                      cacheUniqs={[schoolValue]}
                      id='grade_id'
                      name='grade_id'
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'grade',
                        params: {
                          'q[school_id_null]': true
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                  <div className={classes.searchField}>
                    <strong style={{ marginBottom: 10 }} htmlFor='classroom_id'>
                      Turma
                    </strong>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      id='classroom_id'
                      name='classroom_id'
                      selectRef={selectClassroomRef}
                      cacheUniqs={[gradeValue, schoolValue]}
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'classroom',
                        params: {
                          'q[school_id_in]': schoolId || schoolValue,
                          'q[grade_id_in]': gradeValue,
                          'q[year_lteq]': new Date().getFullYear() + 1,
                          'q[year_gteq]': new Date().getFullYear()
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                  {isAdmin && (
                    <div className={classes.searchField}>
                      <strong>Professor(a)</strong>
                      <Field
                        component={AsyncSelect}
                        selectRef={selectTeacherRef}
                        rootStyle={classes.asyncSelectClass}
                        cacheUniqs={[gradeValue, classroomValue]}
                        id='teacher_id'
                        name='teacher_id'
                        placeholder='Digite ou selecione o prof'
                        handleAsyncChange={handleChange}
                        searchParam='q[teacher_name_cont]'
                        request={{
                          path: 'school_teachers',
                          params: {
                            'q[school_id_eq]': schoolId || schoolValue,
                            include: 'teacher'
                          }
                        }}
                        touch={touch}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={classes.buttons}
                >
                  <Button
                    onClick={() => handleClearFields()}
                    className={classes.clearBtn}
                  >
                    Limpar
                  </Button>
                  <Button
                    className={classes.applyBtn}
                    type='button'
                    onClick={() => handleSearch()}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.horizontalBar} />
        </div>
      </Form>
      <div className={classes.searchTitle}>
        <StyledSVG src={listSVG} height={24} width={24} />
        <h2>Lista de pastas e arquivos</h2>
      </div>
      <MaterialComponent
        materials={materials}
        params={defaultParams}
        fetchItems={getMaterials}
        isAdmin={isAdmin}
        isVersarAdmin={isVersarAdmin}
        isFetching={isFetching}
        history={history}
        classes={classes}
        pagination={pagination}
        deleteMaterial={deleteMaterial}
      />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchMaterialsForm')

  return {
    currentUser: state.auth.user.data,
    schoolValue:
      selector(state, 'school_id') ||
      (state.school.currentSchool && state.school.currentSchool.school_id),
    fieldValues: getFormValues('searchMaterialsForm')(state),
    schoolId:
      state.school.currentSchool && state.school.currentSchool.school_id,
    materials: state.materials.items,
    userOccupations: state.auth.currentOccupation,
    isFetching: state.materials.isFetching,
    pagination: state.materials.pagination,
    currentClassroom: state.classrooms.currentItem,
    currentGrade: state.grades.current,
    classroomValue: selector(state, 'classroom_id'),
    gradeValue: selector(state, 'grade_id'),
    teacherValue: selector(state, 'teacher_id'),
    materialTitleValue: selector(state, 'title')
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('searchMaterialsForm', field, data)),
  getMaterials: data => dispatch(getMaterials(data)),
  deleteMaterial: data => dispatch(deleteMaterial(data)),
  getClassroomById: data => dispatch(getClassroomById(data)),
  clearCurrentClassroom: data => dispatch(clearCurrentClassroom(data)),
  cleanGrade: data => dispatch(cleanGrade(data)),
  getGradesById: data => dispatch(getGradesById(data)),
  getUserById: data => dispatch(getUserById(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchMaterialsForm'
  })(SearchMaterialsForm)
)

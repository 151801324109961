import React, { useState, useReducer } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import IconButton from '@material-ui/core/IconButton'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Box from '@material-ui/core/Box'
import Slide from '@material-ui/core/Slide'

import jsonApi from '../../utils/jsonApi'
import { parseImageData } from '../../utils/parsers'

const useStyles = makeStyles(theme => ({
  gridList: {
    paddingLeft: 0,
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    height: 'fit-content',
    justifyContent: 'space-between',
    '& li': {
      width: '100%',
      height: '100%',
      maxWidth: '250px',
      maxHeight: '130px',
      '& img': {
        height: '100%',
        minHeight: 'fit-content',
        objectFit: 'cover',
        borderRadius: 8
      }
    },
    '& li:nth-of-type(3n)': {
      justifySelf: 'flex-end'
    },
    '& li:nth-of-type(3n-1)': {
      justifySelf: 'center'
    }
  },
  title: {
    color: '#FFF'
  },
  selected: {
    color: theme.palette.primary.main
  },
  titleBar: {
    background: 'none'
  },
  arrowStyle: {
    position: 'absolute',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    '&:disabled': {
      cursor: 'default'
    }
  },
  loadingContainer: {
    height: 386,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  previousBtn: {
    left: '-30px'
  },
  nextBtn: {
    right: '-30px'
  }
}))

const initialState = {
  items: [],
  pagination: {
    current: 1,
    totalPages: 1
  }
}

const reducer = (state, action) => {
  return { ...state, [action.property]: action.value }
}
export default function ImagesList ({
  handleClick,
  selected,
  perPage,
  listStyle,
  ...props
}) {
  const PER_PAGE = perPage || 9
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [slideDirection, setSlideDirection] = useState('left')
  const { items, pagination: { current, totalPages } } = state

  const defaultParams = {
    'page[number]': current,
    'page[size]': PER_PAGE
  }

  React.useEffect(() => {
    getData({ params: defaultParams })
  }, [])

  const getData = async ({ params }) => {
    await jsonApi.findAll('covers', { params }).then(res => {
      if (res.data) {
        if (res.meta.total_count === items.length + res.data.length) {
          dispatch({
            property: 'pagination',
            value: {
              ...state.pagination
            }
          })
        }
        const imagesData = parseImageData(res.data)
        const newData = [...imagesData]
        dispatch({
          property: 'items',
          value: newData
        })
        dispatch({
          property: 'pagination',
          value: {
            ...state.pagination,
            totalPages: res.meta.page_count,
            current: params['page[number]']
          }
        })
      }
    })
  }

  const handlePagination = event => {
    setSlideDirection(event === 'next' ? 'left' : 'right')
    const goToPage = event === 'next' ? current + 1 : current - 1
    getData({
      params: {
        'page[number]': goToPage,
        'page[size]': PER_PAGE
      }
    })
  }

  return (
    <Box position='relative' display='flex' alignItems='center'>
      <button
        type='button'
        name='back'
        className={`${classes.arrowStyle} ${classes.previousBtn}`}
        disabled={current <= 1}
        onClick={() => handlePagination('back')}
      >
        <ArrowBackIosIcon />
      </button>
      <GridList
        className={`${classes.gridList} ${listStyle}`}
        style={items.length < 3 ? { justifyContent: 'unset !important' } : {}}
        cols={{ sm: 1, md: 3 }}
      >
        {items.map(tile => (
          <Slide in={tile.id} key={tile.id} direction={slideDirection}>
            <GridListTile>
              <img src={tile.img} alt={tile.alt} width={250} />
              <GridListTileBar
                actionPosition='left'
                title={tile.title}
                onClick={() =>
                  handleClick(selected === tile.id ? null : tile.id)
                }
                classes={{
                  root: classes.titleBar,
                  title: classes.title
                }}
                actionIcon={
                  <IconButton aria-label={`star ${tile.alt}`}>
                    {String(selected) === tile.id ? (
                      <CheckCircleSharpIcon className={classes.selected} />
                    ) : (
                      <RadioButtonUncheckedIcon className={classes.title} />
                    )}
                  </IconButton>
                }
              />
            </GridListTile>
          </Slide>
        ))}
      </GridList>
      <button
        type='button'
        name='next'
        className={`${classes.arrowStyle} ${classes.nextBtn}`}
        disabled={current >= totalPages}
        onClick={() => handlePagination('next')}
      >
        <ArrowForwardIosIcon />
      </button>
    </Box>
  )
}

import React from 'react'
import Button from '../button/Button'
import folderSVG from '../../assets/icons/material-folder.svg'
import StyledSVG from '../shared/StyledSVG'
import calendarSVG from '../../assets/icons/material-calendar.svg'
import timerSVG from '../../assets/icons/material-timer.svg'
import editSVG from '../../assets/icons/edit-pencil.svg'
import destinationsSVG from '../../assets/icons/sended-icon.svg'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { roles } from '../../utils/constants'

const useStyles = makeStyles(theme => ({
  overview: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '& h1': {
      margin: 0,
      color: '#386093',
      fontWeight: 700,
      fontSize: 32
    },
    '& span': {
      fontSize: 16,
      color: '#555555'
    }
  },
  title: {
    marginTop: 21,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: 12,
    width: '85%',
    '& .label': {
      fontSize: 20,
      fontWeight: 700,
      color: '#555555'
    },
    '& .content': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      fontSize: 24,
      fontWeight: 600
    }
  },
  description: {
    marginTop: 24,
    '& .label': {
      fontSize: 20,
      fontWeight: 700,
      color: '#555555'
    },
    '& .content': {
      marginTop: 16,
      '& span': {
        wordBreak: 'break-all'
      }
    },
    '& .seeMore': {
      color: '#00ACDB',
      textDecoration: 'underline',
      cursor: 'pointer',
      marginLeft: 8
    }
  },
  dateInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: 8,
    '& .label': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      fontSize: 20,
      fontWeight: 700
    }
  },
  datesSection: {
    marginTop: 35,
    display: 'flex',
    columnGap: 30
  },
  destinationsSection: {
    marginTop: 32,
    '& .label': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      fontSize: 20,
      fontWeight: 600,
      color: '#555555'
    },
    '& .destinations': {
      marginTop: 16,
      display: 'flex',
      alignItems: 'center',
      columnGap: 65
    }
  },
  destinationItem: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    '& span:nth-of-type(1)': {
      fontSize: 16,
      fontWeight: 600
    }
  },
  editLabel: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    color: '#386093'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start'
    },
    '& button': {
      width: 150,
      border: '2px solid #386093',
      color: '#386093',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'transparent',
        border: '2px solid #386093'
      },
      fontSize: '16px',
      fontWeight: 600
    }
  },
  editButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  }
}))

const MaterialViewTemplate = ({
  cleanMaterialSuccessful,
  currentMaterial,
  showEditButton,
  userOccupations
}) => {
  const classes = useStyles()
  const isFolder = currentMaterial?.files?.length > 1
  const history = useHistory()
  const handleEditBtn = materialId => {
    cleanMaterialSuccessful()
    history.push(`/materials/${materialId}/edit`)
  }
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)

  const classrooms = currentMaterial?.destinations
    ?.filter(item => item.classroom_id)
    .filter((item, index, self) => {
      return (
        index === self.findIndex(obj => obj.classroom_id === item.classroom_id)
      )
    })

  const grades = classrooms
    ?.map(materialDestination => materialDestination.classroom.grade)
    .filter((item, index, self) => {
      return index === self.findIndex(obj => obj.grade_id === item.grade_id)
    })

  const childDestinations = currentMaterial?.destinations?.filter(
    item => item.classroom_id && item.child_id
  )

  return (
    <div>
      <div className={classes.overview}>
        <h1>{isFolder ? 'Grupo de arquivos' : 'Arquivo aberto'}</h1>
        <span>Conteúdo detalhado, organizado e pronto para ser explorado.</span>
      </div>
      <div className={classes.header}>
        <div className={classes.title}>
          <span className='label'>Título</span>
          <div className='content'>
            {isFolder && <StyledSVG src={folderSVG} height={24} width={24} />}
            <span>{currentMaterial?.title}</span>
          </div>
        </div>
        {showEditButton && (
          <div className={classes.editButton}>
            <Button variant='outlined'>
              <div
                onClick={() => handleEditBtn(currentMaterial.id)}
                className={classes.editLabel}
              >
                <StyledSVG width={14} height={14} src={editSVG} />
                <span>Editar</span>
              </div>
            </Button>
          </div>
        )}
      </div>
      <div className={classes.description}>
        <span className='label'>Descrição</span>
        <div className='content'>
          <span
            dangerouslySetInnerHTML={{ __html: currentMaterial?.description }}
          />
        </div>
      </div>
      <div className={classes.datesSection}>
        <div className={classes.dateInfo}>
          <div className='label'>
            <StyledSVG src={calendarSVG} width={24} height={24} />
            <span>Data</span>
          </div>
          <span>{moment(currentMaterial.created_at).format('DD/MM/YY')}</span>
        </div>
        <div className={classes.dateInfo}>
          <div className='label'>
            <StyledSVG src={timerSVG} width={24} height={24} />
            <span>Hora</span>
          </div>
          <span>{moment(currentMaterial.created_at).format('HH:mm')}</span>
        </div>
      </div>
      <div className={classes.destinationsSection}>
        <div className='label'>
          <StyledSVG src={destinationsSVG} width={20} height={20} />
          <span>Destinatários</span>
        </div>
        <div className='destinations'>
          {currentMaterial.school &&
            isVersarAdmin && (
              <div className={classes.destinationItem}>
                <span>Escola</span>
                <span>{currentMaterial.school.name}</span>
              </div>
            )}
          {grades?.length > 0 && (
            <div className={classes.destinationItem}>
              <span>Série(s)</span>
              <span>{grades?.map(grade => grade.name).join(', ')}</span>
            </div>
          )}
          {classrooms?.length > 0 && (
            <div className={classes.destinationItem}>
              <span>Turma(s)</span>
              <span>
                {classrooms
                  ?.map(
                    materialDestination => materialDestination.classroom.name
                  )
                  .join(', ')}
              </span>
            </div>
          )}
          {childDestinations?.length > 0 && (
            <div className={classes.destinationItem}>
              <span>Aluno(s)</span>
              <span>
                {childDestinations
                  ?.map(materialDestination => materialDestination.child.name)
                  .join(', ')}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MaterialViewTemplate

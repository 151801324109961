import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Label from '../label/Label'
import { connect } from 'react-redux'
import { showAlertMessage } from '../../store/alert/actions'
import { getEventById } from '../../store/events/actions'
import PaginatedAsyncTable from '../table/PaginatedAsyncTable'
import moment from 'moment'
import Button from '../button/Button'
import { occupationsObject } from '../../utils/dicts'
import StyledSVG from '../shared/StyledSVG'
import { LinearProgress } from '@material-ui/core'
import houglassSVG from '../../assets/icons/hourglass.svg'
import checkSVG from '../../assets/icons/check.svg'
import errorSVG from '../../assets/icons/error.svg'
import waitingEventConfirmationSVG from '../../assets/icons/waiting-event-confirmation.svg'
// import { occupationsObject } from '../../utils/dicts'

import ShowOvercontentTable from '../table/ShowOvercontentTable'

const useStyles = makeStyles(theme => ({
  textColor: {
    color: '#4D5E80'
  },
  root: {
    width: '98%',
    margin: '20px',
    padding: '30px 50px',
    backgroundColor: '#fff',
    borderRadius: 16
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    '& h2.label': {
      margin: 0,
      fontSize: '24px',
      fontWeight: '700',
      marginBottom: 16
    },
    '& h2.title': {
      fontSize: '20px'
    },
    '& p': {
      margin: 0,
      marginBottom: 40,
      '&.description': {
        maxWidth: '40vw'
      },
      '& p': {
        [theme.breakpoints.up('800')]: {
          maxWidth: '40vw'
        },
        [theme.breakpoints.up('1000')]: {
          maxWidth: '25vw'
        }
      }
    }
  },
  bottomButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'center'
    }
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  waitingConfirmation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& img': {
      width: 430,
      height: 300
    },
    [theme.breakpoints.down('950')]: {
      '& img': {
        height: 250
      }
    },
    [theme.breakpoints.down('800')]: {
      '& img': {
        height: 200
      }
    },
    [theme.breakpoints.down('700')]: {
      '& img': {
        height: 150
      }
    },
    [theme.breakpoints.down('330')]: {
      '& img': {
        height: 125
      }
    }
  }
}))

const AnnouncementConfirmationsView = ({
  eventId,
  currentEvent,
  userOccupations,
  getEventById,
  eventConfirmations,
  isFetching,
  eventNotConfirmed,
  eventRefused
}) => {
  const classes = useStyles()
  const history = useHistory()

  if (isFetching) return <LinearProgress color='secondary' />
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Label className={`label ${classes.textColor}`}>Confirmados</Label>
        <p style={{ color: '#555555' }}>
          Veja quem já confirmou o recebimento do comunicado. Mantenha-se
          informado de forma rápida e fácil.
        </p>
        <Label className={`title label ${classes.textColor}`}>
          {currentEvent.title}
        </Label>
        <p
          className='description'
          style={{ color: '#555555' }}
          dangerouslySetInnerHTML={{ __html: `${currentEvent?.description}` }}
        />
      </div>
      <div
        style={{
          width: '100%',
          height: 0,
          borderBottom: '1px solid #DEDBDB',
          marginBottom: 32
        }}
      />
      <div style={{ marginBottom: 70 }}>
        <h2 className={classes.textColor}>Confirmados</h2>
        {eventConfirmations?.length > 0 ? (
          <PaginatedAsyncTable
            headBorder={'none'}
            borderBottom={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            withoutPageCounter={eventConfirmations?.length < 10}
            columns={[
              {
                key: 'status',
                name: 'Status',
                render: function render (row) {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <StyledSVG height={20} width={20} src={checkSVG} />
                    </div>
                  )
                }
              },
              {
                key: 'users',
                name: 'Usuários',
                render: function render (row) {
                  return row.name
                }
              },
              {
                key: 'occupation',
                name: 'Vínculo',
                render: function render (row) {
                  return occupationsObject[row.occupations?.[0]]
                }
              },
              {
                key: 'classroom',
                name: 'Turma',
                render: function render (row) {
                  const classroom = row?.classrooms?.map(item => item.name)
                  if (classroom?.length > 0) {
                    return <ShowOvercontentTable item={classroom} />
                  } else {
                    return '-'
                  }
                }
              },
              {
                key: 'confirmed_at',
                name: 'Data de confirmação',
                render: function render (row) {
                  return moment(row.confirmed_at).format('DD/MM/YYYY HH:mm')
                }
              }
            ]}
            pageSize={10}
            data={eventConfirmations}
          />
        ) : (
          <div className={classes.waitingConfirmation}>
            <img src={waitingEventConfirmationSVG} />
            <p
              className={classes.textColor}
              style={{ textAlign: 'center', maxWidth: '40vw', fontSize: 20 }}
            >
              {
                'Parece que ainda não deram aquele "ok" para o seu comunicado. Mas não desista, em breve devem surgir os primeiros. Volte aqui emalguns minutos.'
              }
            </p>
          </div>
        )}
      </div>
      {eventRefused?.length > 0 && (
        <div style={{ marginBottom: 50 }}>
          <div
            style={{
              width: '100%',
              height: 0,
              borderBottom: '1px solid #DEDBDB',
              marginTop: 32,
              marginBottom: 32
            }}
          />
          <h2 className={classes.textColor}>Ausências confirmadas</h2>
          <PaginatedAsyncTable
            headBorder={'none'}
            borderBottom={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            withoutPageCounter={eventRefused.length < 10}
            columns={[
              {
                key: 'status',
                name: 'Status',
                render: function render (row) {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <StyledSVG height={20} width={20} src={errorSVG} />
                    </div>
                  )
                }
              },
              {
                key: 'users',
                name: 'Usuários',
                render: function render (row) {
                  return row.name
                }
              },
              {
                key: 'occupation',
                name: 'Vínculo',
                render: function render (row) {
                  return occupationsObject[row.occupations?.[0]]
                }
              },
              {
                key: 'classroom',
                name: 'Turma',
                render: function render (row) {
                  const classroom = row?.classrooms?.map(item => item.name)
                  if (classroom?.length > 0) {
                    return <ShowOvercontentTable item={classroom} />
                  } else {
                    return '-'
                  }
                }
              },
              {
                key: 'refused_at',
                name: 'Data da recusa',
                render: function render (row) {
                  return moment(row.refused_at).format('DD/MM/YYYY HH:mm')
                }
              }
            ]}
            pageSize={10}
            data={eventRefused}
          />
        </div>
      )}
      <div
        style={{
          width: '100%',
          height: 0,
          borderBottom: '1px solid #DEDBDB',
          marginTop: 32,
          marginBottom: 32
        }}
      />
      <div style={{ marginBottom: 50 }}>
        <h2 className={classes.textColor}>Aguardando confirmação</h2>
        {eventNotConfirmed?.length > 0 && (
          <PaginatedAsyncTable
            headBorder={'none'}
            borderBottom={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            withoutPageCounter={eventNotConfirmed.length < 10}
            columns={[
              {
                key: 'status',
                name: 'Status',
                render: function render (row) {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <StyledSVG height={20} width={20} src={houglassSVG} />
                    </div>
                  )
                }
              },
              {
                key: 'users',
                name: 'Usuários',
                render: function render (row) {
                  return row.name
                }
              },
              {
                key: 'occupation',
                name: 'Vínculo',
                render: function render (row) {
                  return occupationsObject[row.occupations?.[0]]
                }
              },
              {
                key: 'classroom',
                name: 'Turma',
                render: function render (row) {
                  const classroom = row?.classrooms?.map(item => item.name)
                  if (classroom?.length > 0) {
                    return <ShowOvercontentTable item={classroom} />
                  } else {
                    return '-'
                  }
                }
              }
            ]}
            pageSize={10}
            data={eventNotConfirmed}
          />
        )}
      </div>
      <div className={classes.bottomButtons}>
        <Button
          className={classes.backBtn}
          variant='outlined'
          type='button'
          onClick={() => {
            currentEvent.id &&
              history.push(
                `/announcements?id=${eventId}&name=view&event=${
                  currentEvent?.event_type
                }`
              )
          }}
        >
          Voltar
        </Button>
      </div>
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  getEventById: id => dispatch(getEventById(id)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

const mapStateToProps = (state, props) => {
  return {
    currentEvent: state.events.currentItem,
    isFetching: state.events.isFetching,
    userOccupations: state.auth.currentOccupation
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AnnouncementConfirmationsView
)

import React from 'react'
import { makeStyles } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import imgSVG from '../../assets/icons/img.svg'
import docSVG from '../../assets/icons/doc.svg'
import videoSVG from '../../assets/icons/video.svg'
import audioSVG from '../../assets/icons/audio.svg'

const useStyles = makeStyles(theme => ({
  file: {
    display: 'flex',
    '& p': {
      backgroundColor: 'rgba(218, 218, 218, 0.2)',
      borderRadius: 8,
      margin: 0,
      display: 'flex',
      gap: 14,
      padding: '5px 10px',
      width: 'fit-content',
      alignItems: 'center',
      '& a': {
        color: theme.palette.text.secondary,
        textDecoration: 'none'
      }
    }
  },
  deleteImgButton: {
    width: 15,
    margin: '2px -8px',
    color: 'gray !important',
    cursor: 'pointer'
  },
  files: {
    marginTop: 20,
    display: 'flex',
    gap: 12,
    flexWrap: 'wrap'
  }
}))

const ShowFile = ({ matches, file, handleDeleteFiles, typeBlob }) => {
  const classes = useStyles()
  const attributesMapped = {
    type: typeBlob ? 'type' : 'content_type',
    name: typeBlob ? 'name' : 'filename',
    preview: typeBlob ? 'preview' : 'file_url'
  }
  const isImage = file?.[attributesMapped.type]?.includes('image')
  const isVideo = file?.[attributesMapped.type]?.includes('video')
  const isAudio = file?.[attributesMapped.type]?.includes('audio')
  const handleDeleteParam = typeBlob ? file.name : file.id

  let fileName = file[attributesMapped.name]
  const extencion = fileName.split('.')[fileName.split('.').length - 1]
  fileName.length > 20 &&
    !matches &&
    (fileName = `${fileName.slice(0, 17)}...${extencion}`)
  let fileDoc
  if ((!isAudio || !isVideo || !isImage) && typeBlob) {
    fileDoc = URL.createObjectURL(file)
  }

  if (isImage) {
    return (
      <div className={classes.file} key={file.name}>
        <p>
          <img src={imgSVG} alt='image' width='21' height='21' />
          <a
            target='_blank'
            href={file[attributesMapped.preview]}
            rel='noreferrer'
          >
            {fileName}
          </a>
          <HighlightOffIcon
            className={classes.deleteImgButton}
            onClick={() => handleDeleteFiles(handleDeleteParam)}
          />
        </p>
      </div>
    )
  } else if (isVideo) {
    return (
      <div className={classes.file} key={file.name}>
        <p>
          <img src={videoSVG} alt='video' width='21' height='21' />
          <a
            target='_blank'
            href={file[attributesMapped.preview]}
            rel='noreferrer'
          >
            {fileName}
          </a>
          <HighlightOffIcon
            className={classes.deleteImgButton}
            onClick={() => handleDeleteFiles(handleDeleteParam)}
          />
        </p>
      </div>
    )
  } else if (isAudio) {
    return (
      <div className={classes.file} key={file.name}>
        <p>
          <img src={audioSVG} alt='video' width='21' height='21' />
          <a
            target='_blank'
            href={file[attributesMapped.preview]}
            rel='noreferrer'
          >
            {fileName}
          </a>
          <HighlightOffIcon
            className={classes.deleteImgButton}
            onClick={() => handleDeleteFiles(handleDeleteParam)}
          />
        </p>
      </div>
    )
  } else {
    return (
      <div className={classes.file} key={file.name}>
        <p>
          <img src={docSVG} alt='document' width='21' height='21' />
          <a target='_blank' href={fileDoc || file.file_url} rel='noreferrer'>
            {fileName}
          </a>
          <HighlightOffIcon
            className={classes.deleteImgButton}
            onClick={() => handleDeleteFiles(handleDeleteParam)}
          />
        </p>
      </div>
    )
  }
}

export default ShowFile

import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  modules: {},
  isFetching: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  currentItem: {
    item: null,
    isFetching: false,
    isError: false,
    messageError: null,
    themes: []
  },
  pagination: {
    total: 1,
    pageCount: 1
  }
}

const modulesReducer = createReducer(initialState, {
  [types.GET_MODULES]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_MODULES_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    modules: action.payload,
    errorMessage: '',
    currentItem: initialState.currentItem
  }),
  [types.GET_MODULES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    modules: {},
    isError: true,
    errorMessage: action.payload
  }),
  [types.NEW_GET_MODULES]: (state, action) => ({
    ...state,
    modules: {},
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.NEW_GET_MODULES_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    modules: action.payload.data,
    pagination: {
      totalCount: action.payload.meta?.total_count,
      pageCount: action.payload.meta?.page_count
    },
    errorMessage: '',
    currentItem: initialState.currentItem
  }),
  [types.NEW_GET_MODULES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    modules: {},
    isError: true,
    errorMessage: action.payload
  }),

  [types.GET_MODULE_BY_ID]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null,
      themes: {}
    }
  }),
  [types.GET_MODULE_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      item: action.payload.data[0],
      isFetching: false,
      isError: false,
      messageError: null,
      themes: {},
      included: action.payload?.included
    }
  }),
  [types.GET_MODULE_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: true,
      messageError: action.payload,
      themes: {}
    }
  }),
  [types.NEW_GET_MODULE_THEMES]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null,
      themes: {}
    }
  }),
  [types.NEW_GET_MODULE_THEMES_SUCCESFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: false,
      messageError: null,
      themes: action.payload
    }
  }),
  [types.NEW_GET_MODULE_THEMES_FAILURE]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: true,
      messageError: null,
      themes: {}
    }
  }),
  'modules/REMOVE_THEME_FROM_MODULE': (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null
    }
  }),
  'modules/REMOVE_THEME_FROM_MODULE_SUCCESSFUL': (state, action) => {
    return {
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isError: false,
        messageError: null,
        themes: state?.currentItem?.themes?.filter(item => {
          return +item.id !== +action.payload
        })
      }
    }
  },
  'modules/REMOVE_THEME_FROM_MODULE_FAILURE': (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: true,
      messageError: null,
      themes: {}
    }
  }),
  [types.CLEAR_CURRENT_ITEM]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null,
      themes: {}
    }
  }),
  [types.GET_MODULE_THEMES]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null,
      themes: {}
    }
  }),
  [types.GET_MODULE_THEMES_SUCCESFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: false,
      messageError: null,
      themes: action.payload
    }
  }),
  [types.GET_MODULE_THEMES_FAILURE]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: true,
      messageError: null,
      themes: {}
    }
  }),
  [types.CREATE_MODULE]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    isSuccess: false,
    errorMessage: ''
  }),
  [types.CREATE_MODULE_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      item: action.payload
    },
    isFetching: false,
    isSuccess: true,
    isError: false,
    errorMessage: ''
  }),
  [types.CREATE_MODULE_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isSuccess: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_MODULE]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.EDIT_MODULE_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.EDIT_MODULE_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.DELETE_MODULE]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  DELETE_OLD_MODULE_SUCCESSFUL: (state, action) => ({
    ...state,
    modules: {
      ...state.modules,
      data: state.modules.data.filter(item => item.id !== action.payload)
    },
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.DELETE_MODULE_SUCCESSFUL]: (state, action) => {
    return {
      ...state,
      modules: state?.modules?.filter(item => item.id !== action.payload),
      isFetching: false,
      isError: false,
      errorMessage: ''
    }
  },
  [types.DELETE_MODULE_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  })
})

export default modulesReducer

import { all, call, takeLatest, put } from 'redux-saga/effects'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import { showAlertMessage } from '../alert/actions'
import * as actions from './actions'
import * as services from './services'

export function * fetchModules ({ payload }) {
  try {
    const modulesData = yield call(services.fetchModules, payload)
    yield put(actions.getModulesSuccessful(modulesData))
  } catch (error) {
    yield put(actions.getModulesFailure(error))
  }
}

export function * newFetchModules ({ payload }) {
  try {
    const modulesData = yield call(services.newFetchModules, payload)
    yield put(actions.newGetModulesSuccessful(modulesData))
  } catch (error) {
    yield put(actions.newGetModulesFailure(error))
  }
}

export function * fetchModuleById ({ payload }) {
  const { ids } = payload
  try {
    const response = yield call(services.fetchModuleById, ids)
    yield put(actions.getModuleByIdSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getModuleByIdFailure(error))
  }
}

export function * fetchModuleThemes ({ payload }) {
  const { id } = payload
  try {
    const response = yield call(services.fetchModuleThemes, id)
    yield put(actions.getModuleThemesSuccessful(response.data))
  } catch (error) {
    console.error(error)
    yield put(actions.getModuleThemesFailure(error))
  }
}

export function * newFetchModulesThemes ({ payload }) {
  const { id } = payload
  try {
    const response = yield call(services.newFetchModuleThemes, { id })
    yield put(actions.newGetModuleThemesSuccessful(response.data))
  } catch (error) {
    console.error(error)
    yield put(actions.newGetModuleThemesFailure(error))
  }
}

export function * createModule ({ payload }) {
  try {
    const modulesData = yield call(services.createModule, payload)
    yield put(actions.createModuleSuccessful(modulesData))
  } catch (error) {
    yield put(actions.createModuleFailure('Erro ao salvar o módulo.'))
    yield put(
      showAlertMessage({ message: 'Falha ao criar módulo.', severity: 'error' })
    )
  }
}

export function * editModule ({ payload }) {
  try {
    const modulesData = yield call(services.editModule, payload)
    yield put(actions.editModuleSuccessful(modulesData))
  } catch (error) {
    yield put(actions.editModuleFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao salvar módulo.',
        severity: 'error'
      })
    )
  }
}

export function * deleteModule ({ payload }) {
  try {
    yield call(services.deleteModule, payload)
    yield put(actions.oldDeleteModuleSuccessful(payload))
  } catch (error) {
    yield put(actions.deleteModuleFailure(error))
  }
}

export function * removeThemeRequest (action) {
  try {
    const { payload: { themeId, moduleId } } = action
    yield call(services.removeTheme, { themeId, moduleId })
    yield put(actions.removeThemeFromModuleSuccessful(themeId))
  } catch (err) {
    console.error(err)
    yield put(actions.removeThemeFromModuleFailure(err))
  }
}

export function * editModuleRSPARequest (action) {
  try {
    const { payload: { id, data } } = action
    const response = yield call(services.editModule, { id, data })
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * createModuleRSPARequest (action) {
  try {
    const { payload: { data } } = action
    const response = yield call(services.createModule, data)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

// Watchers
export function * watchModules () {
  yield takeLatest(actions.getModules, fetchModules)
  yield takeLatest(actions.getModuleById, fetchModuleById)
  yield takeLatest(actions.getModuleThemes, fetchModuleThemes)
  yield takeLatest(actions.newGetModules, newFetchModules)
  yield takeLatest(actions.newGetModuleThemes, newFetchModulesThemes)
  yield takeLatest(actions.editModule, editModule)
  yield takeLatest(actions.deleteModule, deleteModule)
  yield takeLatest(actions.removeThemeFromModule, removeThemeRequest)
  yield takeLatest(actions.createModuleRSPA.request, createModuleRSPARequest)
  yield takeLatest(actions.editModuleRSPA.request, editModuleRSPARequest)
}

export function * watchCreateModule () {
  yield takeLatest(actions.createModule, createModule)
}

export default function * modulesSaga () {
  yield all([watchModules(), watchCreateModule()])
}
